import { calculateFundingRate, calculateHoursPassedBorrow, formatPriceDecimal, isMarketClosed } from "./ultils";

export const getTotalPnlOfTransactions = ({
    transactions,
    priceFx,
    priceCrypto,
    fudingRateAll,
  }) => {
  
      let totalPnl = 0;
  
      for (const element of transactions) {
          let currentPrice = getCurrentPriceBySymbol(element.unit, element.trading_pair_type, priceFx, priceCrypto);
         
          if (isMarketClosed(element.unit) && element.trading_pair_type === "FX") {
              currentPrice = formatPriceDecimal(element.last_market_price, element.unit)
          }
          if (!currentPrice) {
              continue 
          }
          if (element.is_locked) {
            currentPrice = element.locked_price
          }
  
          const fundingRate = getFundingRateBySymbol(element.unit, element.trading_pair_type, fudingRateAll)
          const pnl = getPnlTransaction({
              transaction: element,
              currentPrice,
              fundingRate
          })
          totalPnl = totalPnl + Number(pnl);
      }
      return totalPnl;
  };
  
  export const getCurrentPriceBySymbol = (symbol, type, priceFX, priceCrypto, record) => {
  
      if (type === "CRYPTO") {
          return Number(priceCrypto?.[`${symbol}`])
      }else if (type === "FX") {
        if (!priceFX?.[`${symbol}`] && record) {
          return record.last_market_price
        }
          return Number(priceFX?.[`${symbol}`])
      }
      return 0;
  }
  
  export const getFundingRateBySymbol = (symbol, type, fundingRateAll) => {
      if (type === "FX" || fundingRateAll) {
          return 0
      }
      return fundingRateAll[`${symbol}`]
  }
  
  export const getPnlTransaction = ({
    transaction,
    currentPrice,
    fundingRate,
  }) => {
    if (transaction.transaction_type == 1) {
      if (transaction.trading_pair_type === "CRYPTO") {
        const pnl_value =
          (currentPrice - transaction.entry_price) *
            (transaction.pay_amt / transaction.entry_price) *
            transaction.leverage -
          Math.abs(Number(getBorrowFee(transaction))) +
          (transaction.volumn_amt *
            calculateFundingRate(fundingRate, transaction.transaction_type) +
            transaction.total_fee_funding_rate);
        return Number(pnl_value);
      } else {
        //FX
        const pnl_value =
          (currentPrice - transaction.entry_price) *
            (transaction.pay_amt / transaction.entry_price) *
            transaction.leverage -
          Math.abs(Number(getBorrowFee(transaction)));
        return Number(pnl_value);
      }
    } else {
      if (transaction.trading_pair_type === "CRYPTO") {
        const pnl_value =
          (transaction.entry_price - currentPrice) *
            (transaction.pay_amt / transaction.entry_price) *
            transaction.leverage -
          Math.abs(Number(getBorrowFee(transaction))) +
          (transaction.volumn_amt *
            calculateFundingRate(fundingRate, transaction.transaction_type) +
            transaction.total_fee_funding_rate);
        return Number(pnl_value);
      } else {
        // FX
        const pnl_value =
          (transaction.entry_price - currentPrice) *
            (transaction.pay_amt / transaction.entry_price) *
            transaction.leverage -
          Math.abs(Number(getBorrowFee(transaction)));
        return Number(pnl_value);
      }
    }
  };
  
  const getBorrowFee = (item) => {
    let count_hour = calculateHoursPassedBorrow(
      item.tx_dt,
      item.unit,
      item.trading_pair_type
    );
    if (count_hour == 0) {
      count_hour = 1;
    }
    const percentFee = item.trading_pair_type === "FX" ? 0.001 : 0.015;
    return (-item.volumn_amt * (percentFee / 100) * count_hour).toFixed(2);
  };

  
  
  
export const templateEmailConfig = {
    email1: 'total_remaining_loss_exceeds_10_percent',
    email2: 'dally_loss_left_5_percent',
    email3: 'round_2_funding_8_percent',
    email4: 'live_round_funding',
    email5: 'congratulation_letter',
    email6: 'violation_fraud_regulations',
    email7: 'user_upgraded',
    email8: 'almost_profit',
    email9: 'eligible_to_withdraw',
}
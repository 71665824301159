import * as React from 'react';
import { Box, Toolbar } from '@mui/material';
import { useState, useEffect } from "react";

import { Route, Routes, Navigate } from 'react-router-dom';
import PrimarySearchAppBarProfile from "../X1KAppBarProfile";
import DashBoardProfile from '../dashboard-profle';
import AccountOverview from '../account-overview';
import Accounts from '../accounts';
import Deposit from '../deposit';
import Billing from '../billing';
import Payout from '../payout';
import Leaderboard from '../leaderboard';
import Ref from '../ref';
import Airdrop from '../airdrop';
import Profile from '../profile';
import Announcements from '../announcements';
import Competition from '../competition';
import X1kSideBar from "../X1kSideBar";
import RequestPayout from "../request-payout";
import RefFee from "../ref-fee";
import RefundFee from "../refund-fee";
import EconomicCalendar from '../economic-calendar';

const MainDashboard = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toogleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen)
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <PrimarySearchAppBarProfile toogleSidebar={toogleSidebar} />
            {isSidebarOpen && <X1kSideBar />}
            <Toolbar />
            <Routes>
                <Route path="/" element={<DashBoardProfile />} />
                <Route path="/deposit" element={<Deposit />} />
                <Route path="/account-overview" element={<AccountOverview />} />
                <Route path="/accounts" element={<Accounts />} />
                <Route path="/billing" element={<Billing />} />
                <Route path="/payout" element={<Payout />} />
                <Route path="/leaderboard" element={<Leaderboard />} />
                {/* <Route path="/ref" element={<Ref />} /> */}
                <Route path="/airdrop" element={<Airdrop />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/announcements" element={<Announcements />} />
                <Route path="/competition" element={<Competition />} />
                <Route path="/request-payout" element={<RequestPayout />} />
                <Route path="/ref-commission" element={<RefFee />} />
                <Route path="/refund-fee" element={<RefundFee />} />
                <Route path="/economic-calander" element={<EconomicCalendar />} />
                <Route path="*" element={<Navigate to="/dashboard" />} />
            </Routes>
        </Box>
    );
};

export default MainDashboard;


import React, { useState, useEffect } from 'react';
import { 
  Select, 
  MenuItem, 
  Box, 
  TextField,
  InputAdornment,
  Paper,
  Popper,
  MenuList,
  ClickAwayListener,
  FormControl,
  InputLabel
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import SearchIcon from '@mui/icons-material/Search';

const styles = `
  .css-1q964xs-MuiFormLabel-root-MuiInputLabel-root {
    display: none !important;
  }
  #demo-controlled-open-select-label {
    display: none !important;
  }
`;

export const SelectWithSearch = ({ options, selectedToken, handleClickToken }) => {
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (searchText) {
      const filtered = options.filter(option => 
        (option.label.toLowerCase().replace('/', '')).includes(searchText.toLowerCase())
      );
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions(options);
    }
  }, [searchText, options]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSearchText('');
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleSelectOption = (value) => {
    handleClickToken({ value });
    handleClose();
  };

  // Find label of selected token
  const selectedLabel = options.find(opt => opt.value === selectedToken)?.label || '';

  return (
    <FormControl fullWidth>
      <style>{styles}</style>
      <InputLabel id="trading-pair-select-label">Trading Pair</InputLabel>
      {/* Fix: Add a hidden MenuItem with the current value to prevent the out-of-range error */}
      <Select
        labelId="trading-pair-select-label"
        id="trading-pair-select"
        value={selectedToken || ""}
        label="TradingPair"
        onClick={handleOpen}
        open={false}
        sx={{ width: '100%' }}
        renderValue={() => selectedLabel}
        inputProps={{ readOnly: true }}
        // This disables the out-of-range value warning
        displayEmpty
      >
        {/* Add an empty MenuItem to ensure the value is always valid */}
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {/* Add MenuItems for all options to ensure the value is valid */}
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>

      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        style={{ width: anchorEl ? anchorEl.clientWidth : null, zIndex: 1300 }}
      >
        <Paper elevation={3}>
          <ClickAwayListener onClickAway={handleClose}>
            <Box>
              <Box sx={{ p: 1 }}>
                <TextField 
                  autoFocus
                  fullWidth
                  placeholder="Search..."
                  value={searchText}
                  onChange={handleSearchChange}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <MenuList sx={{ maxHeight: 500, overflow: 'auto' }}>
                {filteredOptions.map((row) => (
                  <MenuItem 
                    key={row.value} 
                    value={row.value} 
                    sx={{ display: 'block' }}
                    onClick={() => handleSelectOption(row.value)}
                  >
                    <div 
                      style={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'space-between' 
                      }} 
                      className={row.status === 1 ? '' : 'text-dark-gray'}
                    >
                      <span>
                        {row.status === 1 ? row.label : `${row.label} (coming soon)`}
                      </span>
                      <Box className="favorite-trading-active pointer">
                        {row.isActive === 1 && (
                          <StarIcon className='star-icon' />
                        )}
                      </Box>
                    </div>
                  </MenuItem>
                ))}
                {filteredOptions.length === 0 && (
                  <MenuItem disabled>
                    <span>Not found</span>
                  </MenuItem>
                )}
              </MenuList>
            </Box>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </FormControl>
  );
};

export const defaultDataTradingPair = [
  {
      "id": "507DE7FF-89E1-4777-B700-50FCA451562F",
      "label": "BTC/USDX",
      "value": "BTC",
      "status": 1,
      "sort": 1,
      "type": "CRYPTO",
      "symbol_exchange": "BTCUSDT",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "B8F60AA1-5BFF-4516-A265-67694261B89D",
      "label": "ETH/USDX",
      "value": "ETH",
      "status": 1,
      "sort": 2,
      "type": "CRYPTO",
      "symbol_exchange": "ETHUSDT",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "480636BA-10B9-45C2-8718-A9AFE5EC50DA",
      "label": "BNB/USDX",
      "value": "BNB",
      "status": 1,
      "sort": 3,
      "type": "CRYPTO",
      "symbol_exchange": "BNBUSDT",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "75E8C17E-1D0E-47DC-96FC-9961D020866F",
      "label": "DOGE/USDX",
      "value": "DOGE",
      "status": 1,
      "sort": 4,
      "type": "CRYPTO",
      "symbol_exchange": "DOGEUSDT",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "3C4CF5BA-4797-406F-8283-D6205E164853",
      "label": "XRP/USDX",
      "value": "XRP",
      "status": 1,
      "sort": 5,
      "type": "CRYPTO",
      "symbol_exchange": "XRPUSDT",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "D5E612D3-22F0-42CE-851D-B4A2CA0B5580",
      "label": "SOL/USDX",
      "value": "SOL",
      "status": 1,
      "sort": 6,
      "type": "CRYPTO",
      "symbol_exchange": "SOLUSDT",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "C6391A88-29D0-4885-962A-D32BC4F735BE",
      "label": "NEAR/USDX",
      "value": "NEAR",
      "status": 1,
      "sort": 7,
      "type": "CRYPTO",
      "symbol_exchange": "NEARUSDT",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "9001D639-CFA7-45B6-A968-C6A6F782A56D",
      "label": "ADA/USDX",
      "value": "ADA",
      "status": 1,
      "sort": 8,
      "type": "CRYPTO",
      "symbol_exchange": "ADAUSDT",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "E6ED83DB-F428-44A9-B2F1-4912B76CE66A",
      "label": "LINK/USDX",
      "value": "LINK",
      "status": 1,
      "sort": 9,
      "type": "CRYPTO",
      "symbol_exchange": "LINKUSDT",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "425ACC6A-4774-4461-AEA0-4BA79BBEF0FA",
      "label": "TRX/USDX",
      "value": "TRX",
      "status": 1,
      "sort": 10,
      "type": "CRYPTO",
      "symbol_exchange": "TRXUSDT",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "B84A72C6-EB5D-4C22-B54F-5DBF701E6609",
      "label": "AVAX/USDX",
      "value": "AVAX",
      "status": 1,
      "sort": 11,
      "type": "CRYPTO",
      "symbol_exchange": "AVAXUSDT",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "6E610CCA-2C13-428E-BC11-6319FBD14F7B",
      "label": "SUI/USDX",
      "value": "SUI",
      "status": 1,
      "sort": 12,
      "type": "CRYPTO",
      "symbol_exchange": "SUIUSDT",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "BD3D63A7-A9D3-412B-B458-ED3E789F4AD1",
      "label": "TON/USDX",
      "value": "TON",
      "status": 1,
      "sort": 13,
      "type": "CRYPTO",
      "symbol_exchange": "TONUSDT",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "0E18CED0-43BE-4330-8C27-D6BDE1B1F58A",
      "label": "SHIB/USDX",
      "value": "SHIB",
      "status": 1,
      "sort": 14,
      "type": "CRYPTO",
      "symbol_exchange": "SHIBUSDT",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "8A79387B-298F-4BCF-8127-E53F0BF5226A",
      "label": "LTC/USDX",
      "value": "LTC",
      "status": 1,
      "sort": 15,
      "type": "CRYPTO",
      "symbol_exchange": "LTCUSDT",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "9BE4662A-D68A-416D-A293-DA1C55C36397",
      "label": "UNI/USDX",
      "value": "UNI",
      "status": 1,
      "sort": 16,
      "type": "CRYPTO",
      "symbol_exchange": "UNIUSDT",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "9F1F4557-AE04-47D2-A003-2CC3765DFBD1",
      "label": "TRUMP/USDX",
      "value": "TRUMP",
      "status": 1,
      "sort": 18,
      "type": "CRYPTO",
      "symbol_exchange": "TRUMPUSDT",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "7DA4FAE9-EE89-4879-92B0-B07EDD9E3E28",
      "label": "PEPE/USDX",
      "value": "PEPE",
      "status": 1,
      "sort": 19,
      "type": "CRYPTO",
      "symbol_exchange": "PEPEUSDT",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "C3DA4A1E-1C6A-4794-8062-6DA869BB8861",
      "label": "APT/USDX",
      "value": "APT",
      "status": 1,
      "sort": 20,
      "type": "CRYPTO",
      "symbol_exchange": "APTUSDT",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "F963E696-52B1-4A4C-B76A-A8797F63DF1B",
      "label": "USD/JPY",
      "value": "USDJPY",
      "status": 1,
      "sort": 21,
      "type": "FX",
      "symbol_exchange": "USDJPY",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "3E3E72E1-179F-474B-AE35-7671FBBD5CF3",
      "label": "USD/CHF",
      "value": "USDCHF",
      "status": 1,
      "sort": 22,
      "type": "FX",
      "symbol_exchange": "USDCHF",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "8C919D00-5C39-4A86-9717-0E572C91DEEA",
      "label": "AUD/USD",
      "value": "AUDUSD",
      "status": 1,
      "sort": 23,
      "type": "FX",
      "symbol_exchange": "AUDUSD",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "F47CB24A-4575-4D45-9EC0-98C194030B35",
      "label": "EUR/USD",
      "value": "EURUSD",
      "status": 1,
      "sort": 24,
      "type": "FX",
      "symbol_exchange": "EURUSD",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "B969D040-844B-4B6C-88F9-823069DB1887",
      "label": "XAU/USD",
      "value": "XAUUSD",
      "status": 1,
      "sort": 25,
      "type": "FX",
      "symbol_exchange": "XAUUSD",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "F67029A5-3F5C-4F77-BE0B-188BB94F9172",
      "label": "XAG/USD",
      "value": "XAGUSD",
      "status": 1,
      "sort": 27,
      "type": "FX",
      "symbol_exchange": "XAGUSD",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "DDB6C003-AD2F-4388-9073-37FCDA2BED01",
      "label": "USD/CAD",
      "value": "USDCAD",
      "status": 1,
      "sort": 28,
      "type": "FX",
      "symbol_exchange": "USDCAD",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "C1F046FF-DC5A-4E54-90D5-3D40BA72D67F",
      "label": "EUR/GBP",
      "value": "EURGBP",
      "status": 1,
      "sort": 30,
      "type": "FX",
      "symbol_exchange": "EURGBP",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "24CD8D3E-7BBB-48EA-A544-6F96ABC5E10B",
      "label": "EUR/JPY",
      "value": "EURJPY",
      "status": 1,
      "sort": 31,
      "type": "FX",
      "symbol_exchange": "EURJPY",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "5BA95EAB-7126-46A9-AB7C-9CCDD96D7B64",
      "label": "GBP/USD",
      "value": "GBPUSD",
      "status": 1,
      "sort": 32,
      "type": "FX",
      "symbol_exchange": "GBPUSD",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "49774BF7-42DD-45F0-BEAC-0841FA8B6E9F",
      "label": "GBP/JPY",
      "value": "GBPJPY",
      "status": 1,
      "sort": 33,
      "type": "FX",
      "symbol_exchange": "GBPJPY",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "2099C3E9-63CD-4FD6-8B9B-9B29663977CC",
      "label": "AUD/JPY",
      "value": "AUDJPY",
      "status": 1,
      "sort": 34,
      "type": "FX",
      "symbol_exchange": "AUDJPY",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "2779A391-9F04-4658-9B5C-C3DEAADEDC1B",
      "label": "EUR/CHF",
      "value": "EURCHF",
      "status": 1,
      "sort": 35,
      "type": "FX",
      "symbol_exchange": "EURCHF",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "175619CB-484F-415E-9C74-2EAB3A99EA53",
      "label": "NZD/JPY",
      "value": "NZDJPY",
      "status": 1,
      "sort": 36,
      "type": "FX",
      "symbol_exchange": "NZDJPY",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "2C418C2F-BB6C-4876-BAF5-A3A33B9F1B1A",
      "label": "CAD/JPY",
      "value": "CADJPY",
      "status": 1,
      "sort": 37,
      "type": "FX",
      "symbol_exchange": "CADJPY",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "B33122C2-32F7-4404-B7A3-E282295AE025",
      "label": "GBP/CHF",
      "value": "GBPCHF",
      "status": 1,
      "sort": 38,
      "type": "FX",
      "symbol_exchange": "GBPCHF",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "B4F2BBAE-3279-4037-9993-253BB2230FDC",
      "label": "AUD/NZD",
      "value": "AUDNZD",
      "status": 1,
      "sort": 39,
      "type": "FX",
      "symbol_exchange": "AUDNZD",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "A7057E97-3AF6-47C8-A343-93D39F5C08C8",
      "label": "CHF/JPY",
      "value": "CHFJPY",
      "status": 1,
      "sort": 40,
      "type": "FX",
      "symbol_exchange": "CHFJPY",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "F575B5E6-CB9F-4A92-BF6F-04A7D1535AEE",
      "label": "EUR/AUD",
      "value": "EURAUD",
      "status": 1,
      "sort": 41,
      "type": "FX",
      "symbol_exchange": "EURAUD",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "278F96FC-AC54-4589-A0BC-93C384FDB762",
      "label": "EUR/CAD",
      "value": "EURCAD",
      "status": 1,
      "sort": 42,
      "type": "FX",
      "symbol_exchange": "EURCAD",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "F9896275-3D0F-4D77-934C-0169BA428265",
      "label": "GBP/AUD",
      "value": "GBPAUD",
      "status": 1,
      "sort": 43,
      "type": "FX",
      "symbol_exchange": "GBPAUD",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "292A811B-B89E-4AF5-A05A-F8844E5AE13F",
      "label": "GBP/CAD",
      "value": "GBPCAD",
      "status": 1,
      "sort": 44,
      "type": "FX",
      "symbol_exchange": "GBPCAD",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "82412F23-B7B8-4574-9590-8EC6EF578CB1",
      "label": "NZD/CHF",
      "value": "NZDCHF",
      "status": 1,
      "sort": 45,
      "type": "FX",
      "symbol_exchange": "NZDCHF",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "DA194BE2-CF63-4B23-B674-0682E911491D",
      "label": "AUD/CHF",
      "value": "AUDCHF",
      "status": 1,
      "sort": 46,
      "type": "FX",
      "symbol_exchange": "AUDCHF",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "618ED9B3-245C-4D18-8FD5-24263345F724",
      "label": "AUD/CAD",
      "value": "AUDCAD",
      "status": 1,
      "sort": 47,
      "type": "FX",
      "symbol_exchange": "AUDCAD",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  },
  {
      "id": "9310EAB2-C34D-4E73-90E1-5C4D149FF965",
      "label": "NZD/CAD",
      "value": "NZDCAD",
      "status": 1,
      "sort": 48,
      "type": "FX",
      "symbol_exchange": "NZDCAD",
      "createDate": null,
      "idFavorite": null,
      "isActive": 0
  }
]
import axios from 'axios';

const api_no_auth = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000
});

api_no_auth.interceptors.request.use((config) => {
    config.withCredentials = true;
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default api_no_auth;
import {
  Box, Toolbar, Avatar, Typography, Badge, Paper
} from "@mui/material";
import TradingOverviewHistory from './TradingOverviewHistory';
import Grid from "@mui/material/Grid2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone, faCalendar, faEyeSlash, faCircleQuestion, faInfo } from "@fortawesome/free-solid-svg-icons";
import './account-overview.style.css'
import { styled } from '@mui/material/styles';
import { LineChart } from '@mui/x-charts/LineChart';
import { dataset } from './constants'
import { DataGrid } from '@mui/x-data-grid';
import BreadcrumbsNav from "../Breadcrumbs"
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import api from '../../instance_api'
import api_key from '../../instance_api_key'
import { useSnackbar } from "../SnackbarProvider";
import { differenceInDays, parseISO } from 'date-fns';
import { TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tab, Tabs, MenuItem, IconButton } from "@mui/material";
import TradingRules from "./TradingRules";
import { shortenAddress } from "../../ultils/ultils";
import api_jwt_wallet from '../../instance_api_jwt';
import { PriceProvider } from "../../hooks/PriceContext";




const AccountOverview = () => {
  const [acccountId, setAccountId] = useState("");
  const [account, setAccount] = useState(null);
  const [profile, setProfile] = useState();
  const navigate = useNavigate();
  const showSnackbar = useSnackbar();
  // Get the location object
  const location = useLocation();
  // Parse the query string
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    let id = queryParams.get('id');
    if(!id) {
      id = sessionStorage.getItem('account');
    }
    setAccountId(id)
    GetAccountById(id);
   
  }, []);

  const getDataProfile = (walletAddress) => {
    api_jwt_wallet
      .get(`/api/profile/getByWalletAddress/${walletAddress}`)
      .then((response) => {
        if (response.data) {
          const dataProfile = response.data;
          setProfile(dataProfile);
      
        }
      })
      .catch((error) => console.error(error));
  };

  const GetAccountById = async (id) => {
    await api_key.get(`/api/account/get_v1_account_by_id_profile/${id}`)
    .then(response => {
      setAccount(response.data);
      getDataProfile(response.data.wallet_address)
    })
    .catch(error => console.error(error));
  }

  const handlePayout = () => {
    // Check KYC status before allowing payout
    if (!profile) {
      showSnackbar("Account information not available", "error");
      return;
    }
    
    const kycStatus = profile.kyc_status;
    
    if (kycStatus !== "approve") {
      let message = "";
      
      switch (kycStatus) {
        case "not_kyc":
          message = "You need to complete KYC verification before requesting a payout";
          break;
        case "pending":
          message = "Your KYC verification is still pending. Please wait for approval";
          break;
        case "reject":
          message = "Your KYC verification was rejected. Please update your KYC information";
          break;
        default:
          message = "KYC verification is required for payout";
      }
      
      showSnackbar(message, "warning");
      
      // Redirect to KYC page if not verified
      if (kycStatus === "not_kyc" || kycStatus === "reject") {
        navigate("/profile");
      }
      
      return;
    }
    
    // If KYC is approved, proceed to payout
    navigate("/profile/payout?id=" + queryParams.get('id'));
  }

  
  return (
    <PriceProvider>
      <Box component="main" sx={{ flexGrow: 1, p: 3, background: '#F8F8F8' }}>
      <Toolbar />
      <BreadcrumbsNav />
      <br></br>
      <Grid container spacing={3}>
        <Grid item size={{ xs: 12, md: 5, lg: 3 }}>
          <Box sx={{ bgcolor: "#FFFFFF", p: 2, width: '100%' }}>
            <Grid container spacing={3}>
              <Grid item size={{ xs: 5, md: 3, lg: 3 }}>
                <Badge color="success" variant="dot"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}>
                  <Avatar alt="avatar" src={`${process.env.PUBLIC_URL}/images/avatar.png`} />
                </Badge>
              </Grid>
              <Grid item size={{ xs: 7, md: 9, lg: 9 }}>
                <Grid container spacing={3}>
                  <Grid item size={{ xs: 5, md: 5, lg: 5 }}>
                    <div><b style={{ fontSize: 'larger' }}>Hi, Sam</b></div>
                  </Grid>
                  <Grid item size={{ xs: 7, md: 7, lg: 7 }}>
                    <div className="account-status">{account?.account_type}</div>
                  </Grid>
                </Grid>
                <div>You have an Evaluation Account</div>
              </Grid>
            </Grid>
            <Box sx={{ width: '100%', marginTop: '20px' }}>
              <div>
                <img src={`${process.env.PUBLIC_URL}/images/Vector1.png`} />
                &nbsp;
                Initial Balance: $ {account?.balance}
              </div>
              {/* <div>
                <img src={`${process.env.PUBLIC_URL}/images/Vector1.png`} />
                &nbsp;
                Plan Type: Free Trial | 6K
              </div> */}
            </Box>
            <Box sx={{ width: '100%', marginTop: '20px', padding: '15px', }}>
              <Box sx={{ width: '100%' }}>
                <img src={`${process.env.PUBLIC_URL}/images/Vector2.png`} />
                &nbsp;
                Trading Cycle Details
              </Box>
              <Box sx={{ width: '100%' }}>
                <div className="trading-cycle-item">
                  <div style={{ width: '50%' }}>Start date</div>
                  <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>{account?.created}&nbsp;<FontAwesomeIcon icon={faCalendar} /></div>
                </div>
                <div className="trading-cycle-item">
                  <div style={{ width: '50%' }}>End date</div>
                  <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>{account?.end_date}&nbsp;<FontAwesomeIcon icon={faCalendar} /></div>
                </div>
              </Box>
            </Box>
            <Box sx={{ width: '100%', marginTop: '20px', padding: '15px', }}>
              <Typography variant="h5" gutterBottom>
                Account Details
              </Typography>
              <Box sx={{ width: '100%' }}>
                <div className="trading-cycle-item">
                  <div style={{ width: '50%' }}>Login</div>
                  <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>{account?.user_name}&nbsp;<FontAwesomeIcon icon={faClone} /></div>
                </div>
                <div className="trading-cycle-item">
                  <div style={{ width: '50%' }}>Master Pass</div>
                  <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>{account?.password}&nbsp;<FontAwesomeIcon style={{
                    cursor:"pointer"
                  }} icon={faClone} onClick={async () => {
                     await navigator.clipboard.writeText(account?.password || '');
                      showSnackbar('Copy success !', 'success')
                  }}/>&nbsp; <FontAwesomeIcon icon={faEyeSlash} /></div>
                </div>
                <div className="trading-cycle-item">
                  <div style={{ width: '50%' }}>Metamask Add</div>
                  <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>{shortenAddress(account?.wallet_address)}&nbsp;<FontAwesomeIcon icon={faClone} /></div>
                </div>
                <div className="trading-cycle-item">
                  <div style={{ width: '50%' }}>Server</div>
                  <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>X1K</div>
                </div>
                {/* <div className="trading-cycle-item">
                  <div style={{ width: '50%' }}>Refund fee gas</div>
                  <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>30%</div>
                </div> */}
                <div>
                  <Button variant="outlined" onClick={handlePayout}>Payout</Button>
                </div>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item size={{ xs: 12, md: 7, lg: 9 }} >
          <Box sx={{ bgcolor: "#FFFFFF", p: 2, width: '100%' }}>
            <Typography variant="h5" gutterBottom>
              Stats
            </Typography>
            <TradingRules balance={account?.balance || 0} account = {account}/>
            {acccountId && <TradingOverviewHistory accountId={acccountId} />}
          </Box>
         
        </Grid>
      </Grid>
    </Box>
    </PriceProvider>
  );
};

export default AccountOverview;

import React, { useState } from 'react';
import { Box, Button, Typography, ButtonGroup } from "@mui/material";

/**
 * BalanceSelector component that displays balance options with tabs for filtering
 * @param {Object} props
 * @param {Array} props.activeBalance - Array of balance options
 * @param {Function} props.onBalanceSelect - Callback function when a balance is selected
 * @param {Function} props.formatCurrency - Function to format currency values
 */
 const BalanceSelector = ({ activeBalance, onBalanceSelect, formatCurrency, listBalance = [] }) => {
  const [tabValue, setTabValue] = useState(0);
  
  // Filter modes
  const MODES = {
    SWING: "SWING",
    INSTANT: "INSTANT"
  };
  
  // Handle tab change
  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };
  
  // Filter balance items based on selected tab
  const filteredBalance = listBalance.filter(balance => {
    // If mode property doesn't exist, show all items in the first tab and none in the second
    // if (!balance.mode) {
    //   return tabValue === 0;
    // }
    
    if (tabValue === 0) {
      return balance.mode === MODES.SWING;
    } else {
      return balance.mode === MODES.INSTANT;
    }
  });

  return (
    <Box sx={{ width: '100%', marginTop:"20px" }}>
      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', mb: 2 }}>
        <h3 style={{ margin: 0 }}>Account Balance</h3>
        <ButtonGroup variant="outlined" aria-label="balance mode tabs" style={{marginLeft: '30px'}}>
          <Button 
            onClick={() => handleTabChange(0)}
            variant={tabValue === 0 ? "contained" : "outlined"}
            sx={{ 
              backgroundColor: tabValue === 0 ? '#3E63DD' : 'transparent',
              color: tabValue === 0 ? 'white' : 'inherit',
              '&:hover': { backgroundColor: tabValue === 0 ? '#3E63DD' : 'rgba(0, 0, 0, 0.04)' },
              borderRadius: '8px 0 0 8px',
              minWidth: '100px',
              padding: '6px 16px'
            }}
          >
            Swing
          </Button>
          <Button 
            onClick={() => handleTabChange(1)}
            variant={tabValue === 1 ? "contained" : "outlined"}
            sx={{ 
              backgroundColor: tabValue === 1 ? '#3E63DD' : 'transparent',
              color: tabValue === 1 ? 'white' : 'inherit',
              '&:hover': { backgroundColor: tabValue === 1 ? '#3E63DD' : 'rgba(0, 0, 0, 0.04)' },
              borderRadius: '0 8px 8px 0',
              minWidth: '100px',
              padding: '6px 16px'
            }}
          >
            Instant
          </Button>
        </ButtonGroup>
      </Box>
      
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 , }}>
        {filteredBalance.length > 0 ? (
          filteredBalance.map((balance, index) => (
            <Box position="relative" display="inline-block" sx={{marginTop: "15px"}} key={balance.id}>
              {balance.is_popular && (
                <Typography
                  variant="caption"
                  sx={{
                    zIndex: 1,
                    position: 'absolute',
                    top: '-15px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    background: 'linear-gradient(90deg, orange, yellow, green, blue)',
                    color: 'white',
                    padding: '3px 8px',
                    borderRadius: '15px',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    letterSpacing: '0.5px',
                  }}
                >
                  POPULAR
                </Typography>
              )}
              <Button
                onClick={() => onBalanceSelect(balance)}
                className={`${activeBalance?.id === balance.id ? 'balance-item-choose' : 'balance-item'}`}
                key={balance.id} 
                variant="outlined"
              >
                <span>{formatCurrency(balance.balance)}</span>
              </Button>
            </Box>
          ))
        ) : (
          <Typography variant="body1" sx={{ p: 2 }}>
            No {tabValue === 0 ? MODES.SWING : MODES.INSTANT} options available
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default BalanceSelector

import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import api_no_auth from '../instance_api_no_authentication';
import { formatPriceDecimal } from '../ultils/ultils';

const PriceContext = createContext();
// Provider component
export const PriceProvider = ({ children }) => {
  // FX state
  const [priceFX, setPriceFX] = useState(null);
  let socketFX = null;
  // Crypto state
  const [priceCrypto, setPriceCrypto] = useState(null);
  const [fundingRateCrypto, setFundingRateCrypto] = useState({});
  let socketCrypto = null;

  // FX WebSocket
  useEffect(() => {
    if (socketFX) {
      socketFX.close();
    }
    initializeFXWebSocket();
    
    // close connection
    return () => {
      if (socketFX) {
        socketFX.close();
      }
    };
  }, []);

  const initializeFXWebSocket = useCallback(() => {
 
    socketFX = new WebSocket("wss://ws.x1kfunded.finance");

    socketFX.onopen = () => console.log("✅ Connected to FX WebSocket server");
    socketFX.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data && data.symbol) {
          onSetPriceFX(data);
        }
      } catch (error) {
        console.log("FX on message error", error);
      }
    };
    socketFX.onerror = (error) => console.error("❌ FX WebSocket error:", error);
    socketFX.onclose = () => console.log("🔌 FX Connection closed");
  }, []);

  const onSetPriceFX = (data) => {
    const { symbol, price } = data;
    setPriceFX((prev) => ({
      ...prev,
      [symbol]: formatPriceDecimal(price, symbol)
    }));
  };



  // Crypto WebSocket
  useEffect(() => {
    initializeCryptoWebSocket();

    return () => {
      if (socketCrypto) {
        socketCrypto.close();
      }
    };
  }, []);

  // Crypto Funding Rate
  useEffect(() => {
    getFundingRateCrypto();
  }, []);

  const initializeCryptoWebSocket = useCallback(() => {
    const wsUrl = `wss://wsbybit.x1kfunded.finance`;
    socketCrypto = new WebSocket(wsUrl);

    socketCrypto.onmessage = async (event) => {
      try {
        if (event.data !== "Connected") {
          const data = JSON.parse(event.data);
          onSetPriceCrypto(data.data);
        }
      } catch (error) {
        console.log("Crypto on message error", error);
      }
    };

    socketCrypto.onclose = () => {
      console.log('WebSocket CRYPTO disconnected');
    };

    socketCrypto.onerror = (error) => {
      console.error('WebSocket CRYPTO error:', error);
    };
  }, []);

  const onSetPriceCrypto = (data) => {
    const { s, p } = data;
    const value = s.replace("USDT", "");
    
    setPriceCrypto((prev) => ({
      ...prev,
      [value]: formatPriceDecimal(p, value) 
    }));
  };


  const getFundingRateCrypto = async () => {
    try {
      const response = await api_no_auth.get(`/api/TradingPair/get_funding_rate`)
      setFundingRateCrypto(response.data);
    } catch (e) {
      console.log("Funding rate error:", e);
    }
  };

  // Context value
  const value = {
    // FX
    priceFX,
    setPriceFX,
    // Crypto
    priceCrypto,
    setPriceCrypto,
    fundingRateCrypto,
    setFundingRateCrypto,

  };

  return (
    <PriceContext.Provider value={value}>
      {children}
    </PriceContext.Provider>
  );
};

// Custom hook to use the Price context
export const usePriceContext = () => {
  const context = useContext(PriceContext);
  if (!context) {
    throw new Error('usePriceContext must be used within a PriceProvider');
  }
  return context;
};

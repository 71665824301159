import { useCallback, useState, useEffect } from "react";
import { debounce, uniq } from "lodash";
import axios from "axios";
import { formatPriceDecimal } from "../../../ultils/ultils";
import api_no_auth from "../../../instance_api_no_authentication";
export const usePriceCrypto = ({ tradeOrder, tradeTx, listTradingPair }) => {
    const [priceCrypto, setPriceCrypto] = useState(); // null | Object
    const [price, setPrice] = useState(0);
    const [selectedToken, setSelectedToken] = useState();
    const [fundingRateCrypto, setFundingRateCrypto] = useState({});
    const [loadingCrypto, setLoadingCrypto] = useState(false);
    let socket = null

    useEffect(() => {
        // if (socket) {
        //     socket.close();
        // }
        initializeWebSocket();
        // close connection
        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, [listTradingPair]);

    useEffect(() => {
        getFundingRateCrypto();
        const interval = setInterval(getFundingRateCrypto, 360000);
        return () => clearInterval(interval);
    }, [listTradingPair])



    const initializeWebSocket = useCallback(
        () => {

            const arrayTrade = listTradingPair.filter(c => c.type === "CRYPTO").map(c => c.symbol_exchange);
            // if (selectedToken) {
            //     arrayTrade.push(selectedToken.symbol_exchange);
            //     setLoadingCrypto(true); 
            // }
            //arrayTrade.push('ETHUSDT');
            const streams = uniq(arrayTrade).map(pair => `${pair.toLowerCase()}@trade`).join('/');
            console.log("stream", streams)
            //const wsUrl = `wss://stream.binance.com:9443/stream?streams=${streams}`;
            const wsUrl = `wss://wsbybit.x1kfunded.finance`;
            socket = new WebSocket(wsUrl);

            socket.onmessage = async (event) => {
                try {
                    if (event.data !== "Connected") {
                        const data = JSON.parse(event.data);
                        onSetPriceToken(data.data)
                    }
                } catch (error) {
                    console.log("on message error", error)
                }
            };

            socket.onclose = () => {
                console.log('WebSocket CRYPTO disconnected');
            };

            socket.onerror = (error) => {
                console.error('WebSocket CRYPTO error:', error);
            };
        },
        [listTradingPair],
    );

    const onSetPriceToken = (data) => {
        const { s, p } = data;
        const value = s.replace("USDT", "");
        if (value === "ETH") {
            setPrice(formatPriceDecimal(p, value))
        }
       
        setPriceCrypto((prev) => ({
            ...prev,
            [value]: formatPriceDecimal(p, value) 
        }));
    }

    const onSelectCryptoToken = (token) => {
        // if (selectedToken?.value === token?.value) {
        //     return;
        // }
        //setSelectedToken(token)
    }

    const getFundingRateCrypto = useCallback(async () => {
       try{
        
        // const listToken = listTradingPair.filter(c => c.type === "CRYPTO" && c.status == 1);
        // const promises = listToken.map(token => {
        //     let symbol = token.value
        // switch (token.value) {
        //     case 'PEPE':
        //         symbol = '1000PEPE';
        //         break;
        //     case 'SHIB':
        //         symbol = '1000SHIB';
        //     break;
        //     default:
        //         break;
        // }
        //     return axios.get(`https://fapi.binance.com/fapi/v1/fundingRate?symbol=${symbol}USDT`)
        // })
        // const results = await Promise.allSettled(promises);
        // const funding = {}
        // for (const response of results) {
        //     if (response.value.data?.length > 0) {
        //         const firstData = response.value.data.find(c => c.fundingRate != 0) ;
        //         const firstFundingRate = (parseFloat(firstData.fundingRate) * 100).toFixed(5)
        //         if (response.value.data[0].symbol.replace("USDT", "") === "1000PEPE") {
        //             funding['PEPE'] = firstFundingRate
        //         }
        //         else if (response.value.data[0].symbol.replace("USDT", "") === "1000SHIB") {
        //             funding['SHIB'] = firstFundingRate
        //         }else {
        //             funding[response.value.data[0].symbol.replace("USDT", "")] = firstFundingRate;
        //         }
                
        //     }
        // }

        const response = await api_no_auth.get(`/api/TradingPair/get_funding_rate`)
        setFundingRateCrypto(response.data)
       }catch(e) {
        console.log(e);
       }
    }, [listTradingPair])

    return {
        priceCrypto,
        price,
        onSelectCryptoToken,
        fundingRateCrypto,
        loadingCrypto,
        setLoadingCrypto
    }
}
import { Grid , Box, Tooltip, IconButton, Typography} from "@mui/material"
import { InfoOutlined } from "@mui/icons-material"
const ItemColumnValue = ({ 
    content = '',
    fontSize = 16,
    info = '',
    discount = 0,
    isSub,
    textColor = 'white',
    extraClass = '',
    beforeSalePrice = 0,
    onClick,
    height
 }) => {
    return <div onClick={onClick} style={{ cursor : "pointer"}}>
        <Box 
    className={
        `
        ${isSub ? 'step-item-sub' : 'step-item'}
        text-${textColor}
        ${extraClass}
        `
    } 
    sx={{fontSize : fontSize}}
    
    >
        {
            discount > 0 &&  <del style={{color : "rgb(255, 140, 0)", marginRight: 5, fontSize: 18}}>
                ${beforeSalePrice}
            </del>
        }
        <span>{content}</span>
        {discount > 0 &&  <TagSale value={discount}/>}
                {info && <Tooltip
                    title={
                    <Typography sx={{ p: 1, fontSize: 14 }}>
                        {info}
                    </Typography>
                    }
                    arrow
                    placement="bottom"
                >
                <IconButton size="small" sx={{ color: '#008ef9', p: 0, marginLeft: '10px' }}>
                  <InfoOutlined fontSize="small" />
                </IconButton>
              </Tooltip>}
    </Box>
    </div>

}
export default ItemColumnValue

export const TagSale = ({value , position = {}}) => {
    return <div style={{
        position : "absolute",
        right : 15,
        bottom : 40,
        ...position
    }}>
        <div style={{
            width : 40,
            height : 40,
            position: "relative"
        }}>
            <span style={{
                color : "black",
                fontWeight : "bold",
                position:"absolute",
                fontSize : 16,
                left : 10,
                top : 2

            }}>{Math.floor(value)}</span>
            <img src={`${process.env.PUBLIC_URL}/images/tag-sale.png`} style={{
                width: 50,
            }}/>
        </div>
    </div>
}

import { memo } from "react";
import TradingViewWidget from "react-tradingview-widget";

const TradingViewChart = ({ token , tradingPairs}) => {
    const activeToken = tradingPairs.find(item => item.value === token);
    if (!activeToken) {
        return <></>
    }
    const symbol = activeToken.type === "CRYPTO" ? `BYBIT:${activeToken.symbol_exchange}` : `OANDA:${activeToken.symbol_exchange}`
    return (
        <div style={{ height: "610px", width: "100%" }}>
            <TradingViewWidget
                symbol= {symbol}// Cặp giao dịch
                theme="dark" // Giao diện (dark / light)
                interval="1" // Khung thời gian
                autosize // Tự động căn chỉnh
                timezone= "Etc/UTC"
                style= "1"
                locale= "en"
                allow_symbol_change={false}
                calendar={false}
                support_host= "https://www.tradingview.com"
                withdateranges
                hide_side_toolbar= {false}
                show_popup_button
            />
        </div>
    )
};

export default memo(TradingViewChart);

/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { 
  Box, 
  Typography, 
  Button, 
  Grid, 
  Paper, 
  Stepper, 
  Step, 
  StepLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faUpload, faCheck, faTwitter } from "@fortawesome/free-solid-svg-icons";
import api_jwt_wallet from '../../instance_api_jwt';
import "./kyc.style.css";
import { useSnackbar } from "../SnackbarProvider";

const steps = ['Twitter Verification', 'ID Document', 'Selfie with ID'];

const KycVerification = ({profile}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [documentType, setDocumentType] = useState('passport');
  const showSnackbar = useSnackbar();
  
  // Document images for preview
  const [passPortPreview, setPassPortPreview] = useState(null);
  const [idCardBeforePreview, setIdCardBeforePreview] = useState(null);
  const [idCardAfterPreview, setIdCardAfterPreview] = useState(null);
  const [drivingLicenseBeforePreview, setDrivingLicenseBeforePreview] = useState(null);
  const [drivingLicenseAfterPreview, setDrivingLicenseAfterPreview] = useState(null);
  const [selfieImagePreview, setSelfieImagePreview] = useState(null);
  
  // Document files for upload
  const [passPortFile, setPassPortFile] = useState(null);
  const [idCardBeforeFile, setIdCardBeforeFile] = useState(null);
  const [idCardAfterFile, setIdCardAfterFile] = useState(null);
  const [drivingLicenseBeforeFile, setDrivingLicenseBeforeFile] = useState(null);
  const [drivingLicenseAfterFile, setDrivingLicenseAfterFile] = useState(null);
  const [selfieImageFile, setSelfieImageFile] = useState(null);
  
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [walletAddress, setWalletAddress] = useState(null);
  const [userTwitter, setUserTwitter] = useState(profile.twitter_id);
  const [kycStatus, setKycStatus] = useState(profile?.kyc_status);

  useEffect(() => {
    const storedWallet = localStorage.getItem("wallet_address");
    if (storedWallet) {
      setWalletAddress(storedWallet);
    }
  }, []);

  const handleNext = async () => {
    // Validate based on current step
    if (activeStep === 1) {
      // ID Document validation
      if (documentType === 'passport' && !passPortFile) {
        showSnackbar("Please select your passport image", "error");
        return;
      } else if (documentType === 'id_card' && (!idCardBeforeFile || !idCardAfterFile)) {
        showSnackbar("Please select both front and back sides of your ID card", "error");
        return;
      } else if (documentType === 'driver_license' && (!drivingLicenseBeforeFile || !drivingLicenseAfterFile)) {
        showSnackbar("Please select both front and back sides of your driver's license", "error");
        return;
      }
    }
    
    if (activeStep === 2 && !selfieImageFile) {
      showSnackbar("Please select a selfie with your ID", "error");
      return;
    }

    // If we're on the final step, upload all files and submit
    if (activeStep === steps.length - 1) {
      setLoading(true);
      try {
        // Upload all files
        await uploadAllFiles();
        // Submit KYC data
      } catch (error) {
        console.error("Error during upload and submission:", error);
        showSnackbar("An error occurred during the upload process. Please try again.", "error");
      } finally {
        setLoading(false);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Clear document images when changing document type
  const handleDocumentTypeChange = (event) => {
    const newType = event.target.value;
    setDocumentType(newType);
    
    // Reset all document images, files and URLs for the previous document type
    setPassPortPreview(null);
    setIdCardBeforePreview(null);
    setIdCardAfterPreview(null);
    setDrivingLicenseBeforePreview(null);
    setDrivingLicenseAfterPreview(null);
    
    setPassPortFile(null);
    setIdCardBeforeFile(null);
    setIdCardAfterFile(null);
    setDrivingLicenseBeforeFile(null);
    setDrivingLicenseAfterFile(null);
    
  };

  const handleFileUpload = (event, type) => {
    const file = event.target.files[0];
    if (!file) return;

    // For preview only
    const reader = new FileReader();
    reader.onload = (e) => {
      const imageData = e.target.result;
      
      if (type === 'front') {
        // Set the appropriate front image preview based on document type
        if (documentType === 'passport') {
          setPassPortPreview(imageData);
          setPassPortFile(file);
        } else if (documentType === 'id_card') {
          setIdCardBeforePreview(imageData);
          setIdCardBeforeFile(file);
        } else if (documentType === 'driver_license') {
          setDrivingLicenseBeforePreview(imageData);
          setDrivingLicenseBeforeFile(file);
        }
      } else if (type === 'back') {
        // Set the appropriate back image preview based on document type
        if (documentType === 'id_card') {
          setIdCardAfterPreview(imageData);
          setIdCardAfterFile(file);
        } else if (documentType === 'driver_license') {
          setDrivingLicenseAfterPreview(imageData);
          setDrivingLicenseAfterFile(file);
        }
      } else if (type === 'selfie') {
        setSelfieImagePreview(imageData);
        setSelfieImageFile(file);
      }
    };
    reader.readAsDataURL(file);
  };
  function formatFileSize(bytes) {
    if (bytes < 1024) return bytes + ' B';
    if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(2) + ' KB';
    return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
  }
  // Upload all files at once
  const uploadAllFiles = async () => {
    setUploading(true);
    
    try {
      // Create form data with all files
      const formData = new FormData();
      
      let totalPayloadSize = 0;

      // Add files based on document type
      if (documentType === 'passport' && passPortFile) {
        formData.append('pass_port', passPortFile);
        totalPayloadSize += passPortFile.size;
      } else if (documentType === 'id_card') {
        if (idCardBeforeFile) {
          formData.append('id_card_before', idCardBeforeFile);
          totalPayloadSize += idCardBeforeFile.size;
        }
        if (idCardAfterFile) {
          formData.append('id_card_after', idCardAfterFile);
          totalPayloadSize += idCardAfterFile.size;
        }
      } else if (documentType === 'driver_license') {
        if (drivingLicenseBeforeFile) {
          formData.append('driving_license_before', drivingLicenseBeforeFile);
          totalPayloadSize += drivingLicenseBeforeFile.size;
        }
        if (drivingLicenseAfterFile) {
          formData.append('driving_license_after', drivingLicenseAfterFile);
          totalPayloadSize += drivingLicenseAfterFile.size;
        }
      }
      
      // Add selfie file
      if (selfieImageFile) {
        formData.append('selfie_with_id', selfieImageFile);
        totalPayloadSize += selfieImageFile.size;
      }
      
      // Hiển thị tổng dung lượng
      console.log(`Total size: ${formatFileSize(totalPayloadSize)}`);
      
      // Upload all documents at once
      const response = await api_jwt_wallet.post('/api/account/upload-documents', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.data && response.data.success) {
        // Process the response to get URLs
        const files = response.data.files || [];
        console.log(response.data)
        // Store URLs based on file types
        showSnackbar("Documents uploaded successfully", "success");
        await submitKycData(response.data.files);
        return true;
      } else {
        showSnackbar("Failed to upload documents. Please try again.", "error");
        return false;
      }
    } catch (error) {
      console.error("Document upload error:", error);
      showSnackbar("An error occurred while uploading the documents.", "error");
      return false;
    } finally {
      setUploading(false);
    }
  };

  // Get the front image preview based on document type
  const getFrontImagePreview = () => {
    if (documentType === 'passport') {
      return passPortPreview;
    } else if (documentType === 'id_card') {
      return idCardBeforePreview;
    } else if (documentType === 'driver_license') {
      return drivingLicenseBeforePreview;
    }
    return null;
  };

  // Get the back image preview based on document type
  const getBackImagePreview = () => {
    if (documentType === 'id_card') {
      return idCardAfterPreview;
    } else if (documentType === 'driver_license') {
      return drivingLicenseAfterPreview;
    }
    return null;
  };



  // Handle removing front image and file
  const handleRemoveFrontImage = () => {
    if (documentType === 'passport') {
      setPassPortPreview(null);
      setPassPortFile(null);
   
    } else if (documentType === 'id_card') {
      setIdCardBeforePreview(null);
      setIdCardBeforeFile(null);

    } else if (documentType === 'driver_license') {
      setDrivingLicenseBeforePreview(null);
      setDrivingLicenseBeforeFile(null);
  
    }
  };

  // Handle removing back image and file
  const handleRemoveBackImage = () => {
    if (documentType === 'id_card') {
      setIdCardAfterPreview(null);
      setIdCardAfterFile(null);
    } else if (documentType === 'driver_license') {
      setDrivingLicenseAfterPreview(null);
      setDrivingLicenseAfterFile(null);
    }
  };

  // Handle removing selfie image and file
  const handleRemoveSelfieImage = () => {
    setSelfieImagePreview(null);
    setSelfieImageFile(null);
  };

  const submitKycData = async (files) => {
    if (!walletAddress) {
      showSnackbar("Wallet address not found. Please reconnect your wallet.", "error");
      return;
    }

    setLoading(true);

    try {
      if (!files || !Array.isArray(files) || files.length === 0) {
        throw new Error("No files uploaded");
      }
      // Create update data object with default null values
      const updateData = {
        id_card_before: null,
        id_card_after: null,
        pass_port: null,
        selfie_with_id: null,
        driving_license_before: null,
        driving_license_after: null,
        kyc_type: documentType
      };
      
      // Update fields based on fileType from the files array
      files.forEach(file => {
        if (file.fileType === 'pass_port') {
          updateData.pass_port = file.publicUrl;
        } else if (file.fileType === 'id_card_before') {
          updateData.id_card_before = file.publicUrl;
        } else if (file.fileType === 'id_card_after') {
          updateData.id_card_after = file.publicUrl;
        } else if (file.fileType === 'driving_license_before') {
          updateData.driving_license_before = file.publicUrl;
        } else if (file.fileType === 'driving_license_after') {
          updateData.driving_license_after = file.publicUrl;
        } else if (file.fileType === 'selfie_with_id') {
          updateData.selfie_with_id = file.publicUrl;
        }
      });
      console.log(updateData);
      // Make PATCH request to update KYC information
      await api_jwt_wallet.patch(`/api/profile/update_kyc_information/${profile.id}`, updateData);
      
      // Update KYC status and show success message
      setKycStatus('submitted');
      showSnackbar("KYC verification submitted successfully!", "success");
    } catch (error) {
      console.error("KYC submission error:", error);
      showSnackbar(error.message || "An error occurred while submitting KYC verification.", "error");
    } finally {
      setLoading(false);
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box className="kyc-step-content">
            <Typography variant="subtitle1" gutterBottom>
              Twitter Account Verification
            </Typography>
            
            <Paper className="twitter-container">
              {userTwitter ? (
                <Box className="twitter-verified">
                  <FontAwesomeIcon icon={faCheck} size="1x" color="green" />
                  <Typography variant="subtitle1" sx={{ mt: 1, color: 'green' }}>
                    Twitter Account Verified
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    Your Twitter account has been successfully linked to your profile.
                  </Typography>
                </Box>
              ) : (
                <Box className="twitter-connect">
                  <img 
                    src={`${process.env.PUBLIC_URL}/images/X_logo_2023.png`} 
                    style={{ width: '40px', height: '40px' }} 
                  />
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    Connect your Twitter account
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ mt: 1, backgroundColor: '#000', '&:hover': { backgroundColor: '#333' }, fontSize: '0.8rem', py: 0.5 }}
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}/api/twitter/auth/twitter`,
                        "_self"
                      )
                    }
                  >
                    Connect Twitter
                  </Button>
                </Box>
              )}
            </Paper>
            
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.8rem' }}>
                Why we need your Twitter account:
              </Typography>
              <ul className="requirements-list">
                <li>To verify your identity</li>
                <li>To provide you with platform updates</li>
                <li>To enable social features of our platform</li>
              </ul>
            </Box>
          </Box>
        );
      
      case 1:
        return (
          <Box className="kyc-step-content">
            <Typography variant="subtitle1" gutterBottom>
              Upload ID Document
            </Typography>
            
            <FormControl component="fieldset" sx={{ mb: 2 }}>
              <FormLabel component="legend" sx={{ fontSize: '0.9rem' }}>Document Type</FormLabel>
              <RadioGroup
                row
                name="document-type"
                value={documentType}
                onChange={handleDocumentTypeChange}
                sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.9rem' } }}
              >
                <FormControlLabel value="passport" control={<Radio size="small" />} label="Passport" />
                <FormControlLabel value="id_card" control={<Radio size="small" />} label="ID Card" />
                <FormControlLabel value="driver_license" control={<Radio size="small" />} label="Driver's License" />
              </RadioGroup>
            </FormControl>

            <Grid container spacing={2}>
              <Grid item xs={12} md={documentType === 'passport' ? 8 : 8} container spacing={2}>
                <Grid item xs={12} md={documentType === 'passport' ? 12 : 6}>
                  <Paper className="upload-container">
                    {getFrontImagePreview() ? (
                      <div className="image-preview">
                        <img src={getFrontImagePreview()} className="preview-image" />
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          Selected
                        </Typography>
                        <Button 
                          variant="contained" 
                          color="primary" 
                          onClick={handleRemoveFrontImage}
                          sx={{ fontSize: '0.8rem', py: 0.5 }}
                          size="small"
                          disabled={uploading}
                        >
                          Remove
                        </Button>
                      </div>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faUpload} size="1x" />
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          Upload Front Side
                        </Typography>
                        <Button
                          variant="contained"
                          component="label"
                          sx={{ mt: 1, fontSize: '0.8rem', py: 0.5 }}
                          size="small"
                          disabled={uploading}
                        >
                          Select File
                          <input
                            type="file"
                            hidden
                            accept="image/*"
                            onChange={(e) => handleFileUpload(e, 'front')}
                          />
                        </Button>
                      </>
                    )}
                  </Paper>
                </Grid>
                
                {documentType !== 'passport' && (
                  <Grid item xs={12} md={6}>
                    <Paper className="upload-container">
                      {getBackImagePreview() ? (
                        <div className="image-preview">
                          <img src={getBackImagePreview()} className="preview-image" />
                          <Typography variant="body2" sx={{ mt: 1 }}>
                            Selected
                          </Typography>
                          <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={handleRemoveBackImage}
                            sx={{ fontSize: '0.8rem', py: 0.5 }}
                            size="small"
                            disabled={uploading}
                          >
                            Remove
                          </Button>
                        </div>
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faUpload} size="1x" />
                          <Typography variant="body2" sx={{ mt: 1 }}>
                            Upload Back Side
                          </Typography>
                          <Button
                            variant="contained"
                            component="label"
                            sx={{ mt: 1, fontSize: '0.8rem', py: 0.5 }}
                            size="small"
                            disabled={uploading}
                          >
                            Select File
                            <input
                              type="file"
                              hidden
                              accept="image/*"
                              onChange={(e) => handleFileUpload(e, 'back')}
                            />
                          </Button>
                        </>
                      )}
                    </Paper>
                  </Grid>
                )}
              </Grid>
              
              <Grid item xs={12} md={4}>
                <Box>
                  <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.8rem', mb: 1 }}>
                    Please ensure your document is:
                  </Typography>
                  <ul className="requirements-list">
                    <li>Valid and not expired</li>
                    <li>Clearly visible with no glare or shadows</li>
                    <li>Complete with all corners visible</li>
                    <li>In color (not black and white)</li>
                  </ul>
                </Box>
              </Grid>
            </Grid>
          </Box>
        );
      
      case 2:
        return (
          <Box className="kyc-step-content">
            <Typography variant="subtitle1" gutterBottom>
              Selfie with ID Document
            </Typography>
            
            <Paper className="upload-container selfie-container">
              {selfieImagePreview ? (
                <div className="image-preview">
                  <img src={selfieImagePreview} className="preview-image" />
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    Selected
                  </Typography>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleRemoveSelfieImage}
                    sx={{ fontSize: '0.8rem', py: 0.5 }}
                    size="small"
                    disabled={uploading}
                  >
                    Remove
                  </Button>
                </div>
              ) : (
                <>
                  <FontAwesomeIcon icon={faCamera} size="1x" />
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    Upload a selfie holding your ID document
                  </Typography>
                  <Button
                    variant="contained"
                    component="label"
                    sx={{ mt: 1, fontSize: '0.8rem', py: 0.5 }}
                    size="small"
                    disabled={uploading}
                  >
                    Select File
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={(e) => handleFileUpload(e, 'selfie')}
                    />
                  </Button>
                </>
              )}
            </Paper>
            
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.8rem' }}>
                Requirements for the selfie:
              </Typography>
              <ul className="requirements-list">
                <li>Your face must be clearly visible</li>
                <li>Hold your ID document next to your face</li>
                <li>The ID document must be the same one you uploaded in step 1</li>
                <li>Take the photo in good lighting</li>
              </ul>
            </Box>
          </Box>
        );
      
      default:
        return null;
    }
  };



  if (kycStatus === 'submitted' || kycStatus === 'pending') {
    return (
      <Box className="kyc-container">
        <Paper className="kyc-paper">
          <Box className="kyc-status-pending">
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              KYC Verification In Progress
            </Typography>
            <Typography variant="body2">
              Your verification documents have been submitted and are currently under review. 
              This process typically takes 1-3 business days.
            </Typography>
          </Box>
        </Paper>
      </Box>
    );
  }

  return (
    <Box className="kyc-container">
      <Paper className="kyc-paper">
        <Typography variant="h6" gutterBottom>
          Identity Verification (KYC)
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          To comply with regulations and ensure platform security, we need to verify your identity.
          Please complete all steps below.
        </Typography>
        
        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 3 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel sx={{ '& .MuiStepLabel-label': { fontSize: '0.8rem' } }}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        
        {renderStepContent(activeStep)}
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Button
            variant="outlined"
            disabled={activeStep === 0 || uploading}
            onClick={handleBack}
            size="small"
          >
            Back
          </Button>
          <Button 
            variant="contained" 
            onClick={handleNext}
            disabled={loading || uploading}
            size="small"
          >
            {loading ? (
              <CircularProgress size={20} />
            ) : activeStep === steps.length - 1 ? (
              'Submit'
            ) : (
              'Next'
            )}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default KycVerification;

import { Grid, Box, Tooltip, IconButton, Typography } from "@mui/material";
import { ItemRowTable } from "./ItemRowTable";
import { InfoOutlined } from "@mui/icons-material";
import ItemColumnValue from "./ItemColumnValue";
import { useSnackbar } from "../../SnackbarProvider";
import { useNavigate } from "react-router-dom";
import { isMobile } from "../../../ultils/ultils";

const InstantMode = ({ currentBalance }) => {
    const showSnackbar = useSnackbar();
    console.log("currentBalance", currentBalance);
    const navigate = useNavigate();
  return (
    <div
      style={{ marginTop: "20px", display: "flex", textAlign: "center", flexDirection:"row", width:"100%", overflow:"auto", }}
    >
      <div style={{ minWidth: 900, display: "flex", textAlign: "center", flexDirection:"row", width:"100%" }}>
      <div className="step-col-instant" >
        <Box sx={{ height: "120px" }}></Box>
        <ItemRowTable
          content="Trading cycle"
          heightStepFirst
          showDescription
          indexIconText={0}
          fontSize={16}
        />
        <ItemRowTable
          content="Scaling speed"
          sx={{ height: "120px !important" }}
          showDescription
          classStep="step-item"
          fontSize={16}
        />
        <ItemRowTable
          content="Maximum loss"
          showDescription
          indexIconText={2}
          fontSize={16}
        />
        <ItemRowTable
          content="Split"
          showDescription
          classStep
          fontSize={16}
        />
        <ItemRowTable
          content="The examination fee"
          showDescription
          fontSize={16}
        />
        <ItemRowTable
          content="Allow major news"
          showDescription
          fontSize={16}
          classStep
        />
        <ItemRowTable content="Leverage" showDescription fontSize={16} />
        <ItemRowTable
          content="Weekend Holding"
          showDescription
          fontSize={16}
          classStep
         
        />
        <ItemRowTable content="RULE" showDescription fontSize={16} />
        <ItemRowTable
          content="Drawdown"
          showDescription
          fontSize={16}
          classStep
        />
        <ItemRowTable content="Rule payout" showDescription fontSize={16}  />
        <ItemRowTable
          content="Fee volume trading"
          showDescription
          fontSize={16}
          classStep
        />
        <ItemRowTable
          content="Fee market trading"
          showDescription
          fontSize={16}
        />
         <ItemRowTable
          content=""
          showDescription
          fontSize={16}
          classStep
        />
      </div>
     
        <div className="step-col-instant" >
          <Box sx={{ height: "120px" }}></Box>
          <ItemColumnValue
            content="Unlimited trading days"
            fontSize={16}
            isSub
            textColor="white"
          />
          <ItemColumnValue
            content={currentBalance?.scaling_speed}
            fontSize={16}
            textColor="dark-gray"
           
          />

          <ItemColumnValue
            content={`10%`}
            fontSize={16}
            isSub
            textColor="blue"
          />
          <ItemColumnValue
            content={`${currentBalance?.split}%`}
            fontSize={16}
            textColor="blue"
          />
          <ItemColumnValue
            content={`$${currentBalance?.fee_amt}`}
            fontSize={16}
            isSub
            textColor="blue"
          />
          <ItemColumnValue
            content={currentBalance?.allow_major_news}
            fontSize={16}
            textColor="dark-gray"
          />
          <ItemColumnValue
            content={currentBalance?.leverage_step1}
            fontSize={16}
            isSub
            textColor="dark-gray"
          />
          <ItemColumnValue
            content={currentBalance?.weekend_holding}
            fontSize={16}
            textColor="white"
          />
          <ItemColumnValue
            content={"No hidden rule"}
            fontSize={16}
            textColor="dark-gray"
            isSub
          />

          <ItemColumnValue
            content={"Smart drawdown"}
            fontSize={16}
            textColor="white"
            info="Account drawdown limits are calculated using Smart Drawdown, which differs from the Static Drawdown calculation method used in Two-Phase accounts. This means that whenever you generate a minimum profit of 5%, the drawdown limit will be locked at 5% instead of 10% (the initial level).
                        If you wish to participate in this account to be able to withdraw funds early, you need to achieve a minimum of 5% profit for your first withdrawal, with no requirements for subsequent withdrawals.
                        After the first withdrawal, the account drawdown limit is only 5%, not 10% as initially set."
          />
          <ItemColumnValue
            content={currentBalance?.rule_payout}
            fontSize={16}
            textColor="dark-gray"
            isSub={true}
          />
          <ItemColumnValue
            content={currentBalance?.fee_volume_trading1}
            fontSize={16}
            textColor="white"
          />
          <ItemColumnValue
            content={currentBalance?.fee_market_trading1}
            fontSize={16}
            isSub
            textColor="dark-gray"
          />

          <ItemColumnValue
            content={'Challenge X1K'}
            fontSize={16}
            textColor="blue"
            onClick={() => {
              if(!isMobile()) {
                alert("Please use the desktop version to access this feature !")
                return
              }
              if (!localStorage.getItem("wallet_address")) {
                showSnackbar('Connect wallet, please !', 'warning');
              } else {
                navigate("/profile/deposit");
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default InstantMode;

export const baseChainId = '0x2105'; // Chain ID for Ethereum Base
export const baseChainParams = {
    chainId: baseChainId,
    chainName: 'Base',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://mainnet.base.org'], // RPC URL for Base mainnet
    blockExplorerUrls: ['https://explorer.base.org'],
  };
  

import { useEffect, useRef } from "react";
import { CssBaseline, Box } from '@mui/material';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import MainDashboard from './component/main-dashboard/main-dashboard';
import MainProfile from './component/main-profile/main-profile';
import { SnackbarProvider } from './component/SnackbarProvider';
import ReactGA from 'react-ga4';
import ProtectedRoute from "./hooks/ProtectedRoute";

const DashboardLayout = () => {

  const navigate = useNavigate();
  const timeoutRef = useRef(null); // Để lưu timeout
  const idleTime = 30 * 60 * 1000; // Thời gian không tương tác (30 phút)

  // Hàm reset lại thời gian đếm
  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); // Xóa timeout cũ
    }

    // Thiết lập lại timeout
    timeoutRef.current = setTimeout(() => {
      window.location.reload(); // Reload lại trang
    }, idleTime); // Đếm lại 30 phút
  };

  useEffect(() => {
    // Gọi resetTimeout để khởi tạo đếm thời gian ban đầu
    resetTimeout();

    // Lắng nghe các sự kiện tương tác từ người dùng
    const events = ['mousemove', 'click', 'keydown', 'scroll', 'touchstart'];
    events.forEach((event) =>
      window.addEventListener(event, resetTimeout)
    );

    // Cleanup khi component bị unmount
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      events.forEach((event) =>
        window.removeEventListener(event, resetTimeout)
      );
    };
  }, []);
  useEffect(() => {
    const userAgent = navigator.userAgent;
    // Kiểm tra từ khóa "Firefox" trong userAgent

    const handleBeforeUnload = () => {
      sessionStorage.clear(); // Xóa toàn bộ sessionStorage
    };

    if (userAgent.includes("OPR")) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    sessionStorage.setItem("sessionId", new Date().toISOString());
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
    ReactGA.send("pageview");
  }, []);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', (accounts) => {
        if (localStorage.getItem('wallet_address') != null && localStorage.getItem('wallet_address') != 'undefined') {
          localStorage.setItem('wallet_address', accounts[0]);
          if (sessionStorage.getItem('account')) {
            sessionStorage.removeItem('account');
            navigate('/dashboard');
            window.location.reload();
          }
        }
      });
    } else {
      console.error('MetaMask is not installed.');
    }
  }, []);


  return (
    <Box>
      <CssBaseline />
      <SnackbarProvider>
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/dashboard/*" element={<MainDashboard />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/profile/*" element={<MainProfile />} />
          </Route>
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </SnackbarProvider>
    </Box>
  );
};

export default DashboardLayout;


import { Box, Toolbar, Typography, Button, Tabs, Tab, TableCell, TableBody, Table, TableContainer, TableHead, TableRow } from "@mui/material";
import PropTypes from 'prop-types';
import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid2";
import BreadcrumbsNav from "../Breadcrumbs"
import "./ref-fee.style.css";
import { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import CheckIcon from '@mui/icons-material/Check';
import api_key from '../../instance_api_key';
import { useSnackbar } from '../SnackbarProvider';
import axios from "axios";
import useRefundEth from "../../hooks/useRefundEth";
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import api_jwt_wallet from '../../instance_api_jwt';
import { setNavigate } from '../../instance_api_jwt';
import { useNavigate } from 'react-router-dom';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const RefFee = () => {
    const navigate = useNavigate();
    setNavigate(navigate);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '30%',
        bgcolor: 'background.paper',
        borderRadius: '10px'
    };


    const [open, setOpen] = React.useState(false);
    const [itemClaim, setItemClaim] = React.useState(null);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [isClaimAll, setIsClaimAll] = useState(false);

    const paginationModel = { page: 0, pageSize: 5 };
    // State for managing the sort model

    const [dataTable, setDataTable] = useState([]);
    const [dataRef, setDataRef] = useState([]);
    const [dataRefFee, setDataRefFee] = useState([]);
    const [walletAddress, setWalletAddress] = useState("");
    let isEffectExecuted = false;
    let a_dataAccount = [];

    useEffect(() => {
        if (!isEffectExecuted) {
            isEffectExecuted = true;
            // const fullData = rows;
            const b_wallet_address = localStorage.getItem('wallet_address');
            setWalletAddress(b_wallet_address);
            loadData(b_wallet_address);
        }
        getProfileData();
        fetchEthPrice();
    }, []);

    const loadData = async (wallet_address) => {
        GetRefByWallet(wallet_address);
        GetRefFeeByWallet(wallet_address);
    }

    const GetRefByWallet = async (walletAddress) => {
        await api_jwt_wallet.get(`/api/ref/getRefByWalletAddress/${walletAddress}`)
            .then(response => {
                if (response.data) {
                    setDataRef(response.data);
                }
            })
            .catch(error => console.error(error));
    }



    const GetRefFeeByWallet = async (walletAddress) => {
        await api_jwt_wallet.get(`/api/ref/getRefFeeByWalletAddress/${walletAddress}`)
            .then(response => {
                if (response.data) {
                    setDataRefFee(response.data);
                    a_dataAccount = response.data;
                }
            })
            .catch(error => console.error(error));
    }

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const showSnackbar = useSnackbar();
    const handleCopy = () => {
        navigator.clipboard.writeText(process.env.REACT_APP_WEB_URL + '/dashboard?referal_code=' + referal_code)
            .then(() => {
                showSnackbar('Referal code copied !', 'info');
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    const [referal_code, setReferalCode] = useState('');
    const getProfileData = async () => {
        api_jwt_wallet.get(`/api/profile/getByWalletAddress/${localStorage.getItem('wallet_address')}`)
            .then((response) => {
                if (response.data) {
                    setReferalCode(response.data.referal_code);
                }
            })
            .catch((error) => console.error(error));
    }

    const [ethPrice, setEthPrice] = useState(null);
    const fetchEthPrice = async () => {
        try {
            // Fetch ETH price from CoinGecko API
            const responseBinance = await axios.get(
                'https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT'
            );
            const ethPriceBinance = Number(responseBinance.data.price).toFixed(2);
            setEthPrice(ethPriceBinance);
        } catch (error) {
            console.error("Error fetching ETH price:", error);
        }
    };

    const getTotalClaimRef = () => {
        let total = 0;
        if (dataRef) {
            dataRef.forEach((row) => {
                if (row.status === 0) {
                    total += Number((row.eth_commission / Number(ethPrice)).toFixed(5));
                }
            });
        }
        return total;
    }

    const FetchUpdateRefStatus = async () => {
        api_key.post(`/api/ref/post_v1_update_ref_status`, { wallet_address: localStorage.getItem('wallet_address') })
            .then(response => {
                if (response.data.valid) {
                    showSnackbar('Claimed', 'success');
                }
            })
            .catch(error => console.error(error));
    }

    const { refundEth, isLoading, error, transactionHash } = useRefundEth(process.env.REACT_APP_BASE_SEPOLIA_INFURA_URL, process.env.REACT_APP_PRIVATE_KEY);
    const [isProcessingClaim, setIsProcessingClaim] = useState(false);
    const handleRefClaimAll = async () => {
        if (getTotalClaimRef() == 0) {
            showSnackbar('No commission to claim', 'warning');
            return;
        }
        api_key.post(`/api/ref/post_v1_update_ref_claim_check`, { wallet_address: localStorage.getItem('wallet_address') })
            .then(async response => {
                if (response.data.valid) {
                    loadData(localStorage.getItem('wallet_address'));
                    setIsProcessingClaim(true);
                    await refundEth(localStorage.getItem('wallet_address'), getTotalClaimRef(), null, null, null);
                    if (!error) {
                        setIsProcessingClaim(false);
                        FetchUpdateRefStatus();
                        loadData(localStorage.getItem('wallet_address'));
                    } else {
                        setIsProcessingClaim(false);
                    }
                } else {
                    showSnackbar('No commission to claim', 'warning');
                }
            })
            .catch(error => console.error(error));


    };

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3, background: '#F8F8F8' }}>
            <Toolbar />
            <BreadcrumbsNav />
            <br></br>
            <Grid container spacing={3}>
                <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                    <Typography variant="h7" gutterBottom>
                        Link ref: <span
                            onClick={handleCopy}
                            className="ref_copy"
                        >
                            {process.env.REACT_APP_WEB_URL}/dashboard?referal_code={referal_code}
                        </span>
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                        Ref commission
                    </Typography>
                </Grid>
                <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Ref fee sign up" {...a11yProps(0)} />
                                <Tab label="Ref fee trading" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <TableContainer sx={{ padding: '0px', maxHeight: 500, overflow: 'auto' }} component={Paper}>
                                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Account</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Eth Price</TableCell>
                                            <TableCell>Eth commission</TableCell>
                                            <TableCell>Action</TableCell>
                                            <TableCell>Transaction</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {dataRef.map((row) => (
                                            <TableRow
                                                key={row.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell> {row.user_name} </TableCell>
                                                <TableCell> {row.type} </TableCell>
                                                <TableCell> {(row.eth_price && !isNaN(row.eth_price)) ? (row.eth_price / Number(ethPrice)).toFixed(5) : null} </TableCell>
                                                <TableCell> {row.claim_amt} </TableCell>
                                                <TableCell> {row.status === 0 ? 'Not Claim' : (row.status === 1 ? 'Processing' : 'Claimed') } </TableCell>
                                                <TableCell> <a href={"https://base-sepolia.blockscout.com/tx/" + row.tx_id} target="_blank">{row.tx_id}</a> </TableCell>
                                            </TableRow>

                                        ))}
                                        {(!dataRef || dataRef.length == 0) &&
                                            <TableRow
                                                key='none'
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell colSpan={10}> No data </TableCell>
                                            </TableRow>}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            Item Two
                        </CustomTabPanel>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};
export default RefFee

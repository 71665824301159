import { useEffect } from "react";


export function useDailyMarketCloseTrigger(onMarketClose) {
    useEffect(() => {
        const scheduleNextCall = () => {
            const now = new Date();
            const nextTrigger = new Date(now);
            nextTrigger.setUTCHours(21, 59, 0, 0); 
            if (now >= nextTrigger) {
                nextTrigger.setUTCDate(nextTrigger.getUTCDate() + 1);
            }

            const timeUntilNextTrigger = nextTrigger - now;
           

            return setTimeout(() => {
                onMarketClose();
                scheduleNextCall(); 
            }, timeUntilNextTrigger);
        };

        const timeoutId = scheduleNextCall();

        return () => clearTimeout(timeoutId); 
    }, []);
}
import api from "../../../instance_api"

export const updateSmartDrawDown = async (payload) => {
    const { id , is_enabled_smart_drawdown} = payload
    try {
        const response = await api.patch(`/api/account/enable_smart_drawdown/${id}`,  { is_enabled_smart_drawdown })
        return response.data
    } catch (error) {
        
    }
}

export const lockTradingUser = async (payload) => {
    const { id , current_price} = payload
    try {
        const response = await api.patch(`/api/account/lock_total_loss_left/${id}`,  { current_price })
        return response.data
    } catch (error) {
        
    }
}




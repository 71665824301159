
export function isValidDecimal(value) {
    const decimalRegex = /^(\d*\.?\d*)$/; // Cập nhật regex
    return decimalRegex.test(value);
}
export const COUNT_DECIMAL_TOKEN = {
    ETH: 2,
    BTC: 2,
    UNI : 3,
    BNB: 2,
    DOGE: 5,
    XRP: 4,
    SOL: 2,
    NEAR: 3,
    ADA: 4,
    LINK: 2,
    TRX: 4,
    AVAX: 2,
    LTC: 2,
    TRUMP: 2,
    SHIB: 8,
    PEPE: 8,
    APT: 2,
    TON: 3,
    SUI: 4,
    
    XLM: 4,
    DOT: 3,
    OM: 3,
    BCH: 2,
    ICP: 3,
    ONDO: 4,
    AAVE: 2,
    ETC: 3,
    TIA: 3,
    FIL: 3,
    ATOM: 3,
    RENDER: 3,
    ALGO: 4,
    ARB: 4,
    JUP: 4,
    OP: 4,
    MKR: 2,
    STX: 4,
    SEI: 4,
    INJ: 3,
    THETA: 4,
    EOS: 4,
    BERA: 3,
    LDO: 4,
    GALA: 5,
    XTZ: 4,
    SAND: 4,
    DYDX: 4,
    PYTH: 4,
    
    USDJPY: 4,
    USDCHF: 5,
    AUDUSD: 5,
    EURUSD: 5,
    XAUUSD: 3,
    XAGUSD: 5,
    USDCAD: 5,
    EURGBP: 5,
    EURJPY: 3,
    GBPUSD: 5,
    GBPJPY: 3,
    
    AUDJPY: 5,  
    EURCHF: 5,  
    NZDJPY: 5,  
    CADJPY: 5,  
    GBPCHF: 5,  
    AUDNZD: 5,  
    CHFJPY: 5,  
    EURAUD: 5,  
    EURCAD: 5,  
    GBPAUD: 5,  
    GBPCAD: 5,  
    NZDCHF: 5,  
    AUDCHF: 5,  
    AUDCAD: 5,  
    NZDCAD: 5,

}
export function formatPriceDecimal(num, token = '', optionCount) {
    if (!num) {
        return 0
    }
    let count = 3;
    if (optionCount) {
        count = optionCount
    }else {
        if(token) {
            count = COUNT_DECIMAL_TOKEN[token] || 3;
        }else {
           const localToken = sessionStorage.getItem("token")
           count = COUNT_DECIMAL_TOKEN[localToken] || 0
        }
    }
    return parseFloat(Number(num).toFixed(count));
}

export function formatNumberDisplayWithZeros(num) {
    const str = num.toString();
    const decimalIndex = str.indexOf('.');
    if (decimalIndex === -1) {
        return str;
    }
    const decimalPart = str.substring(decimalIndex + 1);
    
    let zeroCount = 0;
    for (let i = 0; i < decimalPart.length && zeroCount < 2; i++) {
        if (decimalPart[i] === '0') {
            zeroCount++;
        } else {
            break;
        }
    }
    
    if (zeroCount >= 5) {
        let nonZeroPart = decimalPart.substring(zeroCount); // Phần không phải số 0
        return `0.0{${zeroCount}}${nonZeroPart}`;
    } else {
        return str;
    }
}
export const isMobile = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice =
      /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(userAgent)
      console.log(isMobileDevice)
      return isMobileDevice 
  }
export function isTodayInDays(daysArray) {
    const today = new Date().getUTCDay();
    return daysArray.includes(today);
}

export function isMarketClosed(symbol, date) {
    const now = date || new Date();
    const utcDay = now.getUTCDay();
    const utcHour = now.getUTCHours();
    const utcMinute = now.getUTCMinutes();
   
    // Xử lý riêng cho XAUUSD và XAGUSD
    if (symbol && (symbol === "XAUUSD" || symbol === "XAGUSD")) {
        // Đóng cửa cuối tuần: từ 20:59 Thứ Sáu đến 22:05 Chủ Nhật
        if (
            (utcDay === 0 && (utcHour < 22 || (utcHour === 22 && utcMinute <= 10))) || // Chủ nhật trước 22:05
            (utcDay === 5 && (utcHour > 20 || (utcHour === 20 && utcMinute >= 59))) || // Thứ 6 sau 20:59
            (utcDay === 6) // Toàn bộ thứ 7
        ) {
            return true;
        }
       
        // Khoảng nghỉ hàng ngày từ 20:59 đến 22:05
        if (
            (utcHour === 20 && utcMinute >= 59) || // từ 20:59
            (utcHour === 21) || // toàn bộ 21h
            (utcHour === 22 && utcMinute <= 10) // đến 22:05
        ) {
            return true;
        }
    } else {
        // Forex: Đóng cửa cuối tuần từ 20:59 Thứ Sáu đến 21:05 Chủ Nhật
        if (
            (utcDay === 0 && (utcHour < 21 || (utcHour === 21 && utcMinute <= 10))) || // Chủ nhật trước 21:05
            (utcDay === 5 && (utcHour > 20 || (utcHour === 20 && utcMinute >= 59))) || // Thứ 6 sau 20:59
            (utcDay === 6) // Toàn bộ thứ 7
        ) {
            return true;
        }
       
        // Khoảng nghỉ hàng ngày từ 20:59 đến 21:05
        if (
            (utcHour === 20 && utcMinute >= 59) || // từ 20:59
            (utcHour === 21 && utcMinute <= 10) // đến 21:05
        ) {
            return true;
        }
    }
   
    return false;
}

export function calculateHoursPassedBorrow(txtTime, symbol, type) {
    const [date, time] = txtTime.split(' ');
    const [day, month, year] = date.split('/');
    const [hours, minutes, seconds] = time.split(':');

    // Tạo đối tượng Date cho thời điểm đặt lệnh (orderDate)
    const orderDate = new Date(Date.UTC(
        parseInt(year),
        parseInt(month) - 1,
        parseInt(day),
        parseInt(hours),
        parseInt(minutes),
        parseInt(seconds)
    ));

    // Lấy thời gian hiện tại theo UTC
    const now = new Date();
    const currentDate = new Date(Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds()
    ));

    let hoursPassed = 0;
    let tempDate = new Date(orderDate);

    // Tính số giờ đã qua, chỉ tính các giờ khi thị trường mở cửa
    while (tempDate.getTime() <= currentDate.getTime()) {
        if ((!isMarketClosed(symbol, tempDate) && type === "FX") || type === 'CRYPTO') {
            hoursPassed++;
        }
        // Tăng thêm 1 giờ
        tempDate.setTime(tempDate.getTime() + 60 * 60 * 1000);
    }

    return hoursPassed;
}

export function calculateFundingRate(funding, type) {
    const fundingRate = Number(funding);
    if (type == "Long") {
        if (fundingRate < 0) {
            return Math.abs(fundingRate) 
        }else{
            return Math.abs(fundingRate) * -1
        }
    }else{
        if (fundingRate < 0) {
            return Math.abs(fundingRate) * -1;
        }else{
            return Math.abs(fundingRate)
        }
    }
}

export function shortenAddress(address, startLength = 5, endLength = 5) {
    if (!address) {
      return ''
    }
    if (typeof address !== 'string' || address.length < startLength + endLength + 3) {
        throw new Error('Invalid address format');
    }
    
    return `${address.slice(0, startLength)}...${address.slice(-endLength)}`;
  }

 export function convertNumber(string) {
    if (!string) {
        return ''
    }
    const num = Number(string.toString().replace("$", ''));
    if (isNaN(num)) return NaN; // Kiểm tra nếu không phải số, trả về NaN

    const roundedNum = Number(num.toFixed(1)); // Chuyển về số sau khi làm tròn

    return Number.isInteger(roundedNum) ? Math.floor(roundedNum) : roundedNum;
}

/**
 * Chuyển đổi số thành dạng ký hiệu "k" (nghìn)
 * Ví dụ: 1000 -> 1k, 1500 -> 1.5k, 500000 -> 500k
 * @param {number} number - Số cần chuyển đổi
 * @param {number} decimals - Số chữ số thập phân muốn giữ lại (mặc định là 1)
 * @returns {string} Chuỗi đã được chuyển đổi với ký hiệu "k"
 */
export function convertToK(number, decimals = 1) {
    // Return empty string if number is undefined or null
    if (number === undefined || number === null) {
      return '';
    }
    
    // For numbers less than 1000, return as is
    if (number < 1000) {
      return number.toString();
    }
    
    // For numbers between 1000 and 999999, convert to K format
    if (number < 1000000) {
      const valueInK = number / 1000;
      const roundedValue = valueInK.toFixed(decimals);
      // Remove trailing zeros
      const result = parseFloat(roundedValue).toString() + 'k';
      return result;
    }
    
    // For numbers 1000000 or greater, convert to M format
    const valueInM = number / 1000000;
    const roundedValue = valueInM.toFixed(decimals);
    // Remove trailing zeros
    const result = parseFloat(roundedValue).toString() + 'M';
    return result;
  }
  export function formatCurrency(num, currency = "", isFull = false) {
    if (!num || num === "") {
      let response = "0 " + currency;
      return response;
    }
    num = Number(num);
    if (num === 0) {
      return "0 " + currency;
    }
    if (num.length === 2) {
      if (num === "00") {
        num = num.replace("00", "0");
      }
    }
    if (num.length > 1) {
      let first = num.substring(0, 1);
      if (first === "0") {
        num = num.substring(1, num.length);
      }
    }
    let result = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    
    return result + currency;
  }
  
import {
    Box, Toolbar, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Paper, Typography,
    Button, CircularProgress
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import BreadcrumbsNav from "../Breadcrumbs"
import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import api from "../../instance_api";

const EconomicCalendar = () => {
    const [economicData, setEconomicData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    const fetchEconomicData = async () => {
        try {
            setLoading(true);
            const response = await api.get('/api/web3/get-trading-economics');
            setEconomicData(response.data);
            setLoading(false);
        } catch (err) {
            console.error('Error fetching economic data:', err);
            setError('Failed to load economic data. Please try again later.');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEconomicData();
    }, []);

    // Format date for display
    const formatDate = (dateString) => {
        if (!dateString) return '';
        try {
            return format(new Date(dateString), 'MMM dd, yyyy HH:mm');
        } catch (error) {
            return dateString;
        }
    };

    // Determine importance level display
    const getImportanceLevel = (level) => {
        switch(level) {
            case 1: return 'Low';
            case 2: return 'Medium';
            case 3: return 'High';
            default: return 'N/A';
        }
    };

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3, background: '#F8F8F8' }}>
            <Toolbar />
            <BreadcrumbsNav />
            <br />
            
            <Paper sx={{ width: '100%', mb: 2, borderRadius: '10px', overflow: 'hidden' }}>
                <Typography
                    variant="h6"
                    component="div"
                    sx={{ p: 2, fontWeight: 'bold', backgroundColor: '#f5f5f5', borderBottom: '1px solid #e0e0e0' }}
                >
                    Economic Calendar
                </Typography>
                
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Box sx={{ p: 3, color: 'error.main' }}>
                        {error}
                    </Box>
                ) : (
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="economic calendar table">
                            <TableHead>
                                <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Country</TableCell>
                                    <TableCell>Event</TableCell>
                                    <TableCell>Symbol</TableCell>
                                    <TableCell>Actual</TableCell>
                                    <TableCell>Forecast</TableCell>
                                    <TableCell>Previous</TableCell>
                                    <TableCell>Importance</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {economicData.length > 0 ? (
                                    economicData.map((row) => (
                                        <TableRow
                                            key={row.CalendarId}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, 
                                                 '&:hover': { backgroundColor: '#f9f9f9' } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {formatDate(row.Date)}
                                            </TableCell>
                                            <TableCell>{row.Country}</TableCell>
                                            <TableCell>
                                                <Box>
                                                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                        {row.Event}
                                                    </Typography>
                                                    <Typography variant="caption" color="text.secondary">
                                                        {row.Category} • {row.Reference}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2" sx={{ fontFamily: 'monospace', fontWeight: 'bold' }}>
                                                    {row.Symbol || 'N/A'}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ 
                                                fontWeight: 'bold',
                                                color: row.Actual && row.Previous ? 
                                                    (parseFloat(row.Actual) > parseFloat(row.Previous) ? 'success.main' : 
                                                     parseFloat(row.Actual) < parseFloat(row.Previous) ? 'error.main' : 'inherit') 
                                                    : 'inherit'
                                            }}>
                                                {row.Actual || 'N/A'}
                                            </TableCell>
                                            <TableCell>{row.Forecast || row.TEForecast || 'N/A'}</TableCell>
                                            <TableCell>{row.Previous || 'N/A'}</TableCell>
                                            <TableCell>
                                                <Box sx={{ 
                                                    display: 'inline-block',
                                                    px: 1,
                                                    py: 0.5,
                                                    borderRadius: '4px',
                                                    backgroundColor: row.Importance === 3 ? '#ffebee' : 
                                                                    row.Importance === 2 ? '#fff8e1' : '#e8f5e9',
                                                    color: row.Importance === 3 ? '#c62828' : 
                                                           row.Importance === 2 ? '#f57f17' : '#2e7d32',
                                                    fontSize: '0.75rem'
                                                }}>
                                                    {getImportanceLevel(row.Importance)}
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={8} align="center">No economic data available</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Paper>
        </Box>
    );
};
export default EconomicCalendar

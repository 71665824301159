import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import api from "../../instance_api";
import { useLocation } from "react-router-dom";
import "./account-overview.style.css";
import { convertToK } from "../../ultils/ultils";
import { getTotalPnlOfTransactions } from "../../ultils/pnl_ultil";
import { usePriceContext } from "../../hooks/PriceContext";

const TradingRules = ({ balance, account }) => {
  const [tradingOverviewData, setTradingOverviewData] = useState({
    total_qualified_days: 0,
    total_drawdown: null,
    daily_drawdown: null,
    round_profit: null,
    is_has_tx_with_40_percent_profit_of_user: false,
    smart_drawdown_active: null

  });
  const [bestTrade, setBestTrade] = useState();
  const [transactions, setTransactions] = useState([])
  // Get the location object to access query parameters
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { priceFX, priceCrypto, fundingRateCrypto } = usePriceContext();

  useEffect(() => {
    // Get account ID from query params or session storage
    let accountId = queryParams.get("id");
    if (!accountId) {
      accountId = sessionStorage.getItem("account");
    }
    if (accountId) {
      fetchTradingOverviewData(accountId);
      fetchTradingDataTrade(accountId);
    }
  }, []);

  const fetchTradingDataTrade = async (accountId) => {
    try {
      const response = await api.get(
        "/api/account/get_v1_account_trading_details/" + accountId
      );
      setBestTrade(response.data?.best_trade);
    } catch (error) {
      console.error("Error fetching trading overview data:", error);
    }
  };

  const fetchTradingOverviewData = async (accountId) => {
    try {
      const response = await api.get(
        `/api/account/get_v1_account_trading_overview/${accountId}`
      );
      setTradingOverviewData(response.data);
      setTransactions(response.data?.trading_transactions || [])
    } catch (error) {
      console.error("Error fetching trading overview data:", error);
    }
  };

  const getTodayPnl = () => {
    const pnl = getTotalPnlOfTransactions({
      transactions: transactions,
      priceCrypto : priceCrypto,
      priceFx: priceFX,
      fudingRateAll: fundingRateCrypto
      })
      
      const daily_loss = Number(pnl + (tradingOverviewData?.total_drawdown?.total_pnl || 0))
      return daily_loss.toFixed(3)
}

const getTotalPnl = () => {
    const pnl = getTotalPnlOfTransactions({
      transactions: transactions,
      priceCrypto : priceCrypto,
      priceFx: priceFX,
      fudingRateAll: fundingRateCrypto
    })
      
    const total_loss = Number(pnl + (tradingOverviewData?.total_drawdown?.total_pnl || 0))
    return total_loss.toFixed(3)
}

  // Calculate rule statuses based on API data
  const getRuleStatus = (ruleType) => {
    const ruleDaily = tradingOverviewData?.daily_drawdown?.lost_condition
    const ruleTotal = tradingOverviewData?.total_drawdown?.lost_condition
    switch (ruleType) {
      case "min_volume": // 3 days with 0.5% profit
        return tradingOverviewData?.min_volume?.isFailed
          ? "pending"
          : "success";
      case "round_profit":
        // Logic for profit target
        return !tradingOverviewData?.round_profit?.isFailed
          ? "success"
          : "pending";
      case "daily_drawdown": // Daily drawdown limit 5%
        if (ruleDaily && ruleDaily >= Number(getTodayPnl())) {
          return 'pending'
        }
        return 'success'
        // return tradingOverviewData?.daily_drawdown &&
        //   tradingOverviewData?.daily_drawdown?.isFailed
        //   ? "pending"
        //   : "success";
      case "total_drawdown": // Total drawdown limit 10%
        // Check if total drawdown exceeds 10%
        if (ruleTotal && ruleTotal >= Number(getTodayPnl())) {
          return 'pending'
        }
        return 'success'
        // return tradingOverviewData?.total_drawdown?.isFailed
        //   ? "pending"
        //   : "success";
      case "is_has_tx_with_40_percent_profit_of_user": // Single profitable trade > 40% of total profit
        // Logic for single trade profit
        if (!bestTrade) {
          return 'success'
        }
        if (bestTrade && Number(bestTrade.pnl_amt) <= (Number(getTotalPnl())) * 0.4) {
          return 'success'
        }
        return 'pending'
        // return !tradingOverviewData?.is_has_tx_with_40_percent_profit_of_user
        //   ? "pending"
        //   : "success";
      case "smart_drawdown_active":
        return tradingOverviewData?.smart_drawdown_active?.isFailed
          ? "pending"
          : "success";
      case "is_trade_with_red_new":
        return !tradingOverviewData?.is_trade_with_red_new
          ? "pending"
          : "success";

      case "is_hold_through_week":
        return !tradingOverviewData?.is_hold_through_week
          ? "pending"
          : "success";
      default:
        return "success";
    }
  };

  // Get bottom text values based on API data
  const getBottomText = (ruleType) => {
    switch (ruleType) {
      case "min_volume": // 3 days with 0.5% profit
        return tradingOverviewData?.min_volume
          ? `${convertToK(tradingOverviewData?.min_volume.current_volume)}/${convertToK(tradingOverviewData?.min_volume.min_volume)}`
          : "";
      case "round_profit": // Profit 8%/5%
        return tradingOverviewData?.round_profit
          ? `${tradingOverviewData?.round_profit?.pnl.toFixed(3)}/${tradingOverviewData?.round_profit?.required_profit}`
          : "";
      case "daily_drawdown": // Daily drawdown limit 5%
        return tradingOverviewData?.daily_drawdown
          ? `${getTodayPnl()}/${tradingOverviewData.daily_drawdown.lost_condition}`
          : "";
      case "total_drawdown": // Total drawdown limit 10%
        return tradingOverviewData?.total_drawdown
          ? `${getTotalPnl()}/${tradingOverviewData.total_drawdown.lost_condition}`
          : "";
      case "is_has_tx_with_40_percent_profit_of_user": // Single profitable trade > 40% of total profit
        return bestTrade
          ? `${bestTrade.pnl_amt.toFixed(2)}/${getTotalPnl()}`
          : ``;
      case "smart_drawdown_active": // Single profitable trade > 40% of total profit
        return tradingOverviewData?.smart_drawdown_active
          ? `${convertToK(tradingOverviewData?.smart_drawdown_active?.total_pnl)}/${tradingOverviewData?.smart_drawdown_active?.lost_condition}`
          : ``;
      default:
        return "";
    }
  };

  // Default rules if none are provided
  const swingRules = [
    {
      type: "min_volume",
      text: "Min volume",
      status: getRuleStatus("min_volume"),
      showIcon: false,
      bottomText: getBottomText("min_volume"),
    },
    {
      type: "round_profit",
      text: `Profit ${tradingOverviewData?.round_profit?.ratio_profit || ""}%`,
      status: getRuleStatus("round_profit"),
      showIcon: true,
      bottomText: getBottomText("round_profit"),
    },
    {
      type: "daily_drawdown",
      text: "Daily drawdown limit 5%",
      status: getRuleStatus("daily_drawdown"),
      showIcon: false,
      bottomText: getBottomText("daily_drawdown"),
    },
    {
      type: "total_drawdown",
      text: "Total drawdown limit 10%",
      status: getRuleStatus("total_drawdown"),
      showIcon: false,
      bottomText: getBottomText("total_drawdown"),
    },
    {
      type: "is_has_tx_with_40_percent_profit_of_user",
      text: "Trade Profit Limitation .No single trade can account for more than 40%",
      status: getRuleStatus("is_has_tx_with_40_percent_profit_of_user"),
      showIcon: true,
      bottomText: getBottomText("is_has_tx_with_40_percent_profit_of_user"),
    },
  ];

  const instantRulesSmart = [
    {
      type: "smart_drawdown_active",
      text: "Smart drawdown active",
      status: getRuleStatus("smart_drawdown_active"),
      showIcon: false,
      bottomText: getBottomText("smart_drawdown_active"),
    },
  ];

  const instantRulesNotSmart = [
    {
      type: "total_drawdown",
      text: "Total drawdown limit 10%",
      status: getRuleStatus("total_drawdown"),
      showIcon: false,
      bottomText: getBottomText("total_drawdown"),
    }

  ];
  const instantRules = [
    ...account?.is_enabled_smart_drawdown ? instantRulesSmart : instantRulesNotSmart,
    ...[{
      type: "is_trade_with_red_new",
      text: "Do not accepted trade with RED news before after 3 minutes",
      status: getRuleStatus("is_trade_with_red_new"),
      showIcon: false,
      bottomText: getBottomText("is_trade_with_red_new"),
    },
    {
      type: "is_hold_through_week",
      text: "Do not accept weekend holding with FX and commodities pair",
      status: getRuleStatus("is_hold_through_week"),
      showIcon: false,
      bottomText: getBottomText("is_hold_through_week"),
    }]
  ] 

  // Use provided rules or default rules
  const defaultRules = account?.mode === "INSTANT" ? instantRules : swingRules;
  return (
    <Box sx={{ width: "100%" }}>
      <div style={{ display: "flex" }}>
        {defaultRules.map((rule, index) => (
          <div
            className="start-item"
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ minHeight: "140px" }}>
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/images/${rule.status === "success" ? "Success.png" : "Circle5.png"}`}
                  alt={rule.status === "success" ? "Success" : "Pending"}
                />
              </div>
              <i style={{ fontWeight: "600", fontSize: "14px" }}>{rule.text}</i>
            </div>
            <div
              style={{
                marginTop: "10px",
                fontSize: "16px",
                color: "#000",
                fontWeight: "bold",
                // borderTop: "1px dashed #e0e0e0",
                paddingTop: "8px",
              }}
            >
              {rule.bottomText}
            </div>
          </div>
        ))}
      </div>
    </Box>
  );
};

export default TradingRules;

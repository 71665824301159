import axios from 'axios';

let navigate;

export const setNavigate = (nav) => {
    navigate = nav;
};

const api_wallet_jwt = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

api_wallet_jwt.interceptors.request.use((config) => {
    config.withCredentials = true;
    const token = localStorage.getItem('wallet_token');
    if (token) {
        config.headers['Authorization'] = token;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

api_wallet_jwt.interceptors.response.use(
  response => response,
  async error => {
      const originalRequest = error.config;
      // Nếu lỗi là Access Token hết hạn, thử làm mới token
      if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
              const { data } = await axios.post(process.env.REACT_APP_API_URL + '/api/wallet/refresh_address', {}, { withCredentials: true });
              localStorage.setItem('wallet_token', data.accessToken);
              axios.defaults.headers.common['Authorization'] = data.accessToken;
              return api_wallet_jwt(originalRequest);
          } catch (err) {
              console.log('Failed to refresh token');
              localStorage.removeItem('wallet_token');
              localStorage.removeItem('wallet_address');
              if (navigate) {
                navigate('/dashboard');
            }
          }
      }
      return Promise.reject(error);
  }
);

export const getData = async (endpoint, params = {}) => {
    try {
      const response = await api_wallet_jwt.get(endpoint, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

export default api_wallet_jwt;
import { useCallback, useState, useEffect, useRef } from "react";
import { debounce, uniq } from "lodash";
import { formatPriceDecimal } from "../../../ultils/ultils";

export const usePriceFX = ({ tradeOrder, tradeTx, listTradingPair }) => {
    const [priceFX, setPriceFX] = useState({}); // null | Object
    const [loadingFX, setLoadingFX] = useState(false);
    const socketRef = useRef(null);
    const reconnectTimerRef = useRef(null);

    // Function to initialize WebSocket connection
    const initializeWebSocket = useCallback(() => {
        // Close existing socket if it exists
        if (socketRef.current) {
            socketRef.current.close();
        }

        const arrayTrade = listTradingPair.filter(c => c.type === "FX").map(c => c.symbol_exchange);
        
        if (arrayTrade.length < 1) {
            return;
        }
        
        console.log("Subscribing to FX pairs:", arrayTrade);
        const apiKey = 'wsUfeEm4M5EhPokH6_cQ';
        socketRef.current = new WebSocket("wss://ws.x1kfunded.finance");
        
        socketRef.current.onopen = () => {
            console.log("✅ Connected to WebSocket server");
            // You might need to send a subscription message here
            // socketRef.current.send(JSON.stringify({ 
            //     type: 'subscribe', 
            //     symbols: arrayTrade,
            //     apiKey 
            // }));
        };
        
        socketRef.current.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                if (data && data.symbol) {
                    onSetPriceToken(data);
                }
            } catch (error) {
                console.log("on message error", error);
            }
        };
        
        socketRef.current.onerror = (error) => console.error("❌ WebSocket error:", error);
        
        socketRef.current.onclose = () => {
            console.log("🔌 Connection closed");
        };
    }, [listTradingPair]);

    // Hàm hỗ trợ tính toán thời điểm kích hoạt
    const getNextSpecificTime = (now, hours, minutes) => {
        const nextTime = new Date(now);
        const currentHours = now.getUTCHours();
        const currentMinutes = now.getUTCMinutes();
        
        // Nếu thời gian hôm nay đã qua, lên lịch cho ngày mai
        if (currentHours > hours || (currentHours === hours && currentMinutes >= minutes)) {
            nextTime.setUTCDate(nextTime.getUTCDate() + 1);
        }
        
        nextTime.setUTCHours(hours, minutes, 0, 0);
        
        // Tránh lên lịch vào cuối tuần (trừ thời điểm mở cửa cuối tuần)
        const dayOfWeek = nextTime.getUTCDay();
        if (dayOfWeek === 6 || (dayOfWeek === 0 && hours < 21)) {
            // Bỏ qua thứ Bảy và Chủ nhật trước 21:00
            if (dayOfWeek === 6) {
                // Nếu là thứ Bảy, chuyển đến thứ Hai
                nextTime.setUTCDate(nextTime.getUTCDate() + 2);
            } else {
                // Nếu là Chủ nhật trước 21:00, chuyển đến thứ Hai
                nextTime.setUTCDate(nextTime.getUTCDate() + 1);
            }
        }
        
        return nextTime;
    };

    const getNextWeekendOpening = (now, extraMinutes = 0) => {
        const utcDay = now.getUTCDay();
        const nextOpening = new Date(now);
        
        // Nếu là Chủ nhật và trước 21:00, đặt trigger vào 21:00 + extraMinutes hôm nay
        if (utcDay === 0 && now.getUTCHours() < 21) {
            nextOpening.setUTCHours(21, extraMinutes, 0, 0);
            return nextOpening;
        }
        
        // Ngược lại, đặt cho Chủ nhật 21:00 + extraMinutes tuần tới
        let daysToAdd = 7 - utcDay;
        if (daysToAdd === 7) {
            daysToAdd = 0; // Nếu đã là Chủ nhật
        }
        nextOpening.setUTCDate(nextOpening.getUTCDate() + daysToAdd);
        
        // Nếu là Chủ nhật và đã qua 21:00 + extraMinutes, đặt cho tuần sau
        if (utcDay === 0 && (now.getUTCHours() > 21 || (now.getUTCHours() === 21 && now.getUTCMinutes() >= extraMinutes))) {
            nextOpening.setUTCDate(nextOpening.getUTCDate() + 7);
        }
        
        nextOpening.setUTCHours(21, extraMinutes, 0, 0);
        return nextOpening;
    };

    const getTriggerType = (triggerTime) => {
        const hours = triggerTime.getUTCHours();
        const minutes = triggerTime.getUTCMinutes();
        const day = triggerTime.getUTCDay();
        
        if (hours === 21 && minutes === 8) {
            return 'FOREX_DAILY_REOPEN_1'; // 4:08 giờ Việt Nam
        } else if (hours === 22 && minutes === 8) {
            return 'FOREX_DAILY_REOPEN_2'; // 5:08 giờ Việt Nam
        } else if (hours === 21 && minutes === 2 && day === 0) {
            return 'WEEKEND_OPENING';
        }
        
        return 'MARKET_REOPEN';
    };

    // Hàm lên lịch cho lần kết nối tiếp theo
    const scheduleMarketReconnection = useCallback(() => {
        // Xóa bất kỳ timer hiện tại nào
        if (reconnectTimerRef.current) {
            clearTimeout(reconnectTimerRef.current);
        }
        
        const now = new Date();
        
        // 2 thời điểm mở cửa lại thị trường theo giờ Việt Nam
        // 1. 4:08 giờ Việt Nam = 21:08 UTC
        const firstReopen = getNextSpecificTime(now, 21, 8);
        
        // 2. 5:08 giờ Việt Nam = 22:08 UTC
        const secondReopen = getNextSpecificTime(now, 22, 8);
        
        // 3. Mở cửa thị trường cuối tuần - Chủ nhật 21:02 UTC
        const weekendOpening = getNextWeekendOpening(now, 2);
        
        // Lọc ra các thời điểm hợp lệ
        const triggers = [firstReopen, secondReopen, weekendOpening].filter(t => t !== null);
        
        // Tìm thời điểm gần nhất
        const nextTrigger = triggers.reduce((closest, current) => 
            current < closest ? current : closest
        , triggers[0]);
        
        const timeUntilNextTrigger = nextTrigger - now;
        
        reconnectTimerRef.current = setTimeout(() => {
            const triggerTime = new Date();
            const triggerType = getTriggerType(triggerTime);
            
            console.log(`Reconnecting WebSocket at market reopening: ${triggerType} at ${triggerTime.toUTCString()}`);
            initializeWebSocket();
            
            // Lên lịch cho lần kích hoạt tiếp theo
            scheduleMarketReconnection();
        }, timeUntilNextTrigger);
        
        console.log(`Next WebSocket reconnection scheduled at ${nextTrigger.toUTCString()} (in ${Math.floor(timeUntilNextTrigger / 60000)} minutes)`);
    }, [initializeWebSocket]);

    // Initialize WebSocket and set up the market trigger
    useEffect(() => {
        initializeWebSocket();
        scheduleMarketReconnection();
        
        // Cleanup function
        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
            if (reconnectTimerRef.current) {
                clearTimeout(reconnectTimerRef.current);
            }
        };
    }, [listTradingPair, initializeWebSocket, scheduleMarketReconnection]);

    const onSetPriceToken = (data) => {
        const { symbol, price } = data;
        setPriceFX((prev) => ({
            ...prev,
            [symbol]: formatPriceDecimal(price, symbol)
        }));
    };

    const onSelectFx = (item) => {
        // Functionality for selecting FX if needed
    };

    return {
        priceFX,
        setPriceFX,
        onSelectFx,
        loadingFX,
        setLoadingFX
    };
};
import { useEffect, useRef } from "react";

/**
 * Hook để kích hoạt callback tại các thời điểm quan trọng trong ngày theo giờ thị trường
 * @param {Function} onTrigger - Callback được gọi khi đến thời điểm kích hoạt
 */
export function useMidnightTrigger(onTrigger) {
    // Sử dụng ref để theo dõi timeout ID
    const timeoutIdRef = useRef(null);
    // Ref để lưu lịch sử các trigger đã xử lý để tránh trigger lặp lại
    const processedTriggersRef = useRef(new Set());
    
    useEffect(() => {
        // Hàm lên lịch cho các trigger tiếp theo
        const scheduleNextTriggers = () => {
            const now = new Date();
            
            // Reset bộ nhớ trigger đã xử lý khi sang ngày mới
            if (now.getUTCHours() === 0 && now.getUTCMinutes() <= 5) {
                processedTriggersRef.current = new Set();
            }
            
            // Tạo mảng chứa tất cả các thời điểm trigger cần kiểm tra
            const triggerTimes = [
                // 1. 4:08 giờ Việt Nam = 21:08 UTC
                { time: getNextSpecificTime(now, 21, 8), type: 'FOREX_DAILY_REOPEN_1' },
                // 2. 5:08 giờ Việt Nam = 22:08 UTC
                { time: getNextSpecificTime(now, 22, 8), type: 'FOREX_DAILY_REOPEN_2' },
                // 3. 7:01 giờ Việt Nam = 00:01 UTC
                { time: getNextSpecificTime(now, 0, 1), type: 'FOREX_DAILY_REOPEN_3' },
                // 4. Mở cửa thị trường cuối tuần - Chủ nhật 21:02 UTC
                { time: getNextWeekendOpening(now, 2), type: 'WEEKEND_OPENING' }
            ].filter(trigger => trigger.time !== null);
            
            // Log các thời điểm trigger tiềm năng để debug
            triggerTimes.forEach(trigger => {
               
            });
            
            // Sắp xếp các trigger theo thời gian tăng dần
            triggerTimes.sort((a, b) => a.time - b.time);
            
            if (triggerTimes.length === 0) {
               
                timeoutIdRef.current = setTimeout(scheduleNextTriggers, 60 * 60 * 1000);
                return;
            }
            
            // Chọn trigger gần nhất
            const nextTrigger = triggerTimes[0];
            const triggerKey = `${nextTrigger.type}-${nextTrigger.time.toISOString().split('T')[0]}`;
            
            // Kiểm tra nếu trigger này đã được xử lý
            if (processedTriggersRef.current.has(triggerKey)) {
                console.log(`Trigger ${nextTrigger.type} ngày ${nextTrigger.time.toLocaleDateString("vi-VN")} đã được xử lý trước đó.`);
                
                // Nếu đã xử lý, di chuyển đến trigger tiếp theo hoặc kiểm tra lại sau 5 phút
                if (triggerTimes.length > 1) {
                    const secondNextTrigger = triggerTimes[1];
                    const timeUntilNext = secondNextTrigger.time - now;
                    console.log(`Chuyển đến trigger tiếp theo ${secondNextTrigger.type} sau ${Math.round(timeUntilNext/1000/60)} phút`);
                    
                    timeoutIdRef.current = setTimeout(() => {
                        scheduleNextTriggers();
                    }, timeUntilNext);
                } else {
                    console.log("Không còn trigger nào hôm nay. Kiểm tra lại sau 5 phút.");
                    timeoutIdRef.current = setTimeout(scheduleNextTriggers, 5 * 60 * 1000);
                }
                return;
            }
            
            // Tính thời gian còn lại đến trigger tiếp theo
            const timeUntilTrigger = Math.max(10, nextTrigger.time - now); // Tối thiểu 10ms
            
            
            // Đặt timeout cho trigger tiếp theo
            timeoutIdRef.current = setTimeout(() => {
                // Đánh dấu trigger này đã được xử lý
                processedTriggersRef.current.add(triggerKey);
                
                console.log(`Kích hoạt trigger: ${nextTrigger.type} vào ${new Date().toLocaleString("vi-VN")}`);
                
                // Gọi callback
                onTrigger({
                    triggerTime: nextTrigger.time,
                    triggerType: nextTrigger.type
                });
                
                // Lên lịch cho các trigger tiếp theo
                scheduleNextTriggers();
            }, timeUntilTrigger);
        };
        
        // Bắt đầu lên lịch
        scheduleNextTriggers();
        
        // Dọn dẹp khi component unmount
        return () => {
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
        };
    }, [onTrigger]);
}

/**
 * Tìm thời điểm trigger kế tiếp cho giờ và phút UTC cụ thể
 */
function getNextSpecificTime(now, hours, minutes) {
    const result = new Date(now);
    
    // Đặt giờ và phút cụ thể
    result.setUTCHours(hours, minutes, 0, 0);
    
    // Nếu thời gian này đã qua, chuyển sang ngày mai
    if (result <= now) {
        result.setUTCDate(result.getUTCDate() + 1);
    }
    
    // Kiểm tra xem có phải cuối tuần không
    const dayOfWeek = result.getUTCDay(); // 0 = Chủ nhật, 6 = Thứ bảy
    
    // Bỏ qua thứ Bảy và Chủ nhật (trước 21:00 UTC)
    if (dayOfWeek === 6) {
        // Thứ Bảy, chuyển sang thứ Hai
        result.setUTCDate(result.getUTCDate() + 2);
        return result;
    } else if (dayOfWeek === 0 && hours < 21) {
        // Chủ nhật trước 21:00 UTC, chuyển sang thứ Hai
        result.setUTCDate(result.getUTCDate() + 1);
        return result;
    }
    
    return result;
}

/**
 * Tìm thời điểm mở cửa thị trường vào cuối tuần tiếp theo
 */
function getNextWeekendOpening(now, extraMinutes = 0) {
    const nextOpening = new Date(now);
    const utcDay = now.getUTCDay(); // 0 = Chủ nhật, 6 = Thứ bảy
    
    // Đặt giờ mở cửa là 21:00 + extraMinutes
    nextOpening.setUTCHours(21, extraMinutes, 0, 0);
    
    if (utcDay === 0) {
        // Nếu hôm nay là Chủ nhật
        if (now.getUTCHours() < 21 || (now.getUTCHours() === 21 && now.getUTCMinutes() < extraMinutes)) {
            // Nếu chưa đến giờ mở cửa, dùng thời điểm hôm nay
            return nextOpening;
        } else {
            // Nếu đã qua giờ mở cửa, chuyển đến Chủ nhật tuần sau
            nextOpening.setUTCDate(nextOpening.getUTCDate() + 7);
            return nextOpening;
        }
    } else {
        // Nếu không phải Chủ nhật, tìm Chủ nhật tiếp theo
        const daysUntilSunday = 7 - utcDay;
        nextOpening.setUTCDate(nextOpening.getUTCDate() + daysUntilSunday);
        return nextOpening;
    }
}

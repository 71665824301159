import { Grid, Box, Tooltip, IconButton, Typography } from "@mui/material";
import { ItemRowTable } from "./ItemRowTable";
import { InfoOutlined } from "@mui/icons-material";
import ItemColumnValue from "./ItemColumnValue";
import { convertToK, formatCurrency } from "../../../ultils/ultils";

const SwingMode = ({ currentBalance }) => {
  return (
    <div
      style={{ marginTop: "20px", display: "flex", textAlign: "center", flexDirection:"row", width:"100%", overflow:"auto", }}
    >
      <div style={{ minWidth: 1000, display: "flex", textAlign: "center", flexDirection:"row",width:"100%", }}>
      <div className="step-col">
        <Box sx={{ height: "120px" }}></Box>
        <ItemRowTable           
          content="Trading cycle"
          showDescription
          indexIconText={0}
          fontSize={16}
          extraClass="trading-cycle row-first"
        />
        <ItemRowTable
          content="Maximum loss"
          showDescription
          classStep="step-item"
          fontSize={16}
        />
        <ItemRowTable
          content="Target profit"
          showDescription
          indexIconText={2}
        />
        <ItemRowTable
          content="Refund"
          showDescription
          classStep
        />
        <ItemRowTable
          content="Leverage"
          showDescription
          fontSize={16}
        />
        <ItemRowTable
          content="Overnight position"
          showDescription
          fontSize={16}
          classStep
        />
        <ItemRowTable content="RULE" showDescription fontSize={16} />
        <ItemRowTable
          content="Drawdown"
          showDescription
          fontSize={16}
          classStep
        />
        <ItemRowTable content="BONUS for user step 1" showDescription fontSize={16} />
        <ItemRowTable
          content={
            <div style={{display:"flex", flexDirection : "column", alignItems:"start"}}>
              <span> Trade Profit Limitation</span>
              <span>No single trade can account for more than 40%</span>
            </div>
          }
          showDescription
          fontSize={16}
          classStep
        />
        <ItemRowTable content="Fee volume trading" showDescription fontSize={16} />
        <ItemRowTable
          content="Fee market trading"
          showDescription
          fontSize={16}
          classStep
        />
        <ItemRowTable
          content="Min volume trading"
          showDescription
          fontSize={16}
        />
      </div>
      
      <div className="step-col">
          <Box className="step-header">Step 1</Box>
          <ItemColumnValue
            content="Unlimited trading days"
            isSub
            textColor="white"
              extraClass="trading-cycle row-first"
          />
          <ItemColumnValue
            content={`$${formatCurrency(currentBalance?.max_hole_r1)}`}
            textColor="blue"
          />
          <ItemColumnValue
            content={`$` + `${formatCurrency(currentBalance?.profit_target_r1)}`}
            isSub
            textColor="blue"
          />
        
          <ItemColumnValue
            content={`$${currentBalance?.sale_price}`}
            textColor="blue"
            extraClass="feeFunding"
            beforeSalePrice= {currentBalance?.fee_amt}
            discount = {currentBalance?.sale}
            fontSize={ currentBalance?.sale_price  === 1 ? 25 : 18}
          />
          <ItemColumnValue
            content={currentBalance?.leverage_step1}
            isSub
            textColor="dark-gray"
          />
          <ItemColumnValue
            content="Accepted"
            textColor="white"
          />
          <ItemColumnValue
            content="No hidden rule"
            isSub
            textColor="white"
          />
          <ItemColumnValue
            content="Static drawdown"
            textColor="white"
          />
          <ItemColumnValue
            content="(Coming soon)"
            textColor="dark-gray"
            isSub
          />
          <ItemColumnValue
            content="Request"
            textColor="dark-gray"
          />
          <ItemColumnValue
            content={currentBalance?.fee_volume_trading1}
            textColor="white"
            isSub
          />
          <ItemColumnValue
            content={currentBalance?.fee_market_trading1}
            textColor="white"
          />
          <ItemColumnValue
            content={ currentBalance?.min_volume_trading_round1 ? `${convertToK(currentBalance?.min_volume_trading_round1)}` : "Not required"}
            textColor="white"
            isSub
          />
        </div>
        <div className="step-col">
          <Box className="step-header">Step 2</Box>
          <ItemColumnValue
            content="Unlimited trading days"
            isSub
            textColor="white"
            extraClass="trading-cycle row-first"
          />
          <ItemColumnValue
            content={`$${formatCurrency(currentBalance?.max_hole_r2)}`}
            textColor="blue"
          />
          <ItemColumnValue
            content={`$` + `${formatCurrency(currentBalance?.profit_target_r2)}`}
            isSub
            textColor="blue"
          />
        
          <ItemColumnValue
            content={`Free`}
            textColor="white"
            extraClass="feeFunding"
          />
          <ItemColumnValue
            content={currentBalance?.leverage_step2}
            isSub
            textColor="dark-gray"
          />
          <ItemColumnValue
            content="Accepted"
            textColor="white"
          />
          <ItemColumnValue
            content="No hidden rule"
            isSub
            textColor="white"
          />
          <ItemColumnValue
            content="Static drawdown"
            textColor="white"
          />
          <ItemColumnValue
            content="(Coming soon)"
            textColor="dark-gray"
            isSub
          />
          <ItemColumnValue
            content="Request"
            textColor="dark-gray"
          />
          <ItemColumnValue
            content={currentBalance?.fee_volume_trading2}
            textColor="white"
            isSub
          />
          <ItemColumnValue
            content={currentBalance?.fee_market_trading2}
            textColor="white"
          />
          <ItemColumnValue
            content={ currentBalance?.min_volume_trading_round2 ? `${convertToK(currentBalance?.min_volume_trading_round2)}` : "Not required"}
            textColor="white"
            isSub
          />
        </div>
        <div className="step-col">
          <Box className="step-header">Step 3</Box>
          <ItemColumnValue
            content="After 14 days from when the LIVE user account is granted, you only need to achieve a minimum of $100. The profit will be paid out plus the refund of the examination fee"
            isSub
            fontSize={16}
            textColor="white"
            extraClass="trading-cycle row-first"
          />
          <ItemColumnValue
            content={`$${formatCurrency(currentBalance?.max_hole_r3)}`}
            textColor="blue"
          />
          <ItemColumnValue
            content={`10% for up next level`}
            isSub
            textColor="blue"
          />
        
          <ItemColumnValue
            content={currentBalance?.bonus_text}
            textColor="white"
            extraClass="feeFunding"
            info="Bonus will be added on your first withdraw"
          />
          <ItemColumnValue
            content={currentBalance?.leverage_step3}
            isSub
            textColor="dark-gray"
          />
          <ItemColumnValue
            content="Accepted"
            textColor="white"
          />
          <ItemColumnValue
            content="No hidden rule"
            isSub
            textColor="white"
          />
          <ItemColumnValue
            content="Static drawdown"
            textColor="white"
          />
          <ItemColumnValue
            content="80%-90%"
            textColor="white"
            isSub
          />
          <ItemColumnValue
            content="Request"
            textColor="dark-gray"
          />
          <ItemColumnValue
            content={currentBalance?.fee_volume_trading3}
            textColor="white"
            isSub
          />
          <ItemColumnValue
            content={currentBalance?.fee_market_trading3}
            textColor="white"
          />
          <ItemColumnValue
            content={ currentBalance?.min_volume_trading_round3 ? `${convertToK(currentBalance?.min_volume_trading_round3)}` : 'Not required'}
            textColor="white"
            isSub
          />
        </div>
      </div>
    
    </div>
  );
};
export default SwingMode;

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Grid2,
  CircularProgress,
  Pagination,
} from "@mui/material";
import api, { getData } from "../../instance_api";
import { useCallback } from "react";

const TradingOverviewHistory = ({ accountId }) => {
  const [timeLeft, setTimeLeft] = useState(5 * 60); // 5 minutes = 300 seconds
  const [trading_overview, setTradingOverview] = useState({});
  const [trade_tx_history, setTradeTxHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  // Pagination state
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total: 0,
  });

  // Get trading overview data
  useEffect(() => {
    GetTradingOverview();
    GetTradingHistory();
  }, []);

  const GetTradingOverview = async () => {
    try {
      const responseHistory = await api.get(
        "/api/account/get_v1_account_trading_details/" + accountId
      );
      setTradingOverview(responseHistory.data);
    } catch (error) {
      console.error("Error fetching trading overview:", error);
    }
  };

  const GetTradingHistory = useCallback(
    async (page = 1) => {
      setLoading(true);
      try {
        const params = {
          account_id: accountId,
          page: page,
          limit: pagination.limit,
        };
        const response = await getData(
          "/api/trading_tx/admin/get_v1_all_trading_tx_history_by_account_id",
          params
        );
        if (response && response.data) {
          setTradeTxHistory(response.data || []);
          setPagination(response.pagination);
        }
      } catch (error) {
        console.error("Error fetching trading history:", error);
      } finally {
        setLoading(false);
      }
    },
    [pagination, accountId]
  );

  const handlePageChange = (event, value) => {
    GetTradingHistory(value);
  };

  // Countdown timer for auto-refresh
  useEffect(() => {
    const countdown = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(countdown);
          // Refresh data instead of reloading the page
          if (accountId) {
            GetTradingOverview(accountId);
            GetTradingHistory(pagination.page);
          }
          return 5 * 60; // Reset to 5 minutes
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, [accountId]);

  // Format time from seconds to MM:SS
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  // Format transaction type
  const formatTransactionType = (type) => {
    if (type === 1) return "Long";
    if (type === 0) return "Short";
    return type;
  };

  // Format trading type
  const formatTradingType = (type) => {
    if (type === 0) return "Market";
    if (type === 1) return "Limit";
    return type;
  };

  // Format date
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  return (
    <>
      <Box sx={{ width: "100%", marginTop: "20px" }}>
        <Typography variant="h5" gutterBottom>
          Trading Overview
        </Typography>
      </Box>
      <Box sx={{ width: "100%" }}>Refreshing in {formatTime(timeLeft)}</Box>
      <Box sx={{ width: "100%", marginTop: "10px" }}>
        <Grid2 container spacing={3}>
          <Grid2 item size={{ xs: 12, md: 6, lg: 6 }}>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%", fontSize: 18 }}>Balance&nbsp;</div>
              <div style={{ width: "50%", fontWeight: "bold", fontSize: 18 }}>
                ${trading_overview?.balance}
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ width: "50%", fontSize: 18 }}>Trades&nbsp;</div>
              <div style={{ width: "50%", fontWeight: "bold", fontSize: 18 }}>
                {trading_overview?.total_trade}
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%", fontSize: 18 }}>Best Trade</div>
              <div style={{ width: "50%", fontWeight: "bold", fontSize: 18 }}>
                ${trading_overview?.best_trade?.pnl_amt}
              </div>
            </div>
          </Grid2>
          <Grid2 item size={{ xs: 12, md: 6, lg: 6 }}>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%", fontSize: 18 }}>Win Rate</div>
              <div style={{ width: "50%", fontWeight: "bold", fontSize: 18 }}>
                {trading_overview?.win_rate}%
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%", fontSize: 18 }}>Loss Rate</div>
              <div style={{ width: "50%", fontWeight: "bold", fontSize: 18 }}>
                {trading_overview?.loss_rate}%
              </div>
            </div>
            
          </Grid2>
        </Grid2>
      </Box>
      <Box sx={{ width: "100%", marginTop: "20px" }}>
        <Typography variant="h5" gutterBottom>
          Trading history
        </Typography>
      </Box>
      <Box sx={{ width: "100%", marginTop: "20px" }}>
        <TableContainer
          sx={{ padding: "0px", maxHeight: 500, overflow: "auto" }}
          component={Paper}
        >
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Order</TableCell>
                <TableCell>Symbol</TableCell>
                <TableCell>Position</TableCell>
                <TableCell>Type</TableCell>
                <TableCell align="right">Net Value</TableCell>
                <TableCell align="right">Volume</TableCell>
                <TableCell align="right">Entry Price</TableCell>
                <TableCell align="right">Mark Price</TableCell>
                <TableCell align="right">PnL</TableCell>
                <TableCell align="right">Exit Price</TableCell>
                <TableCell align="right">Tx Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={11} align="center">
                    <CircularProgress size={24} />
                  </TableCell>
                </TableRow>
              ) : trade_tx_history.length > 0 ? (
                trade_tx_history.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{row.ord_no || ""}</TableCell>
                    <TableCell>{row.trading_pair || ""}</TableCell>
                    <TableCell>
                      {formatTransactionType(row.transaction_type)}
                    </TableCell>
                    <TableCell>{formatTradingType(row.trading_type)}</TableCell>
                    <TableCell align="right">{row.pay_amt || ""}</TableCell>
                    <TableCell align="right">{row.volumn_amt || ""}</TableCell>
                    <TableCell align="right">{row.entry_price || ""}</TableCell>
                    <TableCell align="right">{row.mark_price || ""}</TableCell>
                    <TableCell align="right">
                      {row.pnl_amt !== undefined ? (
                        <span
                          style={{
                            color: Number(row.pnl_amt) < 0 ? "red" : "green",
                          }}
                        >
                          {row.pnl_amt}
                        </span>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell align="right">{row.exit_price || ""}</TableCell>
                    <TableCell align="right">{formatDate(row.tx_dt)}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={11} align="center">
                    No data
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            marginTop: "10px",
          }}
        >
          <Pagination
            count={Math.ceil(pagination.total / pagination.limit) || 1}
            page={pagination.page}
            onChange={handlePageChange}
            color="primary"
            showFirstButton
            showLastButton
          />
        </div>
      </Box>
    </>
  );
};

export default TradingOverviewHistory;

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Grid2, Box } from "@mui/material"
import React from "react"
export const ItemRowTable = ({
    content = '',
    showDescription = false,
    indexIconText ,
    descriptionRef,
    descriptionIconText = 0,
    sxContainer = {},
    heightStepFirst = false,
    onClick = () => {},
    showInfoIcon = false,
    classStep,
    fontSize ,
    extraClass
}) => {
    return (
        <Grid2 container spacing={0} className={`${classStep ? 'step-item' : "step-item-sub"} text-dark-gray ${extraClass}`} sx={sxContainer}>
        <Grid2
          display="flex"
          justifyContent="left"
          alignItems="left"
          size="auto"
        >
          <Box className="content-table" sx={{fontSize : fontSize || null }}>{content}</Box>
        </Grid2>
        <Grid2
          display="flex"
          justifyContent="center"
          alignItems="center"
        ></Grid2>
        
      </Grid2>
    )
}
/* eslint-disable react-hooks/exhaustive-deps */
import './index.css';
import '../../App.css';
import { Box, Slider, Switch, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Paper, Tab, Tabs, Button, MenuItem, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid2";
import * as React from 'react';
import { useState, useEffect, useRef, useMemo } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { BrowserProvider, ethers } from 'ethers';
import Popup from "./popup"; // Import the Popup component
import ChangeSltp from "./changeSlTp"; // Import the Popup component
import PropTypes, { element, number } from 'prop-types';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector, useDispatch } from 'react-redux';
import api from '../../instance_api'
import api_no_auth from '../../instance_api_no_authentication'
import { useSnackbar } from '../SnackbarProvider';
import PopupLogin from "../login";
import TradingViewChart from "../TradingViewChart";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import api_key from '../../instance_api_key'
import { usePriceFX } from './hooks/usePriceFx';
import useRefundEth from "../../hooks/useRefundEth";
import { usePriceCrypto } from './hooks/usePriceCrypto';
import { calculateFundingRate, formatPriceDecimal, isMarketClosed, isTodayInDays, isValidDecimal, calculateHoursPassedBorrow} from '../../ultils/ultils';

import PopupCloseOrder from './popupCloseOrder';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import {defaultDataTradingPair, SelectWithSearch } from '../../common/common-select';
import { find, uniq } from 'lodash';
import { useDailyMarketCloseTrigger } from './hooks/useDailyMarketCloseTrigger';
import {useMidnightTrigger} from "./hooks/useMidnightTrigger";
import {templateEmailConfig} from "../../constants/template_email_config";
import { RefundType } from '../../constants/refund_type';
import { lockTradingUser, updateSmartDrawDown } from './hooks/apiTrading';
// import { useAutoLogout } from './hooks/useAutoLogout';

let isSendEmail10Percent = false;
let isSendEmail5Percent = false;
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



const LauchApp = ({ reload, b_reload, b_balance, b_equity }) => {
    const [session_account, setsession_account] = useState('');

    const getSessionAccount = () => {
        let account = ''
        const accountSession = sessionStorage.getItem('account');
        const accountLocal = localStorage.getItem("account");
        if (accountSession) {
            account = accountSession
        }else if (accountLocal) {
            account = accountLocal;
            sessionStorage.setItem('account', accountLocal)
        }
        setsession_account(account);
        return account;
    }
    // const { updateLastActivity } = useAutoLogout()
    const globalVariable = useSelector((state) => state.globalVariable.value);
    const dispatch = useDispatch();

    const customStyles = {
        control: (base) => ({
            ...base,
            color: 'white',          // White text
            backgroundColor: '#232323',
            border: '#333',
        }),
        singleValue: (base) => ({
            ...base,
            color: 'white',
        }),
        menu: (base) => ({
            ...base,
            backgroundColor: '#333',  // Dark background for dropdown
            scrollbarColor: '#a0a0a0 #232323',
            scrollbarWidth: 'thin',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#666' : '#333',
            color: 'white',
            padding: 10,
        }),
    };

    const showSnackbar = useSnackbar();

    const [activeTrading, setActiveTrading] = useState('Long');
    const showTrading = (step, active, text) => () => {
        setActiveTrading(text);
        setListOpStopLoss(dataOptionsStopLoss?.filter(x => x.type === text))
        setListOpTakeProfit(dataOptionsTakeProfit?.filter(x => x.type === text))
    };

    const [opTrading, setOpTrading] = useState('Market');
    const changeOpTrading = (text) => () => {
        setOpTrading(text);
        if (text == 'Market') {
            setListOpStopLoss(dataOptionsStopLoss?.filter(x => x.type === activeTrading));
            setListOpTakeProfit(dataOptionsTakeProfit?.filter(x => x.type === activeTrading));
            setOpStopLossPrice(getPopupPrice());
            setOpTakeProfitPrice(getPopupPrice());
            setMarkPrice(0);
        } else {
            setListOpStopLoss(dataOptionsStopLoss?.filter(x => x.type === activeTrading));
            setListOpTakeProfit(dataOptionsTakeProfit?.filter(x => x.type === activeTrading));
            setOpStopLossPrice(getPopupPrice());
            setOpTakeProfitPrice(getPopupPrice());
        }
    };
    const dataOptionsStopLoss = [
        { id: 2, value: 'None', type: 'Long' },
        { id: 3, value: '-10%', type: 'Long' },
        { id: 4, value: '-25%', type: 'Long' },
        { id: 5, value: '-50%', type: 'Long' },
        { id: 6, value: '-75%', type: 'Long' },
        { id: 7, value: 'None', type: 'Short' },
        { id: 8, value: '10%', type: 'Short' },
        { id: 9, value: '25%', type: 'Short' },
        { id: 10, value: '50%', type: 'Short' },
        { id: 11, value: '75%', type: 'Short' },
    ];
    const [listOpStopLoss, setListOpStopLoss] = useState(dataOptionsStopLoss?.filter(x => x.type === "Long"));
    const [opStopLoss, setOpStopLoss] = useState(dataOptionsStopLoss[0].value);
    const [opStopLossPrice, setOpStopLossPrice] = useState(0);
    const changeOpStopLoss = (text) => () => {
        let price = formatPriceDecimal(Number(getPopupPrice()), trading?.value)
        if (opTrading === "Limit") {
            price = Number(markPrice) || 0
        }
        setOpStopLoss(text);
        switch (text) {
            case 'None': { setOpStopLossPrice(price); } break;
            case '-10%': { setOpStopLossPrice(formatPriceDecimal(price - Number(price * 10 / 100))) } break;
            case '-25%': { setOpStopLossPrice(formatPriceDecimal(price - Number(price * 25 / 100))) } break;
            case '-50%': { setOpStopLossPrice(formatPriceDecimal(price - Number(price * 50 / 100))) } break;
            case '-75%': { setOpStopLossPrice(formatPriceDecimal(price - Number(price * 75 / 100))) } break;
            case '10%': { setOpStopLossPrice(formatPriceDecimal(price + Number(price * 10 / 100))) } break;
            case '25%': { setOpStopLossPrice(formatPriceDecimal(price + Number(price * 25 / 100))) } break;
            case '50%': { setOpStopLossPrice(formatPriceDecimal(price + Number(price * 50 / 100))) } break;
            case '75%': { setOpStopLossPrice(formatPriceDecimal(price + Number(price * 75 / 100))) } break;
        }
    };

    const dataOptionsTakeProfit = [
        { id: 2, value: 'None', type: 'Long' },
        { id: 3, value: '10%', type: 'Long' },
        { id: 4, value: '25%', type: 'Long' },
        { id: 5, value: '50%', type: 'Long' },
        { id: 6, value: '75%', type: 'Long' },
        { id: 7, value: 'None', type: 'Short' },
        { id: 8, value: '-10%', type: 'Short' },
        { id: 9, value: '-25%', type: 'Short' },
        { id: 10, value: '-50%', type: 'Short' },
        { id: 11, value: '-75%', type: 'Short' },
    ];
    const [listOpTakeProfit, setListOpTakeProfit] = useState(dataOptionsTakeProfit?.filter(x => x.type === "Long"));
    const [opTakeProfit, setOpTakeProfit] = useState(dataOptionsTakeProfit[0].value);
    const [opTakeProfitPrice, setOpTakeProfitPrice] = useState(0);
    const changeOpTakeProfit = (text) => () => {
        let price = formatPriceDecimal(Number(getPopupPrice()), trading?.value)
        if (opTrading === "Limit") {
            price = Number(markPrice) || 0
        }
        setOpTakeProfit(text);
        switch (text) {
            case 'None': { setOpTakeProfitPrice(price); } break;
            case '10%': { setOpTakeProfitPrice(formatPriceDecimal((price + Number(price * 10 / 100)))); } break;
            case '25%': { setOpTakeProfitPrice(formatPriceDecimal((price + Number(price * 25 / 100)))); } break;
            case '50%': { setOpTakeProfitPrice(formatPriceDecimal((price + Number(price * 50 / 100)))); } break;
            case '75%': { setOpTakeProfitPrice(formatPriceDecimal((price + Number(price * 75 / 100)))); } break;
            case '-10%': { setOpTakeProfitPrice(formatPriceDecimal((price - Number(price * 10 / 100)))); } break;
            case '-25%': { setOpTakeProfitPrice(formatPriceDecimal((price - Number(price * 25 / 100)))); } break;
            case '-50%': { setOpTakeProfitPrice(formatPriceDecimal((price - Number(price * 50 / 100)))); } break;
            case '-75%': { setOpTakeProfitPrice(formatPriceDecimal((price - Number(price * 75 / 100)))); } break;
        }
       
    };

    //tranding

    const [payAmount, setPayAmount] = useState("0");
    const [trendPayAmount, setTrendPayAmount] = useState("0.00");
    const changePayAmount = (e) => {
        // if (isNaN(e.target.value)) {
        //     setValue(1);
        //     return;
        // }
        // if (e.target.value > account?.current_balance) {
        //     setValue(account?.current_balance);
        //     return;
        // }
        // if (e.target.value < 0) {
        //     setValue(1);
        //     return;
        // }

        if (isNaN(e.target.value)) {
            return;
        }
        if (e.target.value > account?.current_balance) {
            setPayAmount(account?.current_balance);
            return;
        }
        if (e.target.value < 0) {
            setPayAmount(1);
            return;
        }
        
        let numericValue = Number(e.target.value);
        setPayAmount(numericValue);

    };

    const [markPrice, setMarkPrice] = useState("0");


    // const handleMarkChange = (e) => {
    //     let value = e.target.value;

    //     if (isNaN(value) || value === '') {
    //         setMarkPrice('');
    //         return;
    //     }

    //     value = Math.max(0, Number(value));
    //     setMarkPrice(value);
    // };

    const handleMarkChange = (e) => {
        let value = e.target.value;

        if (value === '' || isNaN(value)) {
            setMarkPrice('');
            return;
        }

        const numericValue = Math.max(0, parseFloat(value));
        setMarkPrice(numericValue);
    };

    const handleBlurMark = () => {
        if (markPrice === '' || markPrice === null) {
            setMarkPrice(0);
        }
    };

    const [priceAmount, setPriceAmount] = useState(0);



    const formatOptionLabel = ({ label, imageUrl, status, isActive }) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className={status === 1 ? '' : 'text-dark-gray'}>
            {/* <img src={imageUrl} alt={label} style={{ width: 25, marginRight: 10 }} /> */}
            <span>{status === 1 ? label : `${label} (coming soon)`}</span>
            <Box className="favorite-trading-active pointer">
                {isActive === 1 && (
                    <StarIcon sx={{ fontSize: '30px' }} />
                )}
            </Box>
        </div>
    );

    const [checked, setChecked] = React.useState(false);
    const handleChangeCheckbox = (event) => {
        setChecked(event.target.checked);
    };



    const [value, setValue] = useState(0);

    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleLeverageChange = (event) => {
        const inputValue = event.target.value;

        if (inputValue === "") {
            setValue("");
            return;
        }

        if (isNaN(inputValue)) {
            return;
        }

        let numericValue = Number(inputValue);
        //const round_server = localStorage.getItem('round_server');
        if (mode === 'INSTANT') {
            const type =  getTokenType();
            if (type === 'FX') {
                numericValue = Math.min(Math.max(numericValue, 0), 50);
            }else {
                numericValue = Math.min(Math.max(numericValue, 0), 3);
            }
         }else {
            if (sessionStorage.getItem("token") === "BTC") {
                numericValue = Math.min(Math.max(numericValue, 0), 50);
            } else {
                numericValue = Math.min(Math.max(numericValue, 0), 25);
            }
        }

        setValue(numericValue);

    };


    const [isOpen, setIsOpen] = useState(false);
    const togglePopup = (payload) => {
        if (isMarketClosed(trading?.value) && trading?.type == "FX") {
            showSnackbar('Market closed', 'warning');
            return;
        }
        if (account?.is_total_loss_left_violate) {
            showSnackbar('Total loss left exceeds 10% balance', 'warning');
            return;
        }
        if (opTrading == 'Limit') {
            if (markPrice == 0) {
                showSnackbar('Please enter the mark price', 'warning');
                return;
            }

            if (markPrice < getPopupPrice() && activeTrading == 'Short') {
                showSnackbar('Please select mark price greater than market price', 'warning');
                return;
            }

            if (markPrice > getPopupPrice() && activeTrading == 'Long') {
                showSnackbar('Please select mark price lower than market price', 'warning');
                return;
            }

            if (activeTrading == 'Long' && checked && opStopLossPrice && opStopLossPrice > markPrice) {
                showSnackbar('Please select SL price lower than limit price', 'warning');
                return;
            }

            if (activeTrading == 'Short' && checked && opStopLossPrice && opStopLossPrice < markPrice) {
                showSnackbar('Please select SL price greater than limit price', 'warning');
                return;
            }

            if (activeTrading == 'Long' && checked && opTakeProfitPrice && opTakeProfitPrice < markPrice) {
                showSnackbar('Please select SL price greater than limit price', 'warning');
                return;
            }
            if (activeTrading == 'Short' && checked && opTakeProfitPrice && opTakeProfitPrice > markPrice) {
                showSnackbar('Please select SL price lower than limit price', 'warning');
                return;
            }
        }else {
            if (activeTrading == 'Long' && checked && opStopLossPrice && opStopLossPrice > getPopupPrice()) {
                showSnackbar('Please select SL price lower than market price', 'warning');
                return;
            }
            if (activeTrading == 'Short' && checked && opStopLossPrice && opStopLossPrice < getPopupPrice()) {
                showSnackbar('Please select SL price greater than market price', 'warning');
                return;
            }
            if (activeTrading == 'Long' && checked && opTakeProfitPrice && opTakeProfitPrice < getPopupPrice()) {
                showSnackbar('Please select TP price greater than market price', 'warning');
                return;
            }
            if (activeTrading == 'Short' && checked && opTakeProfitPrice && opTakeProfitPrice > getPopupPrice()) {
                showSnackbar('Please select TP price lower than market price', 'warning');
                return;
            }
        }
        setIsOpen(!isOpen);
        if (payload?.reloadTx) {
            const session_account_reload = sessionStorage.getItem('account');
            GetTradingTxByAccountId(session_account_reload);
        }
    };

    // const [price, setPrice] = useState(0);
    // const [btcPrice, setBtcPrice] = useState(0);
    // const [bnbPrice, setBnbPrice] = useState(0);
    // const [pepePrice, setPepePrice] = useState(0);
    // const [xrpPrice, setXrpPrice] = useState(0);
    // const [solPrice, setSolPrice] = useState(0);
    // const [dogePrice, setDogePrice] = useState(0);


    const [trade_tx, setTradeTx] = useState([]); //Tab Position
    const [trade_tx_order, setTradeTxOrder] = useState([]); //Tab Order
    const [trade_tx_history, setTradeTxHistory] = useState([]); //Tab History

    const [mode, setMode] = useState("SWING");

    let account_data = {}
    const [account, setAccount] = useState(null);
    useEffect(() => {
        const session_account_reload = sessionStorage.getItem('account');
        if(session_account_reload)
        {
            GetTradingTxByAccountId(session_account_reload);
        }
        sessionStorage.setItem("token", 'BTC'); 
    }, []);

    const [accountId, setAccountId] = useState(null);
    useEffect(() => {
        const session_account_reload = sessionStorage.getItem('account');
        if (session_account_reload) {
            setAccountId(session_account_reload);
            GetAccountById(session_account_reload);
            //GetTradingTxByAccountId(session_account_reload);
        }
    }, [isOpen]);
    
    // call api reload account mỗi 00:00 utc
    useMidnightTrigger(() => {
        const session_account_reload = sessionStorage.getItem("account");
        if (session_account_reload) {
            GetAccountById(session_account_reload);
            GetTradingPosition(session_account_reload)
        }
    });

    const GetAccountById = async (session_account_reload) => {
        const response = await api.get('/api/account/get_v1_account_by_id/' + session_account_reload);
        // setEquity(response.data.current_balance.toFixed(2));
        // b_equity(response.data.current_balance.toFixed(2));
        account_data = response.data;
        setAccount(response.data);
        setMode(response.data.mode)
        b_balance(response.data?.current_balance);
    }

    const GetTradingTxByAccountId = async (session_account_reload) => {
        GetTradingPosition(session_account_reload);
        GetTradingOrder(session_account_reload);
        GetTradingHistory(session_account_reload);
    }

    const GetTradingPosition = async (session_account_reload) => {
        const responsePosition = await api.get('/api/trading_tx/get_v1_trading_tx_by_account_id/' + session_account_reload + '/0');
        setTradeTx(responsePosition.data);
    }

    const GetTradingOrder = async (session_account_reload) => {
        const responseOrder = await api.get('/api/trading_tx/get_v1_trading_tx_by_account_id/' + session_account_reload + '/1');
        setTradeTxOrder(responseOrder.data);
    }

    const GetTradingHistory = async (session_account_reload) => {
        const responseHistory = await api.get('/api/trading_tx/get_v1_trading_tx_by_account_id/' + session_account_reload + '/2');
        setTradeTxHistory(responseHistory.data);
    }

    useDailyMarketCloseTrigger(() => {
        GetTradingPosition(getSessionAccount())
    })

    const marks = [
        {
            value: 1.1,
            label: '1.1x',
        },
        {
            value: 10,
            label: '10x',
        },
        {
            value: 15,
            label: '15x',
        },
        {
            value: 25,
            label: '25x',
        },
        {
            value: 35,
            label: '35x',
        },
        {
            value: 50,
            label: '50x',
        },
        {
            value: 60,
            label: '60x',
        },
        {
            value: 70,
            label: '70x',
        },
        {
            value: 80,
            label: '80x',
        },
        {
            value: 90,
            label: '90x',
        },
        {
            value: 100,
            label: '100x',
        },
    ];

    const [isOn, setIsOn] = useState(false);
    const handleSwitchChange = (event) => {
        setIsOn(event.target.checked);
    };

    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        if (!sessionStorage.getItem('account')) {
            showSnackbar('Please login to account', 'warning');
            return
        }
        setTabValue(newValue);
        changeTabTxData(newValue);
    };

    const changeTabTxData = async (tabIndex) => {
        switch (tabIndex) {
            case 0: {
                GetTradingPosition(getSessionAccount());
            }; break;
            case 1: {
                GetTradingOrder(getSessionAccount());
            }; break;
            case 2: {
                GetTradingHistory(getSessionAccount());
            }; break;
        }
    }

    const getFeeCloseTx = async (item) => {
        try {
            const payload = {
                account_id: account?.id,
                tx_id: item.tx_id
            }
            const response = await api.post("/api/trading_tx/post_v1_trading_transaction_calculate_close_position_fee", payload)
            return response.data.total_fee
        } catch (error) {
            throw error
        }
    }

    const [isProcessingMarket, setIsProcessingMarket] = useState(false);
    const [txClosing, setTxClosing] = useState([]);
    const handleCloseTx = async (item, type) => {
        setIsProcessingMarket(true);
        setTxClosing((prev) => {
            return uniq([...prev, item.id])
        })
        
        const trading_fee_close_positon = await getFeeCloseTx(item);
        if (!window.ethereum) {
            alert('MetaMask is required!');
            return;
        }

        const tempProvider = new BrowserProvider(window.ethereum);
        const network = tempProvider && await tempProvider.getNetwork();
        const chainid = network.chainId.toString()
        // if (network.name != 'base-sepolia' && chainid != "1301") {
        //     showSnackbar('Please switch to Base Sepolia or Unichain Sepolia', 'warning');
        //     return;
        // }

        if (network.name != 'base' && chainid != "8453") {
            showSnackbar('Please switch to Base', 'warning');
            return;
        }

        try {

            // Connect to MetaMask
            const provider = new ethers.BrowserProvider(window.ethereum);
            await provider.send('eth_requestAccounts', []); // Request account access
            const signer1 = await provider.getSigner();
            // Create transaction object
            const tx = {
                to: process.env.REACT_APP_WALLET_ADDRESS,
                value: ethers.parseEther(((trading_fee_close_positon / Number(price)).toFixed(7)).toString()), // Convert ETH to wei
            };

            // Send the transaction
            const transactionResponse = await signer1.sendTransaction(tx);
            // console.log('Transaction sent:', transactionResponse);
            const session_account = sessionStorage.getItem('account');

            // Wait for transaction confirmation
            const receipt = await transactionResponse.wait();
            if (receipt.status == 1) {
                api.post('/api/trading_tx/post_v1_trading_transaction_close_posotion',
                    {
                        tx_id: item.id,
                        trading_type: type,
                        pnl: item.pnl_amt,
                        exit_price: getPopupPrice(item.unit)
                    })
                    .then(response => {
                        api.get(`/api/account/get_v1_account_by_id/${session_account}`)
                            .then(response1 => {
                                account_data = response1.data;
                                setAccount(response1.data);
                                b_balance(response1.data?.current_balance);
                                // b_equity(response1.data?.current_balance);
                            })
                            .catch(error => console.error(error));
                        if (type === 0) {
                            // GetInformationWallet(sessionStorage.getItem('account'));
                            GetTradingPosition(session_account);
                        } else if (type === 1) {
                            GetTradingOrder(session_account);
                        } else {
                            GetTradingHistory(session_account);
                        }
                        showSnackbar('Close position success !', 'success');
                        setIsProcessingMarket(false);
                        setTxClosing((prev) => {
                            return prev.filter(c => c !== item.id)
                        })
                    })
                    .catch(error => {
                        console.error(error);
                        showSnackbar( error, 'error');
                    });
            } else {
                setIsProcessingMarket(false);
                setTxClosing((prev) => {
                    return prev.filter(c => c !== item.id)
                })
                showSnackbar('Error', 'warning');
            }
            // console.log('Transaction confirmed:', receipt);
        } catch (error) {
            setIsProcessingMarket(false);
            setTxClosing((prev) => {
                return prev.filter(c => c !== item.id)
            })
            console.error('Error sending transaction:', error);

            if (error.action == 'estimateGas') {
                showSnackbar('The fee balance is insufficient to execute the order.', 'warning');
                return;
            }
        }
    }

    const handleStoplossInputChange = (event) => {
        let inputValue = event.target.value;
        if (inputValue.startsWith('.') && inputValue.length > 1) {
            inputValue = `0${inputValue}`;
        }
        if (/^0+\d/.test(inputValue)) {
            inputValue = inputValue.replace(/^0+/, '');
        }
        if (isValidDecimal(inputValue) || inputValue === '') {
            setOpStopLossPrice(inputValue);
        }
    };
    const handleTakeProfitInputChange = (event) => {
        let inputValue = event.target.value;
        if (inputValue.startsWith('.') && inputValue.length > 1) {
            inputValue = `0${inputValue}`;
        }
        if (/^0+\d/.test(inputValue)) {
            inputValue = inputValue.replace(/^0+/, '');
        }
        if (isValidDecimal(inputValue) || inputValue === '') {
            const price = opTrading === 'Market' ? getPopupPrice() : markPrice;
            setOpTakeProfitPrice(inputValue);
        }
    };

    const [sltpTradingId, setSltpTradingId] = useState(null);
    const [sltpStoplossValue, setSltpStoplossValue] = useState(null);
    const [sltpTakeProfitValue, setSltpTakeProfitValue] = useState(null);
    const [sltpPopup, setSltpPopup] = useState(false);

    const handleEditPopup = (item) => {
        if (sltpPopup === false) {
            setSltpTradingId(item.id);
            setSltpStoplossValue(item.stop_loss);
            setSltpTakeProfitValue(item.take_profit);
            setSltpPopup(!sltpPopup);
        } else {
            setSltpTradingId(0);
            setSltpStoplossValue(0);
            setSltpTakeProfitValue(0);
            setSltpPopup(!sltpPopup);
            GetTradingPosition(getSessionAccount());
        }
    }

    const [lastUpdated, setLastUpdated] = useState(0); // Thời gian cập nhật cuối cùng

    const getFundingRate = (token) => {
        if (!token) {
            const valueToken = sessionStorage.getItem("token");
            return fundingRateCrypto[`${valueToken}`] ? Number(fundingRateCrypto[`${valueToken}`]).toFixed(5) : 0;
        } else {
            return fundingRateCrypto[`${token}`] ? Number(fundingRateCrypto[`${token}`]).toFixed(5) : 0;
        }
    }


    const GetPnl = (item) => {
     
        // let count_hour = calculateHoursBetweenDates(new Date(), parseDateString(item.tx_dt));
        // if (count_hour == 0) {
        //     count_hour = 1;
        // }
        
        let currentPricce = Number(getPopupPrice(item.unit));
        let feeFunding = (item.volumn_amt * calculateFundingRate(Number(getFundingRate(item.unit) / 100), item.transaction_type) + item.total_fee_funding_rate) ;
        if (isMarketClosed(item.unit) && item.trading_pair_type === "FX") { // nếu là forex thì lấy giá cuối cùng vì k có giá ở on chain
            currentPricce = formatPriceDecimal(item.last_market_price, item.unit)
        }
        if (!currentPricce) {
            return 0
        }
        if (item.is_locked && item.locked_price) {
            currentPricce = Number(item.locked_price);
            feeFunding = item.total_fee_funding_rate;
        }

        if (item.transaction_type == 'Long') {
            if (item.trading_pair_type === "CRYPTO") {
                const pnl_value = (currentPricce - item.entry_price) * (item.pay_amt / item.entry_price) * item.leverage
                - Math.abs(Number(GetBorrowFee(item))) 
                + feeFunding 
                return Number(pnl_value).toFixed(2);
            }else{ //FX
                const pnl_value = (currentPricce - item.entry_price) * (item.pay_amt / item.entry_price) * item.leverage - Math.abs(Number(GetBorrowFee(item))) 
                return Number(pnl_value).toFixed(2);
            }
            
        } else {
            if (item.trading_pair_type === "CRYPTO") {
                const pnl_value = (item.entry_price - currentPricce) * (item.pay_amt / item.entry_price) * item.leverage
                - Math.abs(Number(GetBorrowFee(item))) 
                + feeFunding;
                return Number(pnl_value).toFixed(2);
            }else { // FX
                const pnl_value = (item.entry_price - currentPricce) * (item.pay_amt / item.entry_price) * item.leverage - Math.abs(Number(GetBorrowFee(item)))
                return Number(pnl_value).toFixed(2);
            }
        }
    }


    const GetBorrowFee = (item) => {
        const txTime = item.is_locked && item.locked_time ? item.locked_time : item.tx_dt;
        let count_hour = calculateHoursPassedBorrow(txTime, item.unit, item.trading_pair_type);
        if (count_hour == 0) {
            count_hour = 1;
        }
        let percentFee = (
            item.trading_pair_type === 'FX' || 
            account?.mode === "INSTANT" || // chế độ instant crypto
            account?.server === 1 || // chế độ swing STEP2 crypto
            account?.server === 0 // chế độ swing STEP1 crypto
        ) ? 0.001 : 0.015
        // crypto của instant và vòng live là 0.01
        if(item.trading_pair_type === 'CRYPTO' && (account?.mode === "INSTANT" || account?.server === 2) ){
            percentFee = 0.01
        }
        return  (- item.volumn_amt * (percentFee / 100) * count_hour).toFixed(2);

    }

    const GetFundingRateFee = (item) => {
        if (item.trading_pair_type === "FX") {
            return 0
        }
        const value =  Number((item.volumn_amt * calculateFundingRate(Number(getFundingRate(item.unit) / 100), item.transaction_type) + Number(item.total_fee_funding_rate)))
        return Number(value.toFixed(3))
    }

    function calculateHoursBetweenDates(date1, date2, symbol) {
        // Chuyển đổi các đối tượng Date về mili giây
        // const timeDiff = Math.abs(date2 - date1);

        // // Tính số giờ từ mili giây
        // const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));

        // return hoursDiff;
        let validHours = 0;
        let currentTime = new Date(date1);

        while (currentTime <= date2) {
            if (!isMarketClosed(symbol)) {
                validHours++;
            }
            currentTime.setHours(currentTime.getHours() + 1); // Tiến đến giờ tiếp theo
        }

        return validHours === 0 ? 1 : validHours;
    }

    function parseDateString(dateString) {
        // Tách ngày, tháng, năm, giờ, phút, giây từ chuỗi định dạng dd/MM/yyyy HH:mm:ss
        const [datePart, timePart] = dateString.split(' ');
        const [day, month, year] = datePart.split('/').map(Number);
        const [hours, minutes, seconds] = timePart.split(':').map(Number);

        // Tạo đối tượng Date, lưu ý tháng - 1 vì tháng bắt đầu từ 0 trong Date
        return new Date(year, month - 1, day, hours, minutes, seconds);
    }

    const [volume, setVolume] = useState(0);
    const [longOpenInterest, setLongOpenInterest] = useState(0);
    const [shortOpenInterest, setShortOpenInterest] = useState(0);
    const [longPercentOpenInterest, setLongPercentOpenInterest] = useState(0);
    const [shortPercentOpenInterest, setShortPercentOpenInterest] = useState(0);

    const GetAccLauchappInfo = async () => {
        const response = await api_no_auth.get('/api/account/get_v1_account_launchapp_info');
        if (response.data) {
            setVolume(response.data?.volume_24h);
            setLongOpenInterest(response.data?.long_open_interest);
            setShortOpenInterest(response.data?.short_open_interest);
            setLongPercentOpenInterest(response.data?.long_percent_open_interest);
            setShortPercentOpenInterest(response.data?.short_percent_open_interest);
        }
    }
    useEffect(() => {
        // Gọi hàm fetch lần đầu và sau đó cứ mỗi 1 giờ gọi lại
        GetAccLauchappInfo();
        const interval2 = setInterval(GetAccLauchappInfo, 360000);

        // Hủy interval khi component bị hủy
        return () => clearInterval(interval2);
    }, []);
    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(amount).replace('$', '$').replace(/,/g, '.'); // Replace comma with dot
    };

    const [equity, setEquity] = useState(0);
    const [totalLossLeft, setTotalLossLeft] = useState(0);
    const [dailyLossLeft, setDailyLossLeft] = useState(0);

    

    const MatchingOrder = async (item, type, price) => {
        if (account?.is_total_loss_left_violate) {
            return;
        }
        const response = await api.post('/api/trading_tx/post_v1_trading_transaction_matching_order', {
            trading_tx_order_id: item.id
        });
        if (response && response.data && response.data.valid == 1) {
            if (type === "Long") {
                showSnackbar(`Your LONG order was filled at the price ${price}`, 'success');
            }else {
                showSnackbar(`Your SHORT order was filled at the price ${price}`, 'success');
            }
            GetTradingOrder(getSessionAccount());
            GetAccountById(getSessionAccount());
        } else {
            showSnackbar(response.data.messages, 'error');
        }
    }

    const MatchingSltp = async (item, sltp) => {
        try {
            const response = await api.post('/api/trading_tx/post_v1_trading_transaction_matching_sltp', {
                tx_id: item.tx_id,
                pnl: GetPnl(item),
                sl_tp: sltp,
                exit_price: getPopupPrice(item.unit),
                closed_by : "ONLINE"
            });
            if (response && response.data && response.data.valid == 1) {
                if (!sltp) {
                    showSnackbar('Your SL command was matched', 'success');
                }else {
                    showSnackbar('Your TP command was matched', 'success');
                }
            } 
        } catch (error) {
            
        }finally {
            GetTradingPosition(getSessionAccount());
            GetAccountById(getSessionAccount());
        }
    }

    const [isOpenLogin, setIsOpenLogin] = useState(false);
    const togglePopupLogin = () => {
        setIsOpenLogin(!isOpenLogin);
    };

    const [changeChartTradeMobile, setChangeChartTradeMobile] = useState('Chart');
    const [isVisibleChart, setIsVisibleChart] = useState(true);
    const [isVisibleTrade, setIsVisibleTrade] = useState(true);
    const [isTradeShow, setIsTradeShow] = useState(true);
    const showChangeChartTradeMobile = (text) => () => {
        setChangeChartTradeMobile(text);
        if (text === "Chart") {
            setIsVisibleChart(true);
            setIsVisibleTrade(false);
            setIsTradeShow(true);
        }
        else {
            setIsVisibleChart(false);
            setIsVisibleTrade(true);
            setIsTradeShow(false);
        }
    };

    const [selectedToken, setSelectedToken] = useState("BTC");
    const [selectedTradingActive, setSelectedTradingActive] = useState(0);
    const [trading, setTrading] = useState(null);
    const [options, setOptions] = useState(defaultDataTradingPair);
    const getTradingPair = async (accountId) => {
        try {
            // Replace this with your actual API call
            api_no_auth.get(`/api/TradingPair/get_trading_pair/${accountId}`)
                .then(response => {
                    if (response && response.data.length > 0) {
                        // Map data to the format required by react-select
                        const formattedOptions = response.data;
                        setOptions(formattedOptions);

                        // Set the default value (e.g., first option or specific logic)
                        // setSelectedToken(formattedOptions[0]?.value)
                        setSelectedTradingActive(formattedOptions[0]?.isActive)
                        setTrading(formattedOptions[0])
                    }
                })
                .catch(error => console.error(error));


        } catch (error) {
            console.error("Error fetching options:", error);
        }
    };


    useEffect(() => {
        const accountId = sessionStorage.getItem('account');
        getTradingPair(accountId);
    }, []);

    const { 
        priceCrypto, 
        price, 
        onSelectCryptoToken ,
        fundingRateCrypto,
        loadingCrypto ,
        setLoadingCrypto
    } = usePriceCrypto({listTradingPair : options, tradeTx: trade_tx, tradeOrder: trade_tx_order});

    useEffect(() => {
        if (getSessionAccount() != null && (trade_tx_order && trade_tx_order.length > 0)) {
            trade_tx_order.forEach(element => {
                options.forEach(token => {
                    // if ((element.transaction_type == 'Long' && element.entry_price >= Number(getPopupPrice(token.value.toUpperCase())) && element.unit == token.value.toUpperCase())
                    //     || (element.transaction_type == 'Short' && element.entry_price <= Number(getPopupPrice(token.value.toUpperCase())) && element.unit == token.value.toUpperCase())) {
                    //     MatchingOrder(element);
                    // }
                    if ((element.transaction_type == 'Long' && element.entry_price >= Number(getPopupPrice(token.value.toUpperCase())) && element.unit == token.value.toUpperCase()) && Number(getPopupPrice(token.value.toUpperCase())) !== 0) {
                        MatchingOrder(element, 'Long', formatPriceDecimal(Number(getPopupPrice(token.value.toUpperCase())), token.value.toUpperCase()));
                    }
                    else if ((element.transaction_type == 'Short' && element.entry_price <= Number(getPopupPrice(token.value.toUpperCase())) && element.unit == token.value.toUpperCase()) && Number(getPopupPrice(token.value.toUpperCase())) !== 0) {
                        MatchingOrder(element, 'Short',  formatPriceDecimal(Number(getPopupPrice(token.value.toUpperCase())), token.value.toUpperCase()));
                    }

                });

            });
        }
        if (getSessionAccount() != null && (trade_tx && trade_tx.length > 0)) {
            trade_tx.forEach(element => {
                options.forEach(token => {
                    if (element.transaction_type == "Long") {
                        if (element.stop_loss && element.stop_loss >= getPopupPrice(token.value.toUpperCase())
                            && element.unit == token.value.toUpperCase()
                            && getPopupPrice(token.value.toUpperCase()) > 0) {
                            MatchingSltp(element, 0)
                        }
                        if (element.take_profit && element.take_profit <= getPopupPrice(token.value.toUpperCase())
                            && element.unit == token.value.toUpperCase()
                            && getPopupPrice(token.value.toUpperCase()) > 0) {
                            MatchingSltp(element, 1)
                        }
                    } else {
                        if (element.stop_loss && element.stop_loss <= getPopupPrice(token.value.toUpperCase())
                            && element.unit == token.value.toUpperCase()
                            && getPopupPrice(token.value.toUpperCase()) > 0) {
                            MatchingSltp(element, 0)
                        }
                        if (element.take_profit && element.take_profit >= getPopupPrice(token.value.toUpperCase())
                            && element.unit == token.value.toUpperCase()
                            && getPopupPrice(token.value.toUpperCase()) > 0) {
                            MatchingSltp(element, 1)
                        }
                    }
                });
            });
        }

        if (trade_tx && trade_tx.length > 0) {
            let sum_pnl = 0;
            trade_tx.forEach(element => {
                sum_pnl += Number(GetPnl(element));
            });
            const equity_amt = Number(account.current_balance) + Number(sum_pnl)
            setEquity(equity_amt.toFixed(2));
            b_equity(equity_amt.toFixed(2));

            const _dailyLoss = Number(sum_pnl + account.today_pnl);
            const _totalLoss = Number(sum_pnl + account.total_pnl);
            
            if (mode === 'INSTANT' && _totalLoss >= account?.balance * 10 / 100 && !account?.is_enabled_smart_drawdown) {
                // call api update trạng thái
                updateSmartDrawDown({
                    id: account.id,
                    is_enabled_smart_drawdown : true
                }).then(c => {
                    GetAccountById(getSessionAccount());
                })
            }
            const percentLossLeft = account?.is_enabled_smart_drawdown ? 5 : 10
            if (_totalLoss <= (- account?.balance * percentLossLeft / 100) && !account.is_total_loss_left_violate) {
                lockTradingUser({
                    id : account.id,
                    current_price : {
                        ...priceCrypto,
                        ...priceFX
                    }
                }).then(c => {
                    GetAccountById(getSessionAccount());
                    GetTradingPosition(getSessionAccount())
                });
            }

            setDailyLossLeft(_dailyLoss.toFixed(2));
            setTotalLossLeft(_totalLoss.toFixed(2));
        } else {
            if (account) {
                const equity_amt = Number(account.current_balance)
                setEquity(equity_amt.toFixed(2));
                b_equity(equity_amt.toFixed(2));
                setDailyLossLeft(Number(account.today_pnl).toFixed(2));
                setTotalLossLeft(Number(account.total_pnl).toFixed(2));
            }
        }
    }, [price]);

    const {
        priceFX,
        onSelectFx,
        loadingFX,
    } = usePriceFX({ tradeOrder: trade_tx_order, tradeTx: trade_tx, listTradingPair: options});

    const calculateTotalLossLeft = () => {
        // {account ? (account?.balance * 10 / 100)
        if (!account) {
            return 0
        }
        if ((mode === 'INSTANT' && totalLossLeft >= account?.balance * 10 / 100) || account?.is_enabled_smart_drawdown) {
            return account?.balance * 5 / 100
        }
        return  account?.balance * 10 / 100
    }

    const checkDisplayTotalLossLeft = () => {
        if ((mode === 'INSTANT' && totalLossLeft >= account?.balance * 10 / 100) || account?.is_enabled_smart_drawdown) {
            return false
        }
        return true
    }

    const handleClickToken = (e) => {
        var item = options.find(x => x.value === e.value);
        if (item?.status !== 2) { // 2-> coming soon
            setSelectedToken(e.value)
            sessionStorage.setItem("token", e.value);
            // GetTradingPosition(getSessionAccount());
            // GetTradingOrder(getSessionAccount());
            // GetTradingHistory(getSessionAccount());
            setOpStopLoss('None');
            setOpStopLossPrice(getPopupPrice());
            setOpTakeProfit('None');
            setOpTakeProfitPrice(getPopupPrice());
            setSelectedTradingActive(item?.isActive);
            setTrading(item);
            setValue(0);
            setPayAmount(0)
            setMarkPrice(0)
            // New add cặp trading để lắng nghe event;
            if(item.type === "CRYPTO") {
                onSelectCryptoToken(item)
                onSelectFx(null)
            }else{
                onSelectFx(item)
                onSelectCryptoToken(null)
            }
            
        }
    };

    const getPopupPrice = (token) => {
        if (token == null) {
            const tokenValue = sessionStorage.getItem("token");
            const findToken = options.find(c => c.value == tokenValue);
            
           if (findToken?.type === "CRYPTO") { //CRYPTO
            return Number(priceCrypto?.[`${tokenValue}`]) ? Number(priceCrypto?.[`${tokenValue}`]) : 0;
           }
           return Number(priceFX?.[`${findToken.symbol_exchange}`]) || 0;
        } else {
            const findToken = options.find(c => c.value == token);
            if (findToken?.type === "CRYPTO") {
                return Number(priceCrypto?.[`${token}`]) ? Number(priceCrypto?.[`${token}`]) : 0;
            }
            return Number(priceFX?.[`${findToken.symbol_exchange}`]) || 0;
        }

    }

    const getTokenType = (token) => {
        const tokenValue = token || sessionStorage.getItem("token");
        const findToken = options.find(c => c.value === tokenValue);
        if (!findToken) {
            return "UNKNOWN";
        }
        return findToken.type;
    }


    const getTabLabel = (tabIndex) => {
        switch (tabIndex) {
            case 0: return 'Position' + ' (' + trade_tx.length + ')';
            case 1: return 'Order' + ' (' + trade_tx_order.length + ')';
            case 2: return 'History' + ' (' + trade_tx_history.length + ')';
        }
    }

    const setTradingPair = async () => {
        try {
            const item = trading;

            // Replace this with your actual API call
            api_no_auth.post(`/api/TradingPair/set_trading_pair`, { id: item.idFavorite ?? '', accountid: accountId, tradingid: item.id, status: item.isActive })
                .then(response => {
                    if (response && response.data.length > 0) {
                        getTradingPair(accountId)
                    }
                })
                .catch(error => console.error(error));
        } catch (error) {
            console.error("Error fetching options:", error);
        }
    };

    const MaxLaverageSlider = () => {
        if (mode === 'INSTANT') {
           const type =  getTokenType();
           if (type === 'FX') {
             return 50
           }else {
                return 3
           }
        }
        return sessionStorage.getItem("token") === "BTC" ? 50 : 25
    };
   

    const { refundEth, error } = useRefundEth();
    const handleRefund = async (item) => {
        const responseRefund = await api.get('/api/trading_tx/get_v1_trading_transaction_refund_info/' + item.id);
        if (!responseRefund || !responseRefund.data || !responseRefund.data.recipient || !responseRefund.data.amount) {
            alert("Please enter address and amount of ETH");
            return;
        }
        await refundEth({
            recipientAddress: responseRefund.data.recipient,
            txId: item.tx_id,
            refundType: RefundType.CANCEL_LIMIT,
            refundAmount: responseRefund.data.amount.toFixed(7)
        })
        if (!error) {
            setIsProcessingLimit(false);
            GetTradingOrder(getSessionAccount());
        } else {
            setIsProcessingLimit(false);
        }
    };

    const [openCloseOrder, setOpenCloseOrder] = useState(false);
    const [itemCloseOrder, setItemCloseOrder] = useState(null);
    const [isProcessingLimit, setIsProcessingLimit] = useState(false);
    const handleOpenCloseOrder = (row) => {
        setItemCloseOrder(row);
        setOpenCloseOrder(true);
        setIsProcessingLimit(true);
    };
    const handleCloseCloseOrder = () => {
        setItemCloseOrder(null);
        setOpenCloseOrder(false);
        setIsProcessingLimit(false);
    };
    const handleConfirmCloseOrder = () => {
        setOpenCloseOrder(false);
        handleRefund(itemCloseOrder);
    };

    const estimateTpSl = (type) => {
        if (!checked) {
             return ''
        }
        const itemToken = options.find(c => c.value == selectedToken);
        
        const entryPrice = opTrading === "Limit" ? markPrice : Number(getPopupPrice(itemToken.value));
        if (entryPrice <= 0 ) {
            return 0
        }
        const closePrice = type === 'TP' ? opTakeProfitPrice : opStopLossPrice
       
       if (activeTrading == 'Long') {
                const pnl_value = (closePrice - entryPrice) * (Number(payAmount) / entryPrice) * value;
                return Number(pnl_value).toFixed(2);
        } else {
                const pnl_value = (entryPrice - closePrice) * (payAmount / entryPrice) * value;
                return Number(pnl_value).toFixed(2);
        }
       
    }

    return (
        <Box component="main" sx={{ flexGrow: 1, width: '100%', backgroundColor: '#010002', minHeight: '70vh', height: 'auto' }}>
            <Box className="background-lauchapp">
                <Box className="content-upgrade">
                    <Grid className="chart-trade-mobile" container spacing={0} sx={{ backgroundColor: '#2f2f2f', borderRadius: '10px', padding: '5px', marginBottom: '5px' }}>
                        <Grid size={{ xs: 6, md: 6 }} className={`button-visible-trading ${changeChartTradeMobile === 'Chart' ? 'active' : ''}`} onClick={showChangeChartTradeMobile('Chart')}>
                            Chart
                        </Grid>
                        <Grid size={{ xs: 6, md: 6 }} className={`button-visible-trading ${changeChartTradeMobile === 'Trade' ? 'active' : ''}`} onClick={showChangeChartTradeMobile('Trade')}>
                            Trade
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid size={{ xs: 12, md: 8 }} className={` ${isVisibleChart ? 'visible' : 'invisible'} `}>
                            <div className='left-app text-white top-chart' style={{ marginBottom: '15px' }}>
                                <Grid container spacing={6} columns={12}>
                                    <Grid container spacing={2} size={3.5}>
                                        <Grid size={11}>
                                            <FormControl sx={{
                                                '& .MuiInputBase-root': {
                                                    color: 'white', // Text color
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#0091ff', // Border color
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#0091ff', // Hover border color
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#0091ff', // Focused border color
                                                    },
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: '#0091ff', // Label color
                                                },
                                                '& .MuiInputLabel-root.Mui-focused': {
                                                    color: '#0091ff', // Focused label color
                                                },
                                                width: '100%'
                                            }}>
                                                <InputLabel id="demo-controlled-open-select-label">Trading pair</InputLabel>
                                                <SelectWithSearch
                                                    options={options}
                                                    selectedToken={selectedToken}
                                                    handleClickToken={handleClickToken}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid size={1} container >
                                            {selectedTradingActive === 1 ?
                                                <Box className="favorite-trading-active pointer" onClick={setTradingPair}>
                                                    <StarIcon sx={{ fontSize: '30px' }} />
                                                </Box>
                                                : <Box className="favorite-trading-active pointer" onClick={setTradingPair}>
                                                    <StarBorderIcon sx={{ fontSize: '30px' }} />
                                                </Box>
                                            }
                                        </Grid>


                                    </Grid>
                                    <Grid size={8.5}>
                                        <Box>
                                            <Grid className='text-dark-gray' container spacing={2} sx={{ fontSize: '12px' }}>
                                                <Grid size={{ xs: 3, md: 3, }}>Funding rate / 1h:</Grid>
                                                <Grid size={{ xs: 3, md: 3, }}>
                                                    {/* Open Interest (<span className='text-dark-green'>{longPercentOpenInterest}%</span>/<span className='text-red'>{shortPercentOpenInterest}%</span>) */}
                                                    </Grid>
                                                <Grid size={{ xs: 2, md: 2, }}>24h Volume</Grid>
                                                <Grid size={{ xs: 2, md: 2, }}>Total loss left</Grid>
                                                {account?.mode !== 'INSTANT' && <Grid size={{ xs: 2, md: 2, }}>Daily loss left</Grid>}
                                                { account?.mode === 'INSTANT' && <Grid size={{ xs: 2, md: 2, }}>Smart drawdown</Grid>}
                                            </Grid>
                                            <Grid container spacing={2} sx={{ fontSize: '14px' }}>
                                                <Grid container spacing={1} size={{ xs: 3, md: 3, }}>
                                                    <span className='text-dark-green'>
                                                        <svg height="9.856" viewBox="0 0 15.704 9.856" width="12" xmlns="http://www.w3.org/2000/svg" class="relative top-1 opacity-70"><path d="m529-488.59v5.67l-2.113-2.109-5.326 5.319-2.924-2.921-3.9 3.9-1.444-1.448 5.341-5.341 2.924 2.924 3.882-3.882-2.113-2.109z" fill="currentColor" transform="translate(-513.3 488.59)"></path></svg>
                                                        &nbsp;{getFundingRate()}%
                                                    </span>
                                                </Grid>
                                                <Grid container spacing={1} size={{ xs: 3, md: 3, }}>
                                                    {/* <span >
                                                        <svg height="9.856" viewBox="0 0 15.704 9.856" width="12" xmlns="http://www.w3.org/2000/svg" class="relative top-1 opacity-70"><path d="m529-488.59v5.67l-2.113-2.109-5.326 5.319-2.924-2.921-3.9 3.9-1.444-1.448 5.341-5.341 2.924 2.924 3.882-3.882-2.113-2.109z" fill="currentColor" transform="translate(-513.3 488.59)"></path></svg>
                                                        {formatCurrency(longOpenInterest)}
                                                    </span>
                                                    <span >
                                                        <svg height="9.856" viewBox="0 0 15.704 9.856" width="12" xmlns="http://www.w3.org/2000/svg" class="relative opacity-70"><path d="m0 0v5.67l2.113-2.11 5.326 5.32 2.924-2.921 3.9 3.9 1.437-1.451-5.337-5.341-2.924 2.924-3.882-3.882 2.113-2.109z" fill="currentColor" transform="matrix(-1 0 0 -1 15.704 9.856)"></path></svg>
                                                        {formatCurrency(shortOpenInterest)}
                                                    </span> */}
                                                </Grid>
                                                <Grid size={{ xs: 2, md: 2, }}>{formatCurrency(volume)}</Grid>
                                                <Grid size={{ xs: 2, md: 2, }}>{totalLossLeft} / -{calculateTotalLossLeft() || 0}</Grid>
                                                {account?.mode !== "INSTANT" && <Grid size={{ xs: 2, md: 2, }}>{dailyLossLeft} / -{(account?.balance * 5  || 0)/ 100}</Grid>}
                                                {account?.mode === 'INSTANT' && <Grid size={{ xs: 2, md: 2, }}>{ account?.is_enabled_smart_drawdown ? "Enable" : "Disable" }</Grid>}
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="App">
                                <TradingViewChart token={selectedToken} tradingPairs={options} />
                            </div>
                            <div className="App left-app" style={{ marginTop: '20px', padding: '0px' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="trading tab">
                                        <Tab sx={{ color: 'white' }} label={getTabLabel(0)} {...a11yProps(0)} />
                                        <Tab sx={{ color: 'white' }} label={getTabLabel(1)}  {...a11yProps(1)} />
                                        <Tab sx={{ color: 'white' }} label={getTabLabel(2)}  {...a11yProps(2)} />
                                    </Tabs>
                                </Box>
                                <CustomTabPanel value={tabValue} index={0}>
                                    <TableContainer sx={{ padding: '0px', maxHeight: 500, overflow: 'auto' }} component={Paper}>
                                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Order</TableCell>
                                                    <TableCell>Symbol</TableCell>
                                                    <TableCell>Position</TableCell>
                                                    <TableCell>Type</TableCell>
                                                    <TableCell align="right">Net Value</TableCell>
                                                    <TableCell align="right">Volume</TableCell>
                                                    <TableCell align="right">Entry Price</TableCell>
                                                    <TableCell align="right">Mark Price</TableCell>
                                                    <TableCell align="right">PnL</TableCell>
                                                    <TableCell align="right">Fee borrow</TableCell>
                                                    <TableCell align="right">Funding rate</TableCell>
                                                    {/* <TableCell align="center">Last price</TableCell> */}
                                                    <TableCell align="center">Close Position</TableCell>
                                                    <TableCell align="center">SL/TP for position</TableCell>
                                                    
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {trade_tx.map((row) => (
                                                    <TableRow
                                                        key={row.tx_dt}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell> {row.stt} </TableCell>
                                                        <TableCell> {row.trading_pair_label} </TableCell>
                                                        <TableCell> {row.transaction_type} </TableCell>
                                                        <TableCell> {row.trading_type} </TableCell>
                                                        <TableCell align="right">{row.pay_amt}</TableCell>
                                                        <TableCell align="right">{row.size}</TableCell>
                                                        <TableCell align="right">{formatPriceDecimal(row.entry_price, row.unit)}</TableCell>
                                                        <TableCell align="right">{row.mark_price}</TableCell>
                                                        <TableCell align="right"><span style={{ color: GetPnl(row) < 0 ? 'red' : 'green' }}>{GetPnl(row)}</span></TableCell>
                                                        <TableCell align="right"><span style={{ color: 'red' }}>{GetBorrowFee(row)}</span></TableCell>
                                                        <TableCell align="right"><span style={{ color: GetFundingRateFee(row) < 0 ? 'red' : 'green'  }}>{GetFundingRateFee(row)}</span></TableCell>
                                                        {/* <TableCell align="right">{formatPriceDecimal(row.last_market_price, row.unit)} ({Number(isMarketClosed(row.unit))})</TableCell> */}
                                                        <TableCell align="center">
                                                            {isProcessingMarket && txClosing.includes(row.id) ?
                                                                <LoadingButton
                                                                    loading={true}
                                                                    loadingPosition="start"
                                                                    startIcon={<SaveIcon />}
                                                                    sx={{ backgroundColor: '#ddd' }}>
                                                                    Processing
                                                                </LoadingButton>
                                                                :
                                                                <Button
                                                                    variant="outlined"
                                                                    disabled = {
                                                                       ( row.trading_pair_type === 'FX' && 
                                                                        isMarketClosed(row.unit)) || 
                                                                        row.is_locked
                                                                    }
                                                                    onClick={() => {
                                                                        row.pnl_amt = GetPnl(row);
                                                                        handleCloseTx(row, 0);
                                                                    }}>Market</Button>

                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                                                                {(row.stop_loss || row.take_profit) && <span style={{ marginRight: '10%' }}>{formatPriceDecimal(row.stop_loss, row.unit)}/{formatPriceDecimal(row.take_profit, row.unit)}</span>}

                                                                {sessionStorage.getItem('account')
                                                                    && <EditIcon 
                                                                        sx={{ 
                                                                            fontSize: 15, 
                                                                            cursor: (isMarketClosed(row.unit) && row.trading_pair_type == 'FX') || account?.is_total_loss_left_violate ? 'not-allowed' :'pointer' }} 
                                                                        onClick={() => { 
                                                                            if (
                                                                                (isMarketClosed(row.unit) && 
                                                                                row.trading_pair_type === 'FX') || 
                                                                                account?.is_total_loss_left_violate
                                                                            ) {
                                                                                return;
                                                                            }
                                                                            handleEditPopup(row)
                                                                         }} 
                                                                        disabled
                                                                    />}
                                                            </div>
                                                        </TableCell>
                                                        {sltpPopup && (<ChangeSltp
                                                            trading_id={sltpTradingId}
                                                            stop_loss={sltpStoplossValue}
                                                            take_profit={sltpTakeProfitValue}
                                                            handleClose={handleEditPopup}
                                                            price={price}
                                                        />)}
                                                    </TableRow>

                                                ))}
                                                {(!trade_tx || trade_tx.length == 0) &&
                                                    <TableRow
                                                        key='none'
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell colSpan={10}> No data </TableCell>
                                                    </TableRow>}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CustomTabPanel>
                                <CustomTabPanel value={tabValue} index={1}>
                                    <TableContainer sx={{ padding: '0px', maxHeight: 500, overflow: 'auto' }} component={Paper}>
                                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Order</TableCell>
                                                    <TableCell>Symbol</TableCell>
                                                    <TableCell>Position</TableCell>
                                                    <TableCell>Type</TableCell>
                                                    <TableCell align="right">Net Value</TableCell>
                                                    <TableCell align="right">Volume</TableCell>
                                                    <TableCell align="right">Entry Price</TableCell>
                                                    <TableCell align="right">Mark Price</TableCell>
                                                    <TableCell align="center">Close Position</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {trade_tx_order.map((row) => (
                                                    <TableRow
                                                        key={row.tx_dt}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell> {row.stt} </TableCell>
                                                        <TableCell> {row.trading_pair_label}</TableCell>
                                                        <TableCell> {row.transaction_type} </TableCell>
                                                        <TableCell> {row.trading_type} </TableCell>
                                                        <TableCell align="right">{row.pay_amt}</TableCell>
                                                        <TableCell align="right">{row.size}</TableCell>
                                                        <TableCell align="right">{row.entry_price}</TableCell>
                                                        <TableCell align="right">{formatPriceDecimal(row.mark_price, row.unit)}</TableCell>
                                                        <TableCell align="center">
                                                            {!isProcessingLimit ?
                                                                <Button 
                                                                    variant="outlined"  
                                                                    disabled = {row.trading_pair_type === 'FX' && isMarketClosed(row.unit) && account?.is_total_loss_left_violate} 
                                                                    onClick={() => { handleOpenCloseOrder(row) }}>Limit</Button>
                                                                : <LoadingButton
                                                                    loading={true}
                                                                    loadingPosition="start"
                                                                    startIcon={<SaveIcon />}
                                                                    sx={{ backgroundColor: '#ddd' }}>
                                                                    Processing
                                                                </LoadingButton>
                                                            }
                                                            <PopupCloseOrder item={row} open={openCloseOrder} onClose={handleCloseCloseOrder} onConfirm={handleConfirmCloseOrder} />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                {(!trade_tx_order || trade_tx_order.length == 0) &&
                                                    <TableRow
                                                        key='none'
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell colSpan={10}> No data </TableCell>
                                                    </TableRow>}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CustomTabPanel>
                                <CustomTabPanel value={tabValue} index={2}>
                                    <TableContainer sx={{ padding: '0px', maxHeight: 500, overflow: 'auto' }} component={Paper}>
                                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Order</TableCell>
                                                    <TableCell>Symbol</TableCell>
                                                    <TableCell>Position</TableCell>
                                                    <TableCell>Type</TableCell>
                                                    <TableCell align="right">Net Value</TableCell>
                                                    <TableCell align="right">Volume</TableCell>
                                                    <TableCell align="right">Entry Price</TableCell>
                                                    <TableCell align="right">Mark Price</TableCell>
                                                    <TableCell align="right">PnL</TableCell>
                                                    <TableCell align="right">Exit Price</TableCell>
                                                    <TableCell align="right">SL/TP</TableCell>
                                                    <TableCell align="right">Tx Date</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {trade_tx_history.map((row) => (
                                                    <TableRow
                                                        key={row.tx_dt}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell> {row.stt} </TableCell>
                                                        <TableCell> {row.trading_pair_label} </TableCell>
                                                        <TableCell> {row.transaction_type} </TableCell>
                                                        <TableCell> {row.trading_type} </TableCell>
                                                        <TableCell align="right">{row.pay_amt}</TableCell>
                                                        <TableCell align="right">{row.size}</TableCell>
                                                        <TableCell align="right">{row.entry_price}</TableCell>
                                                        <TableCell align="right">{row.mark_price}</TableCell>
                                                        <TableCell align="right"><span style={{ color: row.pnl_amt < 0 ? 'red' : 'green' }}>{row.pnl_amt}</span></TableCell>
                                                        <TableCell align="right">{row.exit_price}</TableCell>
                                                        <TableCell align="center">
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                                                                {(row.stop_loss || row.take_profit) && <span style={{ marginRight: '10%' }}>{formatPriceDecimal(row.stop_loss, row.unit)}/{formatPriceDecimal(row.take_profit, row.unit)}</span>}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="right">{row.tx_dt}</TableCell>
                                                    </TableRow>
                                                ))}
                                                {(!trade_tx_history || trade_tx_history.length == 0) &&
                                                    <TableRow
                                                        key='none'
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell colSpan={10}> No data </TableCell>
                                                    </TableRow>}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CustomTabPanel>

                            </div>
                        </Grid>
                        <Grid size={{ xs: 12, md: 4 }} className={`${isTradeShow ? 'trade-mobile' : 'trade-mobile-show'} ${isVisibleTrade ? 'visible' : 'invisible'} `}>
                            <Box className='right-app'>
                                <Box>
                                    <Grid container spacing={0} sx={{ backgroundColor: '#2f2f2f', borderRadius: '10px', padding: '5px' }}>
                                        <Grid size={{ xs: 6, md: 6 }} className={`button-trading ${activeTrading === 'Long' ? 'active' : ''}`} onClick={showTrading(1, 1, 'Long')}>
                                            Long
                                        </Grid>
                                        <Grid size={{ xs: 6, md: 6 }} className={`button-trading ${activeTrading === 'Short' ? 'active' : ''}`} onClick={showTrading(2, 1, 'Short')}>
                                            Short
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box>
                                    <Grid container spacing={2} sx={{ borderRadius: '10px', marginTop: '20px' }}>
                                        <Grid className={`button-market ${opTrading === 'Market' ? 'active' : ''}`} onClick={changeOpTrading('Market')}>
                                            Market
                                        </Grid>
                                        <Grid className={`button-market ${opTrading === 'Limit' ? 'active' : ''}`} onClick={changeOpTrading('Limit')}>
                                            Limit
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box sx={{ backgroundColor: '#1c1c1c', padding: '20px', borderRadius: '20px', marginTop: '20px' }}>
                                    <Grid container spacing={2}>
                                        <Grid display="flex" justifyContent="left" alignItems="nt" size={6}>
                                            <Box className='text-dark-gray' sx={{ fontSize: '20px' }}>
                                                Pay: {payAmount && <span className='text-white'>{payAmount} USDX</span>}
                                            </Box>
                                        </Grid>
                                        <Grid display="flex" justifyContent="right" alignItems="nt" size="grow">
                                            <Box className='text-dark-gray' sx={{ fontSize: '20px' }}>Balance: <b style={{ color: 'white' }}>{account?.current_balance}</b> $USDX</Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                                        <Grid display="flex" justifyContent="left" alignItems="center" size={6}>
                                            <input 
                                                type='text' 
                                                className='text-dark-gray input-market' 
                                                value={payAmount} 
                                                onChange={changePayAmount}
                                                onFocus={(e) => {
                                                    if (e.target.value === "0") {
                                                      setPayAmount("");
                                                    }
                                                }}
                                                onBlur={() => payAmount === "" && setPayAmount(0)} 
                                            />
                                        </Grid>
                                        <Grid display="flex" justifyContent="right" alignItems="nt" size="grow">
                                            <div className='usdx'><img style={{ width: '24px', height: '24px' }} src={`${process.env.PUBLIC_URL}/images/usdc-logo.png`} />
                                                &nbsp;USDX</div>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box sx={{ backgroundColor: '#1c1c1c', padding: '20px', borderRadius: '20px', marginTop: '15px' }}>
                                    <Grid container spacing={2}>
                                        <Grid display="flex" justifyContent="left" alignItems="nt" size={4}>
                                            <Box className='text-dark-gray' sx={{ fontSize: '20px' }}>
                                                Leverage <b style={{ color: 'white' }}>{value}x</b>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                                        <Grid display="flex" justifyContent="left" alignItems="center" size={6}>
                                            <input 
                                                type='text' 
                                                className='text-dark-gray input-market' 
                                                value={value} 
                                                onFocus={(e) => {
                                                    if (e.target.value === "0") {
                                                      setValue("");
                                                    }
                                                  }}
                                                onChange={handleLeverageChange} 
                                                onBlur={() => value === "" && setValue(0)} 
                                            />

                                        </Grid>
                                    </Grid>
                                </Box>
                                {opTrading === "Limit" && <Box sx={{ backgroundColor: '#1c1c1c', padding: '20px', borderRadius: '20px', marginTop: '20px' }}>
                                    <Grid container spacing={2}>
                                        <Grid display="flex" justifyContent="left" alignItems="nt" size={6}>
                                            <Box className='text-dark-gray' sx={{ fontSize: '20px' }}>Price</Box>
                                        </Grid>
                                        <Grid display="flex" justifyContent="right" alignItems="nt" size="grow">
                                            <Box className='text-dark-gray' sx={{ fontSize: '20px' }}>Mark</Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                                        <Grid display="flex" justifyContent="left" alignItems="center" size={6}>
                                            <input type='number' step="0.01" className='text-dark-gray input-market no-spinner' value={markPrice} onBlur={handleBlurMark} onChange={handleMarkChange} min={0} ></input>
                                        </Grid>
                                        <Grid display="flex" justifyContent="right" alignItems="nt" size="grow">
                                            <div className='usdx'><img style={{ width: '24px', height: '24px' }} src={`${process.env.PUBLIC_URL}/images/usdc-logo.png`} />
                                                &nbsp;USDX</div>
                                        </Grid>
                                    </Grid>
                                </Box>}
                                <Box>
                                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                                        <Grid display="flex" justifyContent="left" alignItems="nt" size="auto">
                                            <Box className='text-dark-gray' sx={{ fontSize: '20px' }}>Laverage slider</Box>
                                        </Grid>
                                        <Grid display="flex" justifyContent="right" alignItems="nt" size="grow">
                                            <Switch
                                                checked={isOn}
                                                onChange={handleSwitchChange}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box>
                                    {isOn && <Slider
                                        sx={{
                                            '& .MuiSlider-markLabel': {
                                                color: 'white',  // Color of the label text
                                            },
                                        }}
                                        size="medium"
                                        defaultValue={5}
                                        min={1}
                                        max={MaxLaverageSlider()}
                                        marks={marks}
                                        value={value}
                                        onChange={handleSliderChange} aria-label="Default" valueLabelDisplay="auto" />}
                                </Box>
                                { !(isMarketClosed(trading?.value) && trading?.type == "FX") && !account?.is_total_loss_left_violate && 
                                    <Box sx={{ marginTop: '20px' }}>
                                    <FormControlLabel
                                        sx={{ color: '#fff' }}
                                        label="TP/SL"
                                        control={<Checkbox checked={checked[0]} onChange={handleChangeCheckbox} />}
                                    />
                                    </Box>
                                }
                                {checked && !(isMarketClosed(trading?.value) && trading?.type == "FX") && !account?.is_total_loss_left_violate &&
                                    <Box sx={{ marginTop: '20px' }}>
                                        <Grid container spacing={2}>
                                            <Grid display="flex" justifyContent="left" alignItems="nt" size="auto">
                                                <Box className='text-dark-gray' sx={{ fontSize: '20px' }}>Stop Loss</Box>
                                            </Grid>
                                            <Grid display="flex" justifyContent="right" alignItems="nt" size="grow">
                                                <Box sx={{ fontSize: '20px', color: 'red' }} >PNL estimate: {estimateTpSl('SL')}$</Box>
                                            </Grid>
                                            {/* <Grid display="flex" justifyContent="right" alignItems="nt" size="grow">
                                                <Box sx={{ fontSize: '20px', color: 'red' }} >{opStopLossPrice}</Box>
                                            </Grid> */}
                                        </Grid>
                                        {
                                            loadingCrypto || loadingFX  ?
                                            <Grid 
                                                container
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <CircularProgress size={24} />
                                                {/* <span style={{ color: "red"}}>{Number(loadingCrypto)} : {Number(loadingFX)}</span> */}
                                            </Grid>
                                            :
                                            <Grid container spacing={1} sx={{ marginTop: '10px' }}>
                                            <Grid key={1} size={2} >
                                                <TextField type='number' id="outlined-basic" label="Price" variant="outlined" value={opStopLossPrice} onChange={handleStoplossInputChange}
                                                    sx={{
                                                        '& input[type=number]::-webkit-inner-spin-button': {
                                                            WebkitAppearance: 'none', // Xóa spinner trên Chrome, Safari
                                                            margin: 0,
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            color: 'white', // Text color
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: '#0091ff', // Border color
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: '#0091ff', // Hover border color
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#0091ff', // Focused border color
                                                            },
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: '#0091ff', // Label color
                                                        },
                                                        '& .MuiInputLabel-root.Mui-focused': {
                                                            color: '#0091ff', // Focused label color
                                                        },
                                                    }} />
                                            </Grid>
                                            {listOpStopLoss.map(item => (
                                                <Grid key={item.id} size={2} className={`button-lost ${opStopLoss === item.value ? 'active' : ''}`} onClick={changeOpStopLoss(item.value)}>
                                                    {item.value}
                                                </Grid>
                                            ))}
                                        </Grid>
                                        }
                                    </Box>
                                }

                                {checked && !(isMarketClosed(trading?.value) && trading?.type == "FX") && <Box sx={{ marginTop: '20px' }}>
                                    <Grid container spacing={2}>
                                        <Grid display="flex" justifyContent="left" alignItems="nt" size="auto">
                                            <Box className='text-dark-gray' sx={{ fontSize: '20px' }}>Take Profit</Box>
                                        </Grid>
                                        <Grid display="flex" justifyContent="right" alignItems="nt" size="grow">
                                            <Box className='text-dark-green' sx={{ fontSize: '20px' }} >PNL estimate: {estimateTpSl("TP")}$</Box>
                                        </Grid>
                                        {/* <Grid display="flex" justifyContent="right" alignItems="nt" size="grow">
                                            <Box className='text-dark-green' sx={{ fontSize: '20px' }} >{opTakeProfitPrice}</Box>
                                        </Grid> */}
                                    </Grid>
                                    {
                                        loadingCrypto || loadingFX ?
                                        <Grid  
                                            container
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <CircularProgress size={24} />
                                        </Grid>
                                        :
                                        <Grid container spacing={1} sx={{ marginTop: '10px' }}>
                                            <Grid key={1} size={2}>
                                                <TextField type='number' id="outlined-basic" label="Price" value={opTakeProfitPrice} variant="outlined" onChange={handleTakeProfitInputChange}
                                                    sx={{
                                                        '& input[type=number]::-webkit-inner-spin-button': {
                                                                WebkitAppearance: 'none', // Xóa spinner trên Chrome, Safari
                                                                margin: 0,
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            color: 'white', // Text color
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: '#0091ff', // Border color
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: '#0091ff', // Hover border color
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#0091ff', // Focused border color
                                                            },
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: '#0091ff', // Label color
                                                        },
                                                        '& .MuiInputLabel-root.Mui-focused': {
                                                            color: '#0091ff', // Focused label color
                                                        },
                                                    }} />
                                            </Grid>
                                            {listOpTakeProfit.map(item => (
                                                <Grid key={item.id} size={2} className={`button-lost ${opTakeProfit === item.value ? 'active' : ''}`} onClick={changeOpTakeProfit(item.value)}>
                                                    {item.value}
                                                </Grid>
                                            ))}
                                        </Grid>
                                    }


                                </Box>}
                                <Box>
                                    <Box className="enter-amount pointer" style = { (isMarketClosed(trading?.value) && trading?.type == "FX") || account?.is_total_loss_left_violate ? { backgroundColor: "gray", color: "white"} : {}} >
                                        {session_account ?
                                            <Grid display="flex" justifyContent="center" alignItems="center" onClick={() => {
                                                // if (!price) {
                                                //     return;
                                                // }
                                                togglePopup()
                                            }}>
                                                <Box>
                                                    Enter an amount
                                                </Box>

                                            </Grid>
                                            :
                                            <Grid display="flex" justifyContent="center" alignItems="center" onClick={togglePopupLogin}>
                                                <Box>
                                                    Login
                                                </Box>


                                            </Grid>}
                                        {isOpenLogin && (
                                            <PopupLogin
                                                handleClose={togglePopupLogin}
                                                reload={b_reload}
                                            />
                                        )}
                                        {isOpen && (
                                            <Popup
                                                opTakeProfitPrice={Number(opTakeProfitPrice)}
                                                opStopLossPrice={Number(opStopLossPrice)}
                                                checked_sltp={checked}
                                                markPrice={Number(markPrice)}
                                                opTrading={opTrading}
                                                leverage={Number(value)}
                                                ethPrice={price}
                                                payAmount={Number(payAmount)}
                                                activeTrading={activeTrading}
                                                tokenPrice={getPopupPrice()}
                                                handleClose={togglePopup}
                                                tokenType={getTokenType()}
                                                onOpenTxSuccess = {() => {
                                                    // updateLastActivity()
                                                }}
                                            />
                                        )}

                                    </Box>
                                </Box>

                            </Box>

                            {/*  <Box className='right-app' sx={{ marginTop: '20px' }}>
                                <Box sx={{ borderBottom: '#a0a0a0 solid 1px', paddingBottom: '20px', color: '#fff', fontSize: '20px' }}>{activeTrading === "Long" ? "Long ETH" : "Short Link"}</Box>
                                <Box sx={{ marginTop: '20px' }}>
                                    <Grid container spacing={0} sx={{ fontSize: '20px', lineHeight: '35px' }}>
                                        <Grid display="flex" justifyContent="center" alignItems="center" size="auto">
                                            <Box>
                                                <Box className='text-dark-gray' >Entry Price</Box>
                                                <Box className='text-dark-gray' >Exit Price</Box>
                                            </Box>

                                        </Grid>
                                        <Grid container spacing={1.2} display="flex" justifyContent="right" alignItems="center" size="grow">
                                            <Box sx={{ textAlign: 'end' }}>
                                                <Box className='text-white'>${entryPrice}</Box>
                                                <Box className='text-white'>0.0029% / 1h</Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box> */}

                            {/* <Box className='right-app' sx={{ marginTop: '20px' }}>
                                <Box sx={{ borderBottom: '#a0a0a0 solid 1px', paddingBottom: '20px', color: '#fff', fontSize: '20px' }}>Useful Links</Box>
                                <Box sx={{ marginTop: '20px' }}>
                                    <Grid container spacing={0} sx={{ fontSize: '20px', lineHeight: '35px' }}>
                                        <Grid display="flex" justifyContent="center" alignItems="center" size="auto">
                                            <Box>
                                                <Box className='text-white underline' >Trading guide</Box>
                                                <Box className='text-white underline' >Leaderboard</Box>
                                                <Box className='text-white underline' >Speed up page loading</Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box> */}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {/* <div className="overlay">
                <h2>Coming Soon ...</h2>
            </div> */}
        </Box>
    );
}
export default LauchApp
import { useState, useEffect } from "react";
import "./changeSlTp.css"; // Optional: Create a CSS file for styling the popup
import Grid from "@mui/material/Grid2";
import { Box, CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { ethers, BrowserProvider } from "ethers";
import { useSnackbar } from "../SnackbarProvider";
import TextField from "@mui/material/TextField";
import api from "../../instance_api";

const wallet_address_ss = localStorage.getItem("wallet_address");

const ChangeSlTp = ({
    trading_id,
    stop_loss,
    take_profit,
    handleClose,
    price,
}) => {
    const [stopLoss, setStopLoss] = useState(null);
    const [takeProfit, setTakeProfit] = useState(null);
    const showSnackbar = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setStopLoss(stop_loss);
        setTakeProfit(take_profit);
    }, []);

    const handleChangeSltp = async () => {
        if (!window.ethereum) {
            alert("MetaMask is required!");
            return;
        }

        const tempProvider = new BrowserProvider(window.ethereum);
        const network = tempProvider && (await tempProvider.getNetwork());
        const chainid = network.chainId.toString();
        // if (network.name != "base-sepolia" && chainid != "1301") {
        //     showSnackbar(
        //         "Please switch to Base Sepolia or Unichain Sepolia",
        //         "warning"
        //     );
        //     return;
        // }

        if (network.name != 'base' && chainid != "8453") {
            showSnackbar('Please switch to Base', 'warning');
            return;
        }

        setLoading(true);

        try {
            const provider = new ethers.BrowserProvider(window.ethereum);
            await provider.send("eth_requestAccounts", []); // Request account access
            const signer1 = await provider.getSigner();
            // Create transaction object
            const tx = {
                to: process.env.REACT_APP_WALLET_ADDRESS,
                value: ethers.parseEther((0.01 / Number(price)).toFixed(7).toString()), // Convert ETH to wei
            };
            // Send the transaction
            const transactionResponse = await signer1.sendTransaction(tx);

            // Wait for transaction confirmation
            const receipt = await transactionResponse.wait();
            if (receipt.status == 1) {
                await api.post(
                    process.env.REACT_APP_API_URL +
                    "/api/trading_tx/post_v1_trading_transaction_update",
                    {
                        id: trading_id,
                        stop_loss: Number(stopLoss),
                        take_profit: Number(takeProfit),
                    }
                );
                handleClose();
            }
        } catch (error) {
            if (error.action == "estimateGas") {
                showSnackbar(
                    "The fee balance is insufficient to execute the order.",
                    "warning"
                );
                return;
            }
        } finally {
            setLoading(false);
        }
    };

    const handleChangeStoploss = (event) => {
        setStopLoss(event.target.value);
    };

    const handleChangeTakeprofit = (event) => {
        setTakeProfit(event.target.value);
    };

    return (
        <div className="popup-box-change-sltp">
            <div className="box-change-sltp">
                <Box sx={{ padding: "20px 0", fontSize: "30px" }}>
                    <Grid container spacing={2}>
                        <Grid
                            display="flex"
                            justifyContent="left"
                            alignItems="center"
                            size={6}
                        >
                            <Box className="text-white">SL/TP</Box>
                        </Grid>
                        {/* <Grid display="flex" justifyContent="right" alignItems="center" size="grow">
                            <span className="close-icon" onClick={handleClose}>
                                &times;
                            </span>
                        </Grid> */}
                    </Grid>
                </Box>
                <Box className="popup-content-change-sltp" sx={{ padding: "20px" }}>
                    <Grid container spacing={2}>
                        <Grid size={6}>
                            <TextField
                                id="outlined-basic"
                                label="Stop loss"
                                variant="outlined"
                                value={stopLoss}
                                onChange={handleChangeStoploss}
                                sx={{
                                    "& .MuiInputBase-root": {
                                        color: "white", // Text color
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: "#0091ff", // Border color
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "#0091ff", // Hover border color
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: "#0091ff", // Focused border color
                                        },
                                    },
                                    "& .MuiInputLabel-root": {
                                        color: "#0091ff", // Label color
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        color: "#0091ff", // Focused label color
                                    },
                                }}
                            />
                        </Grid>
                        <Grid size={6}>
                            <TextField
                                id="outlined-basic"
                                label="Take profit"
                                variant="outlined"
                                value={takeProfit}
                                onChange={handleChangeTakeprofit}
                                sx={{
                                    "& .MuiInputBase-root": {
                                        color: "white", // Text color
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: "#0091ff", // Border color
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "#0091ff", // Hover border color
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: "#0091ff", // Focused border color
                                        },
                                    },
                                    "& .MuiInputLabel-root": {
                                        color: "#0091ff", // Label color
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        color: "#0091ff", // Focused label color
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    sx={{
                        marginTop: "20px",
                        padding: "20px 0 ",
                        borderTop: "1px solid #a0a0a0 ",
                    }}
                >
                    {/* <Grid display="flex" justifyContent="center" alignItems="center" onClick={handleChangeSltp}>
                        <Box>
                            Edit
                        </Box>

                    </Grid> */}
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Box
                            className="confirm-model-button"
                            onClick={handleChangeSltp}
                            sx={{
                                minWidth: "100px",
                                justifyContent: "center",
                            }}
                        >
                            {loading ? (
                                <CircularProgress size={15} color="white" />
                            ) : (
                                "Confirm"
                            )}
                        </Box>
                        <Box
                            className="cancel-model-button"
                            onClick={loading ? undefined : handleClose}
                            sx={{
                                opacity: loading ? 0.5 : 1,
                                cursor: loading ? 'not-allowed' : 'pointer'
                            }}
                        >
                            Cancel
                        </Box>
                    </Box>
                </Box>
            </div>
        </div>
    );
};

export default ChangeSlTp;

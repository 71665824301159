import "./index.css";
import "../../App.css";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDiamond,
  faCheck,
  faInfoCircle,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import PrimaryFooter from "../../component/X1kFooter";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef, useMemo } from "react";
import axios from "axios";
import api from "../../instance_api";
import api_key from "../../instance_api_key";
import api_no_auth from "../../instance_api_no_authentication";
import { useSnackbar } from '../SnackbarProvider';
import { convertNumber, convertToK, formatCurrency, isMobile } from "../../ultils/ultils";
import { InfoOutlined } from "@mui/icons-material";
import InstantMode from "./components/InstantMode";
import SwingMode from "./components/SwingMode";
import ScalpingMode from "./components/ScalpingMode";

export const MODE_TYPE = {
  'INSTANT': "INSTANT",
  'SWING': "SWING",
  'SCALPING': "SCALPING",
}

export const MODES = [
  { id: 1, text: "Swing", value: MODE_TYPE.SWING, status: 1 },
  {
    id: 2,
    text: "Scalping (coming soon)",
    value: MODE_TYPE.SCALPING,
    status: 0,
  },
  {
    id: 3,
    text: "Instant X1K funded",
    value: MODE_TYPE.INSTANT,
    status: 1,
  },
];

const DashBoard = () => {
  const showSnackbar = useSnackbar();

  const navigate = useNavigate();

  const Support = (step) => () => {
    navigate("support");
  };

  const Home = (step) => () => {
    navigate("/");
  };

  const handleDeposit = (id) => () => {
    const dataRow = exam.find((e) => e.id === id);
    let fee = dataRow.fee_amt;
    let balance = dataRow.balance;
    window.open(
      "/profile/deposit?balance=" + balance,
      "_blank",
      "noopener,noreferrer"
    );
  };



  let dataExam = [];
  const [exam, setShowExam] = useState(dataExam);
  const [dataInstant, setDataInstant] = useState([]);
  const descriptionRef = useRef(null);
  useEffect(() => {
    getBalance();
    getEvaluation();

    const handleClickOutside = (event) => {
      if (
        descriptionRef.current &&
        !descriptionRef.current.contains(event.target)
      ) {
        setShowDescription(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const getBalance = () => {
    api_no_auth
      .get(`/api/balance/get_v1_balance/1?mode=ALL`)
      .then((response) => {
        if (response && response.data.length > 0) {
          const listBalances = response.data;
          setListBalance(listBalances);
          setCurrentBalance(listBalances.find(c => c.mode === activeMode && c.priority));
        }
      })
      .catch((error) => console.error(error));
  };
  const [optionTrading, setOptionTrading] = useState("");
  const [optionMode, setOptionModel] = useState(3);
  
  const [currentBalance, setCurrentBalance] = useState();
  const [listBalance, setListBalance] = useState([]);
  const [activeMode, setActiveMode] = useState(MODE_TYPE.INSTANT);

  const renderBalance = () => {
    const balances = listBalance.filter((item) => item.mode === activeMode);
    return balances.map((item) => {
      return (
        <Box key={item.id} onClick={() => {
          setCurrentBalance(item);
        }}>
          <Grid
            size="auto"
            className={`balance-btn ${item.id === currentBalance?.id ? "active" : ""}`}
          >
            ${formatCurrency(item.balance)}
          </Grid>
        </Box>
      );
    });
  }

  const renderMode = () => {
    return MODES.map((item) => {
      return (
        <Box key={item.id} onClick={() => {
          if (item.value !== activeMode) {
            const balance = listBalance.find((c) => c.priority && c.mode === item.value);
            setCurrentBalance(balance);
          }
          setActiveMode(item.value);
        }}>
          <Grid
            size="auto"
            className={`balance-btn ${activeMode === item.value ? "active" : "unactive"}`}
          >
            {item.text}
          </Grid>
        </Box>
      );
    });
  }

  const renderTable = useMemo(() => {
    switch (activeMode) {
      case MODE_TYPE.INSTANT:
        return <InstantMode currentBalance={currentBalance}/>
        case MODE_TYPE.SWING:
          return <SwingMode currentBalance={currentBalance}/>
          case MODE_TYPE.SCALPING:
        return <ScalpingMode currentBalance={currentBalance}/>
      default:
        return <div></div>
        break;
    }
  },[activeMode, currentBalance])


  let dataEvalua = [];
  const [evalua, setShowEvalua] = useState(dataEvalua);
  const getEvaluation = () => {
    api_no_auth
      .get(`/api/evaluationProcess/get_v1_evaluationProcess/dashboard`)
      .then((response) => {
        if (response && response.data.length > 0) {
          let index = 1;
          response.data.forEach((element) => {
            element.title = index === 3 ? "LIVE" : "Round " + index;
            index++;
          });
          setShowEvalua(response.data);
        }
      })
      .catch((error) => console.error(error));
  };

  const [showDescription, setShowDescription] = useState(false);
  

  const profileDeposit = () => {
    if(isMobile()){
      alert('Use on laptop or pc please!')
      return;
    }
    if (!localStorage.getItem("wallet_address")) {
      showSnackbar('Connect wallet, please !', 'warning');
    } else {
      navigate("/profile/deposit");
    }
  };

  const tryPc = () => {
    navigate("/dashboard/try-pc");
  };

  
  const changeModel = (id) => () => {
    setLoading(true);
    setTimeout(() => {
      setOptionModel(id);
      setLoading(false);
    }, 100);
  };

  const data = {
    totalTradingVolume: "$255,250,093,203",
    openInterest: "$231,195,229",
    totalUsers: "685,056",
  };

  function formatNumber(_number) {
    const validNumber = parseFloat(_number);

    if (isNaN(validNumber)) {
      return "";
    }

    const absoluteNumber = Math.abs(validNumber);

    const fixedNumber = Number.isInteger(absoluteNumber)
      ? absoluteNumber.toString()
      : absoluteNumber.toFixed(2);

    return fixedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const [trade_tx_history, setTradeTxHistory] = useState([]);
  const [data_wallet, setDataWallet] = useState([]);
  const [data_payout, setDataPayout] = useState([]);
  const [total_trading_volume, setTotalTradingVolume] = useState("");
  const [total_users, setTotalUsers] = useState("");
  const [total_payout, setTotalPayout] = useState("");
  useEffect(() => {
    fetchData()
  }, []);

  const fetchData = () => {
    try {
      GetTradingHistory();
      GetDataWallet();
      GetDataPayout();
    } catch (error) {
      console.error('Call API false:', error);
    }
  };

  useEffect(() => {
    const now = new Date();
    const minutesUntilNextHour = 60 - now.getMinutes();
    const secondsUntilNextHour = (minutesUntilNextHour * 60 - now.getSeconds()) * 1000;

    const timeoutId = setTimeout(() => {
      fetchData();

      // Thiết lập gọi API mỗi giờ sau lần đầu tiên
      const intervalId = setInterval(fetchData, 60 * 60 * 1000);  // 60 phút

      return () => clearInterval(intervalId);
    }, secondsUntilNextHour);

    return () => clearTimeout(timeoutId);
  }, []);

  const GetTradingHistory = async (session_account_reload) => {
    const responseHistory = await api.get("/api/trading_tx/get-all-trading-tx");
    let totalVolume;
    if (responseHistory.data.length !== 0) {
      totalVolume = responseHistory.data.reduce(
        (sum, item) => sum + item.volumn_amt,
        0
      );
    } else {
      totalVolume = 0;
    }
    setTotalTradingVolume(formatNumber(totalVolume));
  };

  const GetDataWallet = async () => {
    const res = await api.get("/api/wallet/get-all-wallets");
    setTotalUsers(res.data.length);
  };

  // const GetDataPayout = async () => {
  //   const res = await api.get("/api/payout/get-all-payout");
  //   let totalPayout;
  //   if (res.data.length !== 0) {
  //     totalPayout = res.data.reduce((sum, item) => sum + item.amount, 0);
  //   } else {
  //     totalPayout = 0;
  //   }
  //   setTotalPayout(formatNumber(totalPayout));
  // };

  const GetDataPayout = async () => {
    const res = await api.get("/api/landing-page");
    
    setTotalPayout(formatNumber(res.data?.total_payout || 0));
  };


  const [loading, setLoading] = useState(false);
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        width: "100%",
        backgroundColor: "#010002",
        minHeight: "70vh",
        height: "auto",
      }}
    >
      <Box
        className="background-container"
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Box className="banner">
          <Box className="content-db">
            <Grid
              container
              spacing={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box className="web3">
                <Box>WEB 3 PROPFIRM.</Box>
                <Box>More transparency. More funding.</Box>
              </Box>
            </Grid>

            {/* <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: '20px' }}>
              <Box className="second-sidebar">Refund of fees upon passing two </Box>
            </Grid>

            <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center">
              <Box className="second-sidebar">challenge rounds</Box>
            </Grid> */}
            <Grid
              container
              spacing={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              sx={{ marginTop: "20px" }}
            >
              <Box className="second-sidebar">
                From <span className="second-sidebar-sub">$48</span> to{" "}
                <span className="second-sidebar-sub">$500K</span>
              </Box>
            </Grid>
            <Grid
              container
              spacing={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
            >
              <Box className="second-sidebar">
                with <span className="second-sidebar-sub">X1K</span>-funded prop
                trading firm
              </Box>
            </Grid>
            {/* <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: '10px' }} >
              <Box className="third-sidebar text-dark-gray">We are seeking and funding talent in TRADING</Box>
            </Grid> */}

            <Box className="web">
              <Grid
                container
                spacing={0}
                className="top-content"
                display="flex"
                justifyContent="space-between"
              >
                <Grid size={2}></Grid>
                <Grid size={14}>
                  <Box className="text-trade-top">How it works:</Box>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                className="second-content "
                display="flex"
                justifyContent="space-between"
                sx={{ display: "flex" }}
              >
                <Grid size={{ xs: 6, md: 3 }}>
                  <Grid container spacing={0} columns={16}>
                    <Grid size={1}>
                      {/* <div style={{ color: '#0091ff', fontSize: '1vw' }}><FontAwesomeIcon icon={faDiamond} /></div> */}
                    </Grid>
                    <Grid size={15}>
                      <Box className="text-trade-top">1.Register</Box>
                    </Grid>
                    <Grid size={1}></Grid>
                    <Grid size={15} sx={{ marginTop: "2vh" }}>
                      <Box className="text-trade-down">Starting $27</Box>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={0}
                    columns={16}
                    sx={{ marginTop: "3vh" }}
                  >
                    <Grid size={1}>
                      {/* <div style={{ color: '#0091ff', fontSize: '1vw' }}><FontAwesomeIcon icon={faDiamond} /></div> */}
                    </Grid>
                    <Grid size={15}>
                      <Box className="text-trade-top">2.Trade</Box>
                    </Grid>
                    <Grid size={1}></Grid>
                    <Grid size={15} sx={{ marginTop: "2vh" }}>
                      <Box className="text-trade-down">
                        Prove your trading skills
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid size={{ xs: 1, md: 6 }}></Grid>
                <Grid size={{ xs: 5, md: 3 }}>
                  <Grid container spacing={0} columns={16} textAlign="left">
                    <Grid size={16} sx={{}}>
                      <Box className="text-trade-top">3.Get funded</Box>
                    </Grid>
                    {/* <Grid size={2}>
                    <div style={{ color: '#0091ff', fontSize: '1vw' }}><FontAwesomeIcon icon={faDiamond} /></div>
                  </Grid> */}
                    <Grid size={16} sx={{ marginTop: "2vh" }}>
                      <Box className="text-trade-down">
                        From $5.000 to $500.000
                      </Box>
                    </Grid>
                    {/* <Grid size={2}>
                  </Grid> */}
                  </Grid>
                  <Grid
                    container
                    spacing={0}
                    columns={16}
                    textAlign="left"
                    sx={{ marginTop: "3vh" }}
                  >
                    <Grid size={16}>
                      <Box className="text-trade-top" sx={{}}>
                        4.Profit
                      </Box>
                    </Grid>
                    {/* <Grid size={2}>
                    <div style={{ color: '#0091ff', fontSize: '1vw' }}><FontAwesomeIcon icon={faDiamond} /></div>
                  </Grid> */}
                    <Grid size={16} sx={{ marginTop: "2vh" }}>
                      <Box className="text-trade-down">
                        Earn up to 90% your profit
                      </Box>
                    </Grid>
                    {/* <Grid size={2}>
                  </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ marginTop: "1vh" }}
              >
                <Button className="ChallengeX1K web" onClick={profileDeposit}>
                  Challenge X1K
                </Button>
                <Button className="FreeTrial">
                  <Box sx={{ color: "#fff" }}>Free trial <small>(coming soon)</small></Box>
                </Button>
              </Grid>
              <br />
              <br />
              <Box
                sx={{
                  padding: "20px",
                  color: "white",
                }}
              >
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12} sm={4} md={3}>
                    <Box
                      sx={{
                        width: "315px",
                        textAlign: "center",
                        border: "1px solid white",
                        borderRadius: "8px",
                        padding: "16px",
                        backgroundColor: "rgba(255, 255, 255, 0.1)", // Nền trong suốt
                        backdropFilter: "blur(10px)", // Hiệu ứng mờ nền
                      }}
                    >
                      <Typography variant="h6">TOTAL TRADING VOLUME</Typography>
                      <Typography variant="h4" sx={{ marginTop: "8px" }}>
                        {total_trading_volume ? total_trading_volume : "0"} USD
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <Box
                      sx={{
                        width: "315px",
                        textAlign: "center",
                        border: "1px solid white",
                        borderRadius: "8px",
                        padding: "16px",
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                        backdropFilter: "blur(10px)",
                      }}
                    >
                      <Typography variant="h6">TOTAL USERS</Typography>
                      <Typography variant="h4" sx={{ marginTop: "8px" }}>
                        {total_users ? total_users : "0"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <Box
                      sx={{
                        width: "315px",
                        textAlign: "center",
                        border: "1px solid white",
                        borderRadius: "8px",
                        padding: "16px",
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                        backdropFilter: "blur(10px)",
                      }}
                    >
                      <Typography variant="h6">TOTAL PAYOUT</Typography>
                      <Typography variant="h4" sx={{ marginTop: "8px" }}>
                        {total_payout ? total_payout : "0"} USD
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box className="mobile">
              <Grid
                container
                spacing={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ marginTop: "5vh" }}
              >
                <Button className="ChallengeX1K mobile" onClick={tryPc}>
                  Challenge x1K
                </Button>
                <Button className="FreeTrial">
                  <Box sx={{ color: "#fff" }}>Free trial</Box>
                </Button>
              </Grid>
              <Grid
                container
                spacing={0}
                className="top-content"
                display="flex"
                justifyContent="center"
              >
                <Box sx={{ color: "#757b7e", fontSize: "8vw" }}>
                  HOW IT WORKS
                </Box>
              </Grid>
              <Grid
                container
                spacing={0}
                className="second-content"
                display="flex"
                justifyContent="space-between"
              >
                <Grid size={{ md: 2 }}>
                  <Box className="text-trade-top">1.</Box>
                </Grid>
                <Grid size={{ md: 8 }} textAlign={"center"}>
                  <Box className="text-trade-top">Register</Box>
                  <Box className="text-trade-down">Starting $27</Box>
                </Grid>
                <Grid size={{ md: 2 }}></Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                className="second-content"
                display="flex"
                justifyContent="space-between"
              >
                <Grid size={{ md: 2 }}>
                  <Box className="text-trade-top">2.</Box>
                </Grid>
                <Grid size={{ md: 8 }} textAlign={"center"}>
                  <Box className="text-trade-top">Trade</Box>
                  <Box className="text-trade-down">
                    Prove your trading skills
                  </Box>
                </Grid>
                <Grid size={{ md: 2 }}></Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                className="second-content"
                display="flex"
                justifyContent="space-between"
              >
                <Grid size={{ md: 2 }}>
                  <Box className="text-trade-top">3.</Box>
                </Grid>
                <Grid size={{ md: 8 }} textAlign={"center"}>
                  <Box className="text-trade-top">Get funded</Box>
                  <Box className="text-trade-down">From $5.000 to $500.000</Box>
                </Grid>
                <Grid size={{ md: 2 }}></Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                className="second-content"
                display="flex"
                justifyContent="space-between"
              >
                <Grid size={{ md: 2 }}>
                  <Box className="text-trade-top">4.</Box>
                </Grid>
                <Grid size={{ md: 8 }} textAlign={"center"}>
                  <Box className="text-trade-top">Profit</Box>
                  <Box className="text-trade-down">
                    Earn up to 90% your profit
                  </Box>
                </Grid>
                <Grid size={{ md: 2 }}></Grid>
              </Grid>
              <Box
                sx={{
                  padding: "20px",
                  color: "white",
                }}
              >
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12} sm={4} md={3}>
                    <Box
                      sx={{
                        width: "272px",
                        textAlign: "center",
                        border: "1px solid white",
                        borderRadius: "8px",
                        padding: "16px",
                        backgroundColor: "rgba(255, 255, 255, 0.1)", // Nền trong suốt
                        backdropFilter: "blur(10px)", // Hiệu ứng mờ nền
                      }}
                    >
                      <Typography variant="h7">TOTAL TRADING VOLUME</Typography>
                      <Typography variant="h5" sx={{ marginTop: "8px" }}>
                        {total_trading_volume ? total_trading_volume : "0"} USD
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <Box
                      sx={{
                        width: "272px",
                        textAlign: "center",
                        border: "1px solid white",
                        borderRadius: "8px",
                        padding: "16px",
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                        backdropFilter: "blur(10px)",
                      }}
                    >
                      <Typography variant="h7">TOTAL USERS</Typography>
                      <Typography variant="h5" sx={{ marginTop: "8px" }}>
                        {total_users ? total_users : "0"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <Box
                      sx={{
                        width: "272px",
                        textAlign: "center",
                        border: "1px solid white",
                        borderRadius: "8px",
                        padding: "16px",
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                        backdropFilter: "blur(10px)",
                      }}
                    >
                      <Typography variant="h7">TOTAL PAYOUT</Typography>
                      <Typography variant="h5" sx={{ marginTop: "8px" }}>
                        {total_payout ? total_payout : "0"} USD
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <br />
              <br />
            </Box>

            <Box>
              {/* <Box className="support-btn" onClick={Support()}>
                <FontAwesomeIcon icon={faMessage} />
              </Box> */}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="" sx={{ padding: "0 4.17vw" }}>
        <Box
          className="evaluation-process"
          sx={{ fontSize: "1.30vw", color: "#fff" }}
        >
          Evaluation process
        </Box>
        <Box
          className="evaluation-process"
          sx={{ fontSize: "1.20vw", color: "#fff" }}
        >
          Swing
        </Box>
        <Grid sx={{ padding: "2.08vh 0" }}>
          <Grid container spacing={3} columns={15}>
            {evalua.map((item) => (
              <Grid
                key={item.id}
                size={{ xs: 15, md: 5 }}
                sx={{ border: "#5b5b5b solid 1px", borderRadius: "20px" }}
              >
                <Grid
                  container
                  spacing={2}
                  columns={16}
                  sx={{ padding: "20px" }}
                >
                  <Grid size={10}>
                    <Box className="evaluation-process-title">{item.title}</Box>
                  </Grid>
                  <Grid
                    size={6}
                    display="flex"
                    justifyContent="right"
                    alignItems="right"
                  >
                    <Box display="flex" sx={{ justifyContent: "flex-end" }}>
                      <Box
                        className="evaluation-process-img"
                        component="img"
                        alt="X1k Image"
                        src={`${process.env.PUBLIC_URL}/images/${item.img}`}
                      />
                    </Box>
                  </Grid>
                  <Grid size={10}>
                    <Box
                      className="evaluation-process-target"
                      sx={{
                        fontSize: "1.04vw",
                        color: "#fff",
                        marginTop: "10px",
                      }}
                    >
                      {item.round === "3" ? "SPLIT profit" : "Profit target to be achieved"}
                    </Box>
                  </Grid>
                  <Grid
                    size={6}
                    display="flex"
                    justifyContent="right"
                    alignItems="right"
                  >
                    <Box
                      className="evaluation-process-rate"
                      sx={{
                        padding: "10px",
                        backgroundColor: "#0091ff",
                        borderRadius: "15px",
                        color: "#fff",
                        fontWeight: "Bold",
                      }}
                    >
                      {item.sale ? (
                        <span>
                          <del className="evaluation-process-rate-sub">
                            {item.profit_target}
                          </del>
                          <span>{item.sale}</span>
                        </span>
                      ) : (
                        <span>{item.profit_target}</span>
                      )}
                    </Box>
                  </Grid>
                  <Grid size={16} sx={{ borderTop: "#5b5b5b solid 1px" }}>
                    <Box>
                      <div
                        className="evaluation-process-content text-dark-gray"
                        style={{ marginTop: "10px" }}
                      >
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{ color: "#f5d90a" }}
                        />{" "}
                        Maximum daily loss{" "}
                        <span className="text-blue">
                          {item.max_loss_per_day}%
                        </span>
                      </div>
                    </Box>
                    <Box>
                      <div
                        className="evaluation-process-content text-dark-gray"
                        style={{ marginTop: "10px" }}
                      >
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{ color: "#f5d90a" }}
                        />{" "}
                        Maximum total loss{" "}
                        <span className="text-blue">{item.max_loss}%</span>
                      </div>
                    </Box>
                    <Box>
                      <div
                        className="evaluation-process-content text-dark-gray"
                        style={{ marginTop: "10px" }}
                      >
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{ color: "#f5d90a" }}
                        />{" "}
                        Leverage{" "}
                        <span className="text-blue">{item.leverage}</span>
                      </div>
                    </Box>
                    <Box>
                      <div
                        className="evaluation-process-content text-dark-gray"
                        style={{ marginTop: "10px" }}
                      >
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{ color: "#f5d90a" }}
                        />{" "}
                        Registration fee paid{" "}
                        <span className="text-blue">{item.fee} time</span>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Box
          className="evaluation-process"
          sx={{ fontSize: "40px", color: "#fff", marginTop: "50px" }}
        >
          Funding packages
        </Box>
        <Box sx={{ fontSize: "15px", color: "#ddd", marginTop: "20px", textAlign: { xs: 'center', md: 'left' } }}>
          Balance
        </Box>
        <Grid container spacing={1.5} sx={{ marginTop: "20px", flexDirection: { xs: 'column', md: 'row' } }}>
          {renderBalance()}
          
          <Grid size={6} sx={{ display: { xs: 'none', md: 'block' } }}></Grid>
          {/* <Grid size="grow" className='compare-btn' >
            Quick comparison
          </Grid> */}
        </Grid>
        <Box sx={{ fontSize: "15px", color: "#ddd", marginTop: "20px", textAlign: { xs: 'center', md: 'left' } }}>
          Mode
        </Box>
        <Grid container spacing={1.5} sx={{ marginTop: "20px", flexDirection: { xs: 'column', md: 'row' } }}>
          {renderMode()}
          <Grid size={6}></Grid>
        </Grid>
         {renderTable}
        <Box sx={{ marginTop: "20px" }}>
          <Button
            variant="contained"
            className="mobile"
            size="large"
            sx={{ width: "100%" }}
            onClick={tryPc}
          >
            Let's start the X1K challenge
          </Button>
        </Box>
      </Box>
      <Box sx={{ marginTop: "100px" }}>
        <PrimaryFooter />
      </Box>
    </Box>
  );
};

export default DashBoard;

/* eslint-disable jsx-a11y/alt-text */
import { Box, Toolbar, Typography, Switch, Button } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import CancelIcon from '@mui/icons-material/Cancel';
import Grid from "@mui/material/Grid2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faIdCard, faShield, faCamera, faEdit } from "@fortawesome/free-solid-svg-icons";
import "./profile.style.css";
import BreadcrumbsNav from "../Breadcrumbs";
import React, { useState, useEffect } from "react";
import { BrowserProvider } from "ethers";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import api from "../../instance_api_key";
import { useSnackbar } from "../SnackbarProvider";
import TwitterPage from "./twitterPage";
import { useLocation, useNavigate, Routes, Route } from "react-router-dom";
import api_jwt_wallet from '../../instance_api_jwt';
import { setNavigate } from '../../instance_api_jwt';
import KycVerification from "./kycVerification";
import { CancelOutlined, CheckCircleOutline, PendingActions } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
};

const Profile = () => {
  const navigate = useNavigate();
  setNavigate(navigate);
  const [showKyc, setShowKyc] = useState(false);

  //#region TWITTER RESPONSE
  const location = useLocation();
  const [userTwitter, setUserTwitter] = useState(null);

  // Lấy `twitter_id` từ URL params
  const getTwitterIdFromUrl = () => {
    const params = new URLSearchParams(location.search);
    if (params.has("twitter_id")) {
      const twitter_id = decodeTwitterId(params.get("twitter_id"));
      if (twitter_id) {
        api.post(`/api/profile/update_profile_twitter`, {
          wallet: localStorage.getItem("wallet_address"),
          twitter_id: twitter_id,
        })
        .then((response) => {
          getDataProfile(localStorage.getItem("wallet_address"));
        })
        .catch((error) => console.error(error));
      }
    }
  };

  useEffect(() => {
    getTwitterIdFromUrl();
  }, []);

  const decodeTwitterId = (encodedTwitterId) => {
    try {
      const decodedTwitterId = atob(encodedTwitterId);
      return decodedTwitterId;
    } catch (error) {
      console.error("Decode error Twitter ID:", error);
      return null;
    }
  };


  const getDataFindTwitterId = (twitterId) => {
    if(twitterId) {
      api.get(`/api/twitter/auth/get-user-twitter-db?twitter_id=${twitterId}`)
        .then((response) => {
          if (response.data) {
            const data = response.data;
            setUserTwitter(data);
          }
        })
        .catch((error) => console.error(error));
    }
  };

  //#endregion

  const showSnackbar = useSnackbar();
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [walletAddress, setWalletAddress] = useState(null);
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [telegram, setTelegram] = useState(null);
  const [discord, setDiscord] = useState(null);
  const [refWallet, setRefWallet] = useState(null);
  const [linkFacaster, setLinkFacaster] = useState(null);
  const [avartar, setAvartar] = useState(null);
  const [profile, setProfile] = useState(null);
  const [avatarFile, setAvatarFile] = useState(null);
  const [uploadingAvatar, setUploadingAvatar] = useState(false);
  const fileInputRef = React.useRef(null);
  let isEffectExecuted = false;
  useEffect(() => {
    if (!isEffectExecuted) {
      checkIfWalletIsConnected();
      isEffectExecuted = true;
    }
  }, []);

  const checkIfWalletIsConnected = async () => {
    const tempProvider = new BrowserProvider(window.ethereum);

    // Request account access if needed
    const accounts = await tempProvider.send("eth_requestAccounts", []);
    getDataProfile(accounts[0]);
    setWalletAddress(accounts[0]);
  };

  const getDataProfile = (walletAddress) => {
    api_jwt_wallet
      .get(`/api/profile/getByWalletAddress/${walletAddress}`)
      .then((response) => {
        if (response.data) {
          const dataProfile = response.data;
          dataProfile.wallet_address = walletAddress;
          setEmail(dataProfile.email);
          setTelegram(dataProfile.link_telegram);
          setDiscord(dataProfile.link_discord);
          setName(dataProfile.user_name || '');
          // setUserName(dataProfile.user_name);
          // setLinkX(dataProfile.link_x);
          // setLinkFarcaster(dataProfile.link_facaster);
          // setAvartar(dataProfile.avartar);
          setRefWallet(dataProfile.ref);
          setProfile(dataProfile);
          getDataFindTwitterId(dataProfile.twitter_id);
        }
      })
      .catch((error) => console.error(error));
  };

  const update = () => {
    api
      .post(`/api/profile/update_profile`, {
        id: profile?.id,
        email: email,
        linkX: profile.link_x,
        linkFacaster: linkFacaster,
        avartar: profile.avartar,
        link_discord: discord,
        link_telegram: telegram,
        user_name: name,
      })
      .then((response) => {
        handleClose();
        handleCloseTelegram();
        handleCloseDiscord();
        handleCloseName();
        getDataProfile(walletAddress);
      })
      .catch((error) => console.error(error));
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [openTelegram, setOpenTelegram] = React.useState(false);
  const handleOpenTelegram = () => {
    setOpenTelegram(true);
  };
  const handleCloseTelegram = () => {
    setOpenTelegram(false);
  };

  const [openDiscord, setOpenDiscord] = React.useState(false);
  const handleOpenDiscord = () => {
    setOpenDiscord(true);
  };
  const handleCloseDiscord = () => {
    setOpenDiscord(false);
  };

  const [openName, setOpenName] = React.useState(false);
  const handleOpenName = () => {
    setOpenName(true);
  };
  const handleCloseName = () => {
    setOpenName(false);
  };

  const [codeRef, setCodeRef] = useState(null);
  const handleChangeCodeRef = (event) => {
    setCodeRef(event.target.value);
  };

  const handleSendEmail = () => {


    const _email = `nhatnn2001.hanoi@gmail.com`;

    // Gọi API với method POST và gửi dữ liệu qua body
    fetch("http://localhost:3185/api/email/send-email-xk", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: _email,
        title: "Tiêu đề",
        content: ``,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Optionally, redirect or update the UI
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  const toggleKycView = () => {
    setShowKyc(!showKyc);
  };

  const handleAvatarClick = () => {
    // Kích hoạt input file ẩn khi click vào avatar
    fileInputRef.current.click();
  };
  
  const handleAvatarChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    
    try {
      setUploadingAvatar(true);
      
      // Create preview
      const reader = new FileReader();
      reader.onload = (e) => {
        // Preview image in UI
      };
      reader.readAsDataURL(file);
      
      // Upload image
      const formData = new FormData();
      formData.append('avatar', file);
      
      // Upload image using upload-documents API
      const response = await api_jwt_wallet.post('/api/account/upload-documents', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      if (response.data && response.data.success) {
        const files = response.data.files || [];
        // Get the URL of the uploaded image
        let avatarUrl = null;
        
        for (const file of files) {
          if (file.fileType === 'avatar') {
            avatarUrl = file.publicUrl;
            break;
          }
        }
        
        if (avatarUrl) {
          // Update profile with new avatar
          const updateResponse = await api.post(`/api/profile/update_profile`, {
            id: profile?.id,
            avartar: avatarUrl,
          });
          getDataProfile(walletAddress);
        } else {
          showSnackbar("Could not find image URL from server", "error");
        }
      } else {
        showSnackbar("Unable to upload image, please try again", "error");
      }
    } catch (error) {
      console.error("Error uploading avatar:", error);
      showSnackbar("An error occurred while uploading avatar", "error");
    } finally {
      setUploadingAvatar(false);
    }
  };

  if (showKyc) {
    return (
      <Box component="main" sx={{ flexGrow: 1, p: 3, background: "#F8F8F8" }}>
        <Toolbar />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Button 
            variant="outlined" 
            onClick={toggleKycView}
            startIcon={<FontAwesomeIcon icon={faChevronRight} style={{ transform: 'rotate(180deg)' }} />}
          >
            Back to Profile
          </Button>
        </Box>
        <KycVerification profile = {profile}/>
      </Box>
    );
  }

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, background: "#F8F8F8" }}>
      <Toolbar />
      <BreadcrumbsNav />
      <br></br>
      <Grid container spacing={3}>
        <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
          <Typography variant="h5" gutterBottom>
            Profile
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Edit and manage your personal and account information here
          </Typography>
        </Grid>
        <Grid item size={{ xs: 12, md: 6, lg: 6 }}>
          <div className="avartar-item">
            <div
              className={`avartar-back ${profile?.email ? "active" : "inactive"}`}
              style={{ position: "relative" }}
            >
              <div  style={{ position: "relative" }} onClick={handleAvatarClick}>
                <img
                  style={{ width: "120px", height: "120px", borderRadius: "50%" }}
                  src={
                    profile?.avartar
                      ? profile?.avartar
                      : `${process.env.PUBLIC_URL}/images/profile-avatar.png`
                  }
                  
                />
                <FontAwesomeIcon icon={faCamera} color="green"  style={{
                  position:"absolute",
                  bottom : 10,
                  right: 15
                }}/>
              </div>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleAvatarChange}
                style={{ display: 'none' }}
                accept="image/*"
              />
              {/* KYC Status Indicator */}
              <Box
                sx={{
                  position: "absolute",
                  bottom: 10,
                  left: 10,
                  backgroundColor: "white",
                  borderRadius: "50%",
                  padding: "2px",
                  border: "2px solid white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {profile?.kyc_status === "approve" ? (
                  <CheckCircleOutline sx={{ color: "green", fontSize: 24 }} />
                ) : profile?.kyc_status === "pending" || profile?.kyc_status === "pending_review" || profile?.kyc_status === "submitted" ? (
                  <PendingActions sx={{ color: "orange", fontSize: 24 }} />
                ) : (
                  <CancelOutlined sx={{ color: "red", fontSize: 24 }} />
                )}
              </Box>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                padding: "5px",
              }}
            >
              <div
                style={{
                  width: "90%",
                  paddingBottom: "5px",
                  borderBottom: "1px solid #ececec",
                }}
              >
                <div style={{ fontWeight: "bold" }}>Name</div>
                <div>{name || ''}</div>
              </div>
              <div
                style={{ width: "10%", textAlign: "end", cursor: "pointer" }}
                onClick={handleOpenName}
              >
                <FontAwesomeIcon icon={faEdit} />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                padding: "5px",
              }}
            >
              <div
                style={{
                  width: "90%",
                  paddingBottom: "5px",
                  borderBottom: "1px solid #ececec",
                }}
              >
                <div style={{ fontWeight: "bold" }}>Email</div>
                <div>{profile?.email}</div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                padding: "5px",
              }}
            >
              <div
                style={{
                  width: "90%",
                  paddingBottom: "5px",
                  borderBottom: "1px solid #ececec",
                }}
              >
                <div style={{ fontWeight: "bold" }}>Twitter</div>

                {userTwitter?.userTwitter ? (
                  <div>{userTwitter?.userTwitter?.username}</div>
                ) : (
                  <button
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}/api/twitter/auth/twitter`,
                        "_self"
                      )
                    }
                  >
                    Login with Twitter
                  </button>
                )}
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                padding: "5px",
              }}
            >
              <div
                style={{
                  width: "90%",
                  paddingBottom: "5px",
                  borderBottom: "1px solid #ececec",
                }}
              >
                <div style={{ fontWeight: "bold" }}>Telegram</div>
                <div>{profile?.link_telegram}</div>
              </div>
              <div
                style={{ width: "10%", textAlign: "end", cursor: "pointer" }}
                onClick={handleOpenTelegram}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                padding: "5px",
              }}
            >
              <div
                style={{
                  width: "90%",
                  paddingBottom: "5px",
                  borderBottom: "1px solid #ececec",
                }}
              >
                <div style={{ fontWeight: "bold" }}>Discord</div>
                <div>{profile?.link_discord}</div>
              </div>
              <div
                style={{ width: "10%", textAlign: "end", cursor: "pointer" }}
                onClick={handleOpenDiscord}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                padding: "5px",
              }}
            >
              <div
                style={{
                  width: "90%",
                  paddingBottom: "5px",
                  borderBottom: "1px solid #ececec",
                }}
              >
                <div style={{ fontWeight: "bold" }}>Link farcaster</div>
                <div>{profile?.linkFarcaster}</div>
              </div>
              <div style={{ width: "10%", textAlign: "end" }}>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  onClick={handleSendEmail}
                />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                padding: "5px",
              }}
            >
              <div
                style={{
                  width: "90%",
                  paddingBottom: "5px",
                  borderBottom: "1px solid #ececec",
                }}
              >
                <div style={{ fontWeight: "bold" }}>Metamask Add</div>
                <div>{walletAddress}</div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                padding: "5px",
              }}
            >
              <div
                style={{
                  width: "90%",
                  paddingBottom: "5px",
                  borderBottom: "1px solid #ececec",
                }}
              >
                <div style={{ fontWeight: "bold" }}>Referred code</div>
                <div>{profile?.referred_code}</div>
              </div>
            </div>

            {profile && <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                padding: "5px",
              }}
            >
              <div
                style={{
                  width: "90%",
                  paddingBottom: "5px",
                  borderBottom: "1px solid #ececec",
                }}
              >
                <div style={{ fontWeight: "bold" }}>
                  <FontAwesomeIcon icon={faIdCard} /> KYC Verification
                </div>
                <div>Verify your identity to unlock all platform features</div>
              </div>
              <div
                style={{ width: "10%", textAlign: "end", cursor: "pointer" }}
                onClick={toggleKycView}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </div>}
          </div>
        </Grid>
        <Grid item size={{ xs: 12, md: 6, lg: 6 }}>
          <div className="avartar-item">
            <Typography variant="h6" sx={{ fontWeight: "bold" }} gutterBottom>
              Email Notifications
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Configure Email Notification
            </Typography>
            <div style={{ width: "100%" }}>
              <Switch {...label} disabled />
              &nbsp;Receive notifications from email
            </div>
            <div style={{ width: "100%" }}>
              <Switch {...label} disabled />
              &nbsp;Important Email
            </div>
          </div>
        </Grid>
      </Grid>
      <Modal
        open={openTelegram}
        onClose={handleCloseTelegram}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <Box sx={{ padding: "20px" }}>
            <Box
              sx={{
                borderBottom: "#a0a0a0 solid 1px",
                fontSize: "20px",
                paddingBottom: "10px",
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  size={3}
                >
                  <img
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "50%",
                    }}
                    src={
                      avartar
                        ? avartar
                        : `${process.env.PUBLIC_URL}/images/profile-avatar.png`
                    }
                  />
                </Grid>
                <Grid
                  container
                  spacing={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  size={6}
                >
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-telegram">
                      Telegram
                    </InputLabel>
                    <Input
                      id="standard-adornment-telegram"
                      defaultValue={telegram}
                      onChange={(event) => {
                        setTelegram(event.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-linkX">
                      Link X
                    </InputLabel>
                    <Input
                      id="standard-adornment-linkX-disabled"
                      disabled
                      defaultValue={profile?.link_x}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-wallet">
                      Metamask Add
                    </InputLabel>
                    <Input
                      id="standard-adornment-wallet-disabled"
                      disabled
                      defaultValue={walletAddress}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={2}>
              <Grid
                display="flex"
                justifyContent="center"
                alignItems="center"
                size="grow"
              ></Grid>
              <Grid
                display="flex"
                justifyContent="center"
                alignItems="center"
                size={9}
              ></Grid>
              <Grid
                container
                spacing={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                size={1.2}
                sx={{
                  marginTop: "20px",
                  padding: "10px 20px",
                  backgroundColor: "#3e63dd",
                  color: "#fff",
                  fontSize: "20px",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                onClick={update}
              >
                <Box>Save</Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openDiscord}
        onClose={handleCloseDiscord}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <Box sx={{ padding: "20px" }}>
            <Box
              sx={{
                borderBottom: "#a0a0a0 solid 1px",
                fontSize: "20px",
                paddingBottom: "10px",
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  size={3}
                >
                  <img
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "50%",
                    }}
                    src={
                      avartar
                        ? avartar
                        : `${process.env.PUBLIC_URL}/images/profile-avatar.png`
                    }
                  />
                </Grid>
                <Grid
                  container
                  spacing={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  size={6}
                >
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-discord">
                      Discord
                    </InputLabel>
                    <Input
                      id="standard-adornment-discord"
                      defaultValue={discord}
                      onChange={(event) => {
                        setDiscord(event.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-linkX">
                      Link X
                    </InputLabel>
                    <Input
                      id="standard-adornment-linkX-disabled"
                      disabled
                      defaultValue={profile?.link_x}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-wallet">
                      Metamask Add
                    </InputLabel>
                    <Input
                      id="standard-adornment-wallet-disabled"
                      disabled
                      defaultValue={walletAddress}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={2}>
              <Grid
                display="flex"
                justifyContent="center"
                alignItems="center"
                size="grow"
              ></Grid>
              <Grid
                display="flex"
                justifyContent="center"
                alignItems="center"
                size={9}
              ></Grid>
              <Grid
                container
                spacing={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                size={1.2}
                sx={{
                  marginTop: "20px",
                  padding: "10px 20px",
                  backgroundColor: "#3e63dd",
                  color: "#fff",
                  fontSize: "20px",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                onClick={update}
              >
                <Box>Save</Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openName}
        onClose={handleCloseName}
        aria-labelledby="name-modal-title"
        aria-describedby="name-modal-description"
      >
        <Box sx={{ ...style }}>
          <Box sx={{ padding: "20px" }}>
            <Box
              sx={{
                borderBottom: "#a0a0a0 solid 1px",
                fontSize: "20px",
                paddingBottom: "10px",
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  size={3}
                >
                  <img
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "50%",
                    }}
                    src={
                      profile?.avartar
                        ? profile?.avartar
                        : `${process.env.PUBLIC_URL}/images/profile-avatar.png`
                    }
                  />
                </Grid>
                <Grid
                  container
                  spacing={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  size={6}
                >
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-name">
                      Name
                    </InputLabel>
                    <Input
                      id="standard-adornment-name"
                      defaultValue={name}
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-email">
                      Email
                    </InputLabel>
                    <Input
                      id="standard-adornment-email-disabled"
                      disabled
                      defaultValue={email}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-wallet">
                      Metamask Add
                    </InputLabel>
                    <Input
                      id="standard-adornment-wallet-disabled"
                      disabled
                      defaultValue={walletAddress}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={2}>
              <Grid
                display="flex"
                justifyContent="center"
                alignItems="center"
                size="grow"
              ></Grid>
              <Grid
                display="flex"
                justifyContent="center"
                alignItems="center"
                size={9}
              ></Grid>
              <Grid
                container
                spacing={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                size={1.2}
                sx={{
                  marginTop: "20px",
                  padding: "10px 20px",
                  backgroundColor: "#3e63dd",
                  color: "#fff",
                  fontSize: "20px",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                onClick={update}
              >
                <Box>Save</Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
export default Profile;

import { Toolbar, Drawer, Box } from "@mui/material";
import TreeViewComponent from './TreeItemComponent'
import React, { useEffect, useState } from "react";
import { treeData } from "../constants/treeData"

const drawerWidth = 250;

const wallet = localStorage.getItem('wallet_address');
const X1kSideBar = () => {
  const [data_final, setDataFinal] = useState([]);

  useEffect(() => {
    const data_raw = treeData;
    if (wallet?.toLocaleLowerCase() != process.env.REACT_APP_WALLET_ADDRESS.toLocaleLowerCase()) {
      setDataFinal( data_raw.filter(c => c.id != "13"));
    } else {
      setDataFinal( data_raw);
    }
    
  }, []);


  return (
    <Box>
      {/* Sidebar Drawer */}
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto', padding: 2 }}>
          <TreeViewComponent data={data_final} />
        </Box>
      </Drawer>
    </Box>
  );
};

export default X1kSideBar;

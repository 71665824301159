import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use((config) => {
    config.withCredentials = true;
    const token = localStorage.getItem('token');
    if (token) {
        config.headers['Authorization'] = token;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

api.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;
        // Nếu lỗi là Access Token hết hạn, thử làm mới token
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const { data } = await axios.post(process.env.REACT_APP_API_URL + '/api/account/post_v1_account_refreshtoken', {}, { withCredentials: true });
                axios.defaults.headers.common['Authorization'] = data.accessToken;
                return api(originalRequest);
            } catch (err) {
                console.log('Failed to refresh token');
            }
        }
        return Promise.reject(error);
    }
);

export const getData = async (endpoint, params = {}) => {
    try {
      const response = await api.get(endpoint, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

export default api;